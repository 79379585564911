// src/components/sales/OverviewDashboard/HubSpotCallback.jsx v2.1.1

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useHubSpot } from '../../../contexts/HubSpotContext';
import { useAuth } from '../../../contexts/AuthContext';
import logger from '../../../utils/frontendLogger';
import { initiateHubSpotIntegration } from '../../../services/hubspotService';
import CircularProgress from '@mui/material/CircularProgress'; // Material-UI spinner
import Box from '@mui/material/Box';

const HubSpotCallback = () => {
  const location = useLocation();
  const { setError } = useHubSpot();
  const { currentUser } = useAuth();
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    const handleCallback = async () => {
      if (isProcessing) return;
      setIsProcessing(true);

      const params = new URLSearchParams(location.search);
      const code = params.get('code');
      const state = params.get('state');

      if (code && state === currentUser?.uid) {
        try {
          logger.info('Initiating HubSpot integration', { code, state });
          await initiateHubSpotIntegration(code, state);
          logger.info('HubSpot integration initiated successfully');
          // No need to navigate here, the HubSpotContext will handle the redirect
        } catch (error) {
          logger.error('Error in HubSpot integration:', error);
          setError(error.message || 'Failed to connect to HubSpot');
        } finally {
          setIsProcessing(false);
        }
      } else {
        logger.warn('Invalid callback parameters', { hasCode: !!code, hasValidState: state === currentUser?.uid });
        setError('Invalid HubSpot callback');
        setIsProcessing(false);
      }
    };

    handleCallback();
  }, [location.search, currentUser, setError, isProcessing]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      flexDirection="column"
    >
      <CircularProgress size={60} />  {/* MUI spinner */}
      <p style={{ marginTop: '20px', fontSize: '1.2rem', color: '#555' }}>
        Connecting to HubSpot, please wait...
      </p>
    </Box>
  );
};

export default HubSpotCallback;
