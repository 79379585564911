// src/config/environment.js v1.3.0

// Determine the current environment
export const isDevelopment = process.env.NODE_ENV === 'development';
export const isProduction = process.env.NODE_ENV === 'production';

// Determine if emulators should be used (only in development)
export const useEmulators = isDevelopment && process.env.REACT_APP_USE_EMULATOR === 'true';

// HubSpot configuration
export const HUBSPOT_AUTH_URL = process.env.REACT_APP_HUBSPOT_AUTH_URL;
export const HUBSPOT_TOKEN_URL = process.env.REACT_APP_HUBSPOT_TOKEN_URL;
export const HUBSPOT_CLIENT_ID = process.env.REACT_APP_HUBSPOT_CLIENT_ID;
export const HUBSPOT_CLIENT_SECRET = process.env.REACT_APP_HUBSPOT_CLIENT_SECRET;
export const HUBSPOT_SCOPES = process.env.REACT_APP_HUBSPOT_SCOPES;
export const FORMATTED_HUBSPOT_SCOPES = HUBSPOT_SCOPES ? HUBSPOT_SCOPES.replace(/%20/g, ' ') : '';

// Redirect URI (different for dev and prod)
export const REDIRECT_URI = process.env.REACT_APP_HUBSPOT_REDIRECT_URI;

// Firebase configuration
export const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Emulator configuration
export const EMULATOR_CONFIG = {
  host: process.env.REACT_APP_EMULATOR_HOST,
  authPort: process.env.REACT_APP_EMULATOR_AUTH_PORT,
  functionsPort: process.env.REACT_APP_EMULATOR_FUNCTIONS_PORT,
  firestorePort: process.env.REACT_APP_EMULATOR_FIRESTORE_PORT,
  hostingPort: process.env.REACT_APP_EMULATOR_HOSTING_PORT,
  storagePort: process.env.REACT_APP_EMULATOR_STORAGE_PORT
};

// Function to determine if we're running in the emulator
export const isRunningInEmulator = () => {
  return process.env.REACT_APP_USE_EMULATOR === 'true' && process.env.NODE_ENV === 'development';
};

// Function to get the appropriate API URL
export const getApiUrl = () => {
  if (isRunningInEmulator()) {
    return `http://${EMULATOR_CONFIG.host}:${EMULATOR_CONFIG.functionsPort}/${FIREBASE_CONFIG.projectId}/us-central1`;
  }
  return isDevelopment 
    ? `http://localhost:5001/${FIREBASE_CONFIG.projectId}/us-central1`
    : `https://us-central1-${FIREBASE_CONFIG.projectId}.cloudfunctions.net`;
};

// API URL (use emulator in development if enabled, otherwise use production)
export const API_URL = getApiUrl();

// Logging level
export const LOG_LEVEL = process.env.REACT_APP_LOG_LEVEL || 'error';

// Cache configuration
export const CACHE_DURATION = isDevelopment ? 1000 * 60 : 1000 * 60 * 5; // 1 minute in dev, 5 minutes in prod

// Batch operation configuration
export const MAX_BATCH_SIZE = 10; // Maximum number of operations in a single batch request

// Function to get the full configuration (useful for debugging)
export const getFullConfig = () => ({
  environment: isDevelopment ? 'Development' : 'Production',
  useEmulators,
  apiUrl: API_URL,
  redirectUri: REDIRECT_URI,
  hubspotAuthUrl: HUBSPOT_AUTH_URL,
  hubspotTokenUrl: HUBSPOT_TOKEN_URL,
  hubspotClientId: HUBSPOT_CLIENT_ID,
  hubspotScopes: FORMATTED_HUBSPOT_SCOPES,
  firebaseConfig: FIREBASE_CONFIG,
  emulatorConfig: useEmulators ? EMULATOR_CONFIG : 'Not Used',
  logLevel: LOG_LEVEL
});

// Function to get the environment configuration
export const getEnvironmentConfig = () => ({
  isProduction,
  isDevelopment,
  isEmulator: isRunningInEmulator(),
  apiUrl: API_URL,
  useEmulators,
  logLevel: LOG_LEVEL,
  cacheDuration: CACHE_DURATION,
  maxBatchSize: MAX_BATCH_SIZE,
  hubspotAuthUrl: HUBSPOT_AUTH_URL,
  hubspotTokenUrl: HUBSPOT_TOKEN_URL,
  hubspotClientId: HUBSPOT_CLIENT_ID,
  hubspotScopes: FORMATTED_HUBSPOT_SCOPES,
  redirectUri: REDIRECT_URI,
  firebaseConfig: FIREBASE_CONFIG,
  emulatorConfig: useEmulators ? EMULATOR_CONFIG : 'Not Used',
  EMULATOR_CONFIG: {
    host: process.env.REACT_APP_EMULATOR_HOST || 'localhost',
    authPort: process.env.REACT_APP_EMULATOR_AUTH_PORT || '3690',
    functionsPort: process.env.REACT_APP_EMULATOR_FUNCTIONS_PORT || '4690',
    firestorePort: process.env.REACT_APP_EMULATOR_FIRESTORE_PORT || '5690',
    // ... other emulator ports ...
  },
});

// Log the configuration in development
if (isDevelopment) {
  console.log('Environment Configuration:', getEnvironmentConfig());
}

export default getEnvironmentConfig;