import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { Box, Typography, CircularProgress } from '@mui/material';
import { useHealthcheck } from '../../../contexts/HealthcheckContext';
import CustomTooltip from '../../charts/CustomTooltip';

const DataQualityScoreByRep = () => {
  const {
    dataQualityScoreByRep,
    loading,
    getCommonChartProperties,
    isMobile,
    getChartColor,
    formatWholePercentage
  } = useHealthcheck() || {};

  const commonProperties = getCommonChartProperties ? getCommonChartProperties('bar') : {};

  const tooltipContent = ({ indexValue, value }) => ({
    title: indexValue,
    items: [
      { label: 'Data Quality Score', value: value * 100, formatType: 'percentage' },
    ],
  });

  if (loading) {
    return (
      <Box height="100%" display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    );
  }

  if (!dataQualityScoreByRep || dataQualityScoreByRep.length === 0) {
    return (
      <Box height="100%" display="flex" justifyContent="center" alignItems="center">
        <Typography variant="body1">No data quality score data available.</Typography>
      </Box>
    );
  }

  return (
    <ResponsiveBar
      data={dataQualityScoreByRep}
      {...commonProperties}
      keys={['score']}
      indexBy="rep"
      padding={0.3}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      label={d => formatWholePercentage ? formatWholePercentage(d.value) : `${(d.value * 100).toFixed(0)}%`}
      colors={({ index }) => getChartColor ? getChartColor(index) : '#000000'}
      tooltip={({ indexValue, value }) => (
        <CustomTooltip {...tooltipContent({ indexValue, value })} />
      )}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: isMobile ? -45 : 0,
        legend: isMobile ? '' : 'Sales Representative',
        legendPosition: 'middle',
        legendOffset: 32
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Data Quality Score',
        legendPosition: 'middle',
        legendOffset: -40,
        format: value => formatWholePercentage ? formatWholePercentage(value) : `${(value * 100).toFixed(0)}%`
      }}
      maxValue={1}
    />
  );
};

export default DataQualityScoreByRep;