import React from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { useRevenue } from '../../../../contexts/RevenueContext';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { formatPercentage } from '../../../utils/formatters';

const calculateGrowth = (current, previous) => {
  if (previous === 0 && current > 0) return Infinity;
  if (previous === 0 && current === 0) return 0;
  return (current - previous) / previous;
};

const formatGrowth = (growth) => {
  if (!isFinite(growth)) return '∞%';
  const percentage = growth * 100;
  if (Math.abs(percentage) > 1000) return `${percentage > 0 ? '>' : '<'}1000%`;
  return formatPercentage(growth);
};

const RevenueInsights = () => {
  const {
    filteredData,
    formatCurrency,
    formatPercentage,
    getChartColorPair,
    isMobile,
    theme,
    getCurrentAndPreviousYear
  } = useRevenue();

  const { currentYear, previousYear } = getCurrentAndPreviousYear();

  // Calculate YoY Revenue Growth
  const currentYearRevenue = filteredData.filteredYoyRevenueComparison[0].data.reduce((sum, month) => sum + month.y, 0);
  const previousYearRevenue = filteredData.filteredYoyRevenueComparison[1].data.reduce((sum, month) => sum + month.y, 0);
  const yoyGrowth = calculateGrowth(currentYearRevenue, previousYearRevenue);

  // Calculate QoQ Revenue Growth (assuming last two quarters)
  const currentQuarterRevenue = filteredData.filteredYoyRevenueComparison[0].data.slice(-3).reduce((sum, month) => sum + month.y, 0);
  const previousQuarterRevenue = filteredData.filteredYoyRevenueComparison[0].data.slice(-6, -3).reduce((sum, month) => sum + month.y, 0);
  const qoqGrowth = calculateGrowth(currentQuarterRevenue, previousQuarterRevenue);

  // Find Highest Growth Month
  const growthByMonth = filteredData.filteredYoyRevenueComparison[0].data.map((month, index) => ({
    month: month.x,
    growth: (month.y - filteredData.filteredYoyRevenueComparison[1].data[index].y) / filteredData.filteredYoyRevenueComparison[1].data[index].y
  }));
  const highestGrowthMonth = growthByMonth.reduce((max, month) => month.growth > max.growth ? month : max);

  // Calculate 2024 Projected Revenue (simple projection based on current growth)
  const projectedRevenue = currentYearRevenue * (1 + yoyGrowth);

  const insightData = [
    { label: 'YoY Revenue Growth', value: yoyGrowth, format: 'percentage', icon: <TrendingUpIcon />, color: yoyGrowth >= 0 ? 'success' : 'error' },
    { label: 'QoQ Revenue Growth', value: qoqGrowth, format: 'percentage', icon: <TrendingUpIcon />, color: qoqGrowth >= 0 ? 'success' : 'error' },
    { label: 'Highest Growth Month', value: `${highestGrowthMonth.month} (${formatPercentage(highestGrowthMonth.growth)})`, format: 'text', icon: <CalendarTodayIcon />, color: 'info' },
    { label: `${currentYear + 1} Projected Revenue`, value: projectedRevenue, format: 'currency', icon: <AttachMoneyIcon />, color: 'primary' },
  ];

  const getColor = (colorName) => {
    const colors = getChartColorPair(0);
    switch (colorName) {
      case 'success': return colors.lineColor;
      case 'error': return theme.palette.error.main;
      case 'info': return theme.palette.info.main;
      case 'primary': return theme.palette.primary.main;
      default: return colors.lineColor;
    }
  };

  return (
    <Box sx={{ flexGrow: 1, mb: 2 }}>
      <Grid container spacing={2}>
        {insightData.map((insight, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Paper
              elevation={3}
              sx={{
                p: 2,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                bgcolor: theme.palette.background.paper,
              }}
            >
              <Typography variant="subtitle2" color="textSecondary">
                {insight.label}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 1 }}>
                <Typography variant={isMobile ? "h6" : "h5"} component="div" sx={{ fontWeight: 'bold', color: getColor(insight.color) }}>
                  {insight.format === 'percentage' ? formatGrowth(insight.value) :
                   insight.format === 'currency' ? formatCurrency(insight.value) :
                   insight.value}
                </Typography>
                {React.cloneElement(insight.icon, { sx: { color: getColor(insight.color) } })}
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default RevenueInsights;