// src/contexts/InsightsContext.jsx v1.2.0

import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { getFirestore, collection, query, onSnapshot } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { processDealsForInsights, generateDynamicInsights, generateInsights, predictTrends } from './utils/insightsProcessing';

const InsightsContext = createContext();

export const useInsights = () => useContext(InsightsContext);

export const InsightsProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deals, setDeals] = useState([]);
  const [dynamicInsights, setDynamicInsights] = useState([]);
  const [currentQuery, setCurrentQuery] = useState(null);
  const [processedData, setProcessedData] = useState(null);

  // Available options for the query builder
  const availableMetrics = ['Total Revenue', 'Number of Deals', 'Average Deal Size', 'Win Rate', 'Deal Velocity', 'Customer Lifetime Value'];
  const availableTimeframes = ['This Month', 'This Quarter', 'This Year', 'Last Month', 'Last Quarter', 'Last Year'];
  const availableComparisons = ['vs Previous Period', 'vs Target', 'Trend'];

  useEffect(() => {
    const auth = getAuth();
    const db = getFirestore();

    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        const dealsRef = collection(db, 'users', user.uid, 'deals');
        const dealsUnsubscribe = onSnapshot(query(dealsRef), (snapshot) => {
          const dealsData = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            closedate: doc.data().closedate ? new Date(doc.data().closedate).getTime() : null,
            createdate: doc.data().createdate ? new Date(doc.data().createdate).getTime() : null,
            amount: parseFloat(doc.data().amount) || 0,
            dealname: doc.data().dealname || 'Unnamed Deal',
            hs_deal_stage_probability: parseFloat(doc.data().hs_deal_stage_probability) || 0,
            dealVelocity: parseFloat(doc.data().dealVelocity) || 0,
            customerLifetimeValue: parseFloat(doc.data().customerLifetimeValue) || 0,
            customStage: doc.data().customStage || '',
            isOpenDeal: doc.data().isOpenDeal || false,
            pipeline: doc.data().pipeline || '',
            hubspot_owner_id: doc.data().hubspot_owner_id || '',
          }));
          
          console.log('Fetched deals:', dealsData);
          setDeals(dealsData);
          const processed = processDealsForInsights(dealsData);
          setProcessedData(processed);
          setDynamicInsights(generateDynamicInsights(processed));
          setLoading(false);
        }, (err) => {
          console.error('Error fetching deals data:', err);
          setError(err.message);
          setLoading(false);
        });

        return () => dealsUnsubscribe();
      } else {
        setDeals([]);
        setProcessedData(null);
        setDynamicInsights([]);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const executeQuery = (queryString) => {
    console.log('Executing query:', queryString);
    setCurrentQuery(queryString);
    const { metric, timeframe, comparison } = parseQuery(queryString);
    
    const filteredDeals = filterDeals(deals, timeframe);
    const processedData = processDealsForInsights(filteredDeals);
    const insights = generateDynamicInsights(processedData, metric, comparison);
    
    setDynamicInsights(insights);
  };

  const parseQuery = (queryString) => {
    const parts = queryString.split(' ');
    return {
      metric: parts[2],
      timeframe: parts[4],
      comparison: parts.slice(5).join(' '),
    };
  };

  const filterDeals = (deals, timeframe) => {
    const now = new Date();
    let startDate;

    switch (timeframe) {
      case 'This Month':
        startDate = new Date(now.getFullYear(), now.getMonth(), 1);
        break;
      case 'This Quarter':
        startDate = new Date(now.getFullYear(), Math.floor(now.getMonth() / 3) * 3, 1);
        break;
      case 'This Year':
        startDate = new Date(now.getFullYear(), 0, 1);
        break;
      case 'Last Month':
        startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        break;
      case 'Last Quarter':
        startDate = new Date(now.getFullYear(), Math.floor(now.getMonth() / 3) * 3 - 3, 1);
        break;
      case 'Last Year':
        startDate = new Date(now.getFullYear() - 1, 0, 1);
        break;
      default:
        return deals;
    }

    return deals.filter(deal => new Date(deal.closedate) >= startDate && new Date(deal.closedate) <= now);
  };

  const value = useMemo(() => ({
    loading,
    error,
    deals,
    dynamicInsights,
    executeQuery,
    availableMetrics,
    availableTimeframes,
    availableComparisons,
    processedData,
    generateInsights: () => generateInsights(processedData),
    predictTrends: () => predictTrends(processedData),
  }), [loading, error, deals, dynamicInsights, processedData]);

  return (
    <InsightsContext.Provider value={value}>
      {children}
    </InsightsContext.Provider>
  );
};