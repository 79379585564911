import React, { useState } from 'react';
import { Box, Typography, Button, Modal, useTheme, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { ReactComponent as TpProDarkLogo } from '../home/assets/tp_pro.svg';
import { ReactComponent as TpProLightLogo } from '../home/assets/tp_pro_lightmode.svg';
import { useChartStyles } from '../hooks/useChartStyles';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { useSubscription } from '../../contexts/SubscriptionContext';

const SubscribePopup = ({ open, onClose }) => {
  const theme = useTheme();
  const { getChartColor } = useChartStyles();
  const { isPro } = useSubscription();

  const proFeatures = [
    'Advanced AI features',
    'Unlimited refreshes with HubSpot',
    'Custom branding and white labeling',
    'Advanced forecasting algorithms',
    'Train own LLM on CRM data',
    'Watch up to 20 deals',
    'Create and save custom dashboards',
  ];

  const Logo = theme.palette.mode === 'dark' ? TpProDarkLogo : TpProLightLogo;

  const handleClose = () => {
    onClose();
  };

  const handleNotYet = () => {
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="subscribe-popup-title"
      aria-describedby="subscribe-popup-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '90%', sm: 400 },
          maxHeight: '90vh',
          bgcolor: 'background.paper',
          borderRadius: 4,
          boxShadow: 24,
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box sx={{ width: '150px', height: '75px', mb: 2 }}>
          <Logo width="100%" height="100%" />
        </Box>
        <Typography id="subscribe-popup-title" variant="h5" component="h2" gutterBottom align="center">
          Upgrade to Pro
        </Typography>
        <Typography variant="body2" sx={{ mb: 2, textAlign: 'center' }}>
          Unlock the full potential of your sales dashboard with these Pro features:
        </Typography>
        <List sx={{ width: '100%', mb: 2, flexGrow: 1, overflowY: 'auto' }}>
          {proFeatures.map((feature, index) => (
            <ListItem key={index} dense>
              <ListItemIcon>
                <CheckCircleRoundedIcon color="secondary" fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={feature} />
            </ListItem>
          ))}
        </List>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt: 2 }}>
          <Button
            variant="outlined"
            onClick={handleNotYet}
            sx={{
              width: '48%',
              color: theme.palette.text.primary,
              borderColor: theme.palette.divider,
              '&:hover': {
                borderColor: theme.palette.text.primary,
              },
            }}
          >
            Not Yet
          </Button>
          <Button
            variant="contained"
            onClick={handleClose}
            sx={{
              width: '48%',
              bgcolor: getChartColor(0),
              '&:hover': {
                bgcolor: getChartColor(1),
              },
            }}
          >
            Upgrade Now
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default SubscribePopup;