import React from 'react';
import { Box, Container, Grid, Typography, Card, Button, useMediaQuery } from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';
import InsightsIcon from '@mui/icons-material/Insights';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import HealthcheckFeatureLight from './assets/healthcheckfeaturelight.png';
import HealthcheckFeatureDark from './assets/healthcheckfeaturedark.png';
import DealInsightsFeatureLight from './assets/insightsfeaturelight.png';
import DealInsightsFeatureDark from './assets/insightsfeaturedark.png';
import PipelineFeatureLight from './assets/pipelinefeaturelight.png';
import PipelineFeatureDark from './assets/pipelinefeaturedark.png';
import { useTheme } from '@mui/material/styles';
import { trackEvent } from '../utils/analytics';

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const AnimatedTypography = styled(Typography)(({ theme }) => ({
  background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main}, ${theme.palette.primary.main})`,
  backgroundSize: '200% 200%',
  animation: `${gradientAnimation} 10s ease infinite`,
  color: 'transparent',
  WebkitBackgroundClip: 'text',
  backgroundClip: 'text',
  fontWeight: 800,
  textAlign: 'center',
  fontSize: '3rem',
  marginBottom: theme.spacing(8),
}));

const FeatureCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: 0,
  borderRadius: theme.spacing(2),
  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
  overflow: 'hidden',
  backgroundColor: theme.palette.background.paper,
}));

const FeatureIcon = styled(Box)(({ theme }) => ({
  fontSize: '2.5rem',
  color: theme.palette.secondary.main,
  marginBottom: theme.spacing(2),
}));

const FeatureImageContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  position: 'relative',
  overflow: 'hidden',
  borderRadius: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const FeatureImage = styled('img')(({ theme, zoomed }) => ({
  maxWidth: '100%',
  maxHeight: '100%',
  objectFit: zoomed ? 'cover' : 'contain',
  objectPosition: zoomed ? 'center top' : 'center',
  transform: zoomed ? 'scale(1.5)' : 'none',
  transformOrigin: 'top center',
  transition: 'transform 0.3s ease-in-out',
}));

const FeatureContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
  padding: theme.spacing(10),
}));

const features = [
  {
    title: 'CRM Healthcheck',
    description: 'Analyze your pipelines health with advanced analytics, ensuring deals are fresh and progressing.',
    icon: <HealthAndSafetyIcon fontSize="inherit" />,
    image: { light: HealthcheckFeatureLight, dark: HealthcheckFeatureDark },
  },
  {
    title: 'Automated Deal Insights',
    description: 'Save time with automated CRM insights that highlight high-potential deals.',
    icon: <InsightsIcon fontSize="inherit" />,
    image: { light: DealInsightsFeatureLight, dark: DealInsightsFeatureDark },
  },
  {
    title: 'Real-time Pipeline Visibility',
    description: 'Get instant insights into deal status, value, and progress across your entire sales pipeline.',
    icon: <TrendingUpIcon fontSize="inherit" />,
    image: { light: PipelineFeatureLight, dark: PipelineFeatureDark },
  },
];

const Features = ({ onOpenSignupModal }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleStartNow = (featureTitle) => {
    trackEvent('start_now_click', {
      'event_category': 'engagement',
      'event_label': `Feature - ${featureTitle}`
    });
    onOpenSignupModal();
  };

  const renderFeature = (feature, index) => {
    const featureImage = typeof feature.image === 'object' 
      ? (theme.palette.mode === 'light' ? feature.image.light : feature.image.dark)
      : feature.image;

    const isInsightsFeature = feature.title === 'Automated Deal Insights';

    return (
      <Grid item xs={12} key={index}>
        <FeatureCard>
          <Grid container>
            {isMobile ? (
              <>
                <Grid item xs={12}>
                  <FeatureImageContainer sx={{ borderRadius: '10px 10px 0 0' }}>
                    <FeatureImage src={featureImage} alt={feature.title} loading="lazy" zoomed={isInsightsFeature} />
                  </FeatureImageContainer>
                </Grid>
                <Grid item xs={12}>
                  <FeatureContent>
                    <FeatureIcon>{feature.icon}</FeatureIcon>
                    <Typography variant="h2" component="h3" gutterBottom sx={{ fontWeight: 800, fontSize: '2rem', color: theme.palette.text.primary }}>
                      {feature.title}
                    </Typography>
                    <Typography variant="h3" sx={{ fontSize: '1.25rem', color: theme.palette.text.secondary, mb: 3 }}>
                      {feature.description}
                    </Typography>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      onClick={() => handleStartNow(feature.title)}
                    >
                      Start Now
                    </Button>
                  </FeatureContent>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} md={6}>
                  <FeatureContent>
                    <FeatureIcon>{feature.icon}</FeatureIcon>
                    <Typography variant="h2" component="h3" gutterBottom sx={{ fontWeight: 800, fontSize: '2.5rem', color: theme.palette.text.primary }}>
                      {feature.title}
                    </Typography>
                    <Typography variant="h3" sx={{ fontSize: '1.5rem', color: theme.palette.text.secondary, mb: 4 }}>
                      {feature.description}
                    </Typography>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      onClick={() => handleStartNow(feature.title)}
                    >
                      Start Now
                    </Button>
                  </FeatureContent>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FeatureImageContainer sx={{ 
                    borderRadius: '0 10px 10px 0', 
                    height: '100%', 
                    overflow: 'hidden',
                    display: 'flex',
                    alignItems: 'flex-start',
                  }}>
                    <FeatureImage 
                      src={featureImage} 
                      alt={feature.title} 
                      loading="lazy" 
                      zoomed={isInsightsFeature}
                    />
                  </FeatureImageContainer>
                </Grid>
              </>
            )}
          </Grid>
        </FeatureCard>
      </Grid>
    );
  };

  return (
    <Box
      id="features"
      sx={{
        py: { xs: 4, md: 8 },
        backgroundColor: theme.palette.background.default,
        overflowX: 'hidden',
      }}
    >
      <Container maxWidth="lg">
        <Typography variant="sectionTitle">
          Powerful Features for Sales Leaders
        </Typography>
        <Grid container spacing={{ xs: 8, sm: 10, md: 12 }}>
          {features.map(renderFeature)}
        </Grid>
        
        <Box 
          sx={{ 
            mt: { xs: 6, sm: 8, md: 10 }, 
            textAlign: 'center',
            backgroundColor: theme.palette.mode === 'light' ? '#F0FFFC' : theme.palette.background.paper,
            borderRadius: '16px',
            padding: { xs: 4, sm: 6, md: 8 },
            boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
          }}
        >
          <Typography
            component="h2"
            sx={{
              textAlign: 'center',
              mb: { xs: 1, sm: 2, md: 3 },
              pt: 0,
              fontWeight: 800,
              fontSize: { xs: '1.5rem', sm: '1.5rem', md: '2.5rem'},
              lineHeight: 1.2,
              background: theme.palette.mode === 'light' 
                ? 'linear-gradient(45deg, #33475B, #2E3A59)'
                : 'linear-gradient(45deg, #66E6D1, #00C0A0)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            Sign Up Now
          </Typography>
          <Typography sx={{ 
            mb: { xs: 2, sm: 3, md: 4 }, 
            fontSize: { xs: '1rem', sm: '1.5rem', md: '1.75rem' }, 
            padding: { xs: '1rem 1rem', sm: '1.5rem 3rem' }, 
            fontWeight: 400,
            lineHeight: 1.3,
            color: theme.palette.text.secondary
          }}>
            One click to get started. No credit card required.
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={onOpenSignupModal}
          >
            Sign Up
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default Features;