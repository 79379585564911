import React from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import { useHealthcheck } from '../../../contexts/HealthcheckContext';

const DealAgeDistribution = () => {
  const { 
    dealAgeDistribution, 
    loading, 
    getCommonChartProperties, 
    isMobile, 
    getChartColor 
  } = useHealthcheck() || {};

  const chartProperties = getCommonChartProperties ? getCommonChartProperties('bar') : {};

  if (loading) {
    return (
      <Box height="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Typography variant="h6">Deal Age Distribution</Typography>
        <CircularProgress />
      </Box>
    );
  }

  if (!dealAgeDistribution || dealAgeDistribution.length === 0) {
    return (
      <Box height="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Typography variant="h6">Deal Age Distribution</Typography>
        <Typography>No data available</Typography>
      </Box>
    );
  }

  return (
    <Box height="100%">
      <Typography variant="h6" align="center" gutterBottom>Deal Age Distribution</Typography>
      <ResponsiveBar
        data={dealAgeDistribution}
        keys={['count']}
        indexBy="label"
        {...chartProperties}
        margin={isMobile ? { top: 10, right: 10, bottom: 40, left: 40 } : { top: 10, right: 10, bottom: 50, left: 60 }}
        padding={0.3}
        colors={getChartColor ? [getChartColor(0)] : ['#4caf50']} // Use the first color from our chart color scheme or a default color
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: isMobile ? -45 : 0,
          legend: isMobile ? '' : 'Age Range',
          legendPosition: 'middle',
          legendOffset: 32
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: isMobile ? '' : 'Count',
          legendPosition: 'middle',
          legendOffset: -40
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      />
    </Box>
  );
};

export default DealAgeDistribution;