import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { collection, query, onSnapshot } from 'firebase/firestore';
import { useFirebase } from './FirebaseContext';
import { useAuth } from './AuthContext';
import { useTheme } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { 
  calculateDataCompleteness, 
  prepareDataCompletenessGaugeData,
  processDealAgeDistribution, 
  processMissingInformation,
  processDealAttentionNeeds,
  processForecastAccuracyScatterPlot,
  processDataQualityScoreByRep,
  calculateAverageDealVelocity,
  calculateAverageWinProbability,
  getClosingSoonDeals
} from './utils/healthcheckProcessing';
import { formatCurrency, formatPercentage, formatDate, formatNumber } from '../components/utils/formatters';
import { useChartStyles } from '../components/hooks/useChartStyles';

const HealthcheckContext = createContext();

export const useHealthcheck = () => useContext(HealthcheckContext);

export const HealthcheckProvider = ({ children }) => {
  const [allDeals, setAllDeals] = useState([]);
  const [owners, setOwners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const chartStyles = useChartStyles();
  const { db } = useFirebase();
  const { currentUser } = useAuth();

  useEffect(() => {
    if (!currentUser) {
      setAllDeals([]);
      setLoading(false);
      return;
    }

    setLoading(true);

    const dealsRef = collection(db, 'users', currentUser.uid, 'deals');
    const dealsQuery = query(dealsRef);

    const unsubscribe = onSnapshot(dealsQuery, 
      (snapshot) => {
        const deals = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setAllDeals(deals);
        setLoading(false);
      },
      (err) => {
        console.error('Error fetching deals:', err);
        setError(err);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [db, currentUser]);

  // Ensure all these values are calculated and included in the context value
  const dataCompleteness = useMemo(() => calculateDataCompleteness(allDeals), [allDeals]);
  const dataCompletenessGaugeData = useMemo(() => prepareDataCompletenessGaugeData(allDeals), [allDeals]);
  const dealAgeDistribution = useMemo(() => processDealAgeDistribution(allDeals), [allDeals]);
  const missingInformation = useMemo(() => processMissingInformation(allDeals), [allDeals]);
  const dealAttentionNeeds = useMemo(() => processDealAttentionNeeds(allDeals), [allDeals]);
  const forecastAccuracyScatterPlot = useMemo(() => processForecastAccuracyScatterPlot(allDeals), [allDeals]);
  const dataQualityScoreByRep = useMemo(() => processDataQualityScoreByRep(allDeals, owners), [allDeals, owners]);
  const dealVelocity = useMemo(() => calculateAverageDealVelocity(allDeals), [allDeals]);
  const winProbability = useMemo(() => calculateAverageWinProbability(allDeals), [allDeals]);
  const closingSoonDeals = useMemo(() => getClosingSoonDeals(allDeals), [allDeals]);

  // Ensure all necessary chart-related functions are included
  const getCommonChartProperties = (chartType) => {
    // Implement this function based on your chart requirements
    return {};
  };

  const getChartColor = (index) => {
    // Implement this function to return colors for your charts
    const colors = ['#4caf50', '#2196f3', '#ff9800', '#f44336', '#9c27b0'];
    return colors[index % colors.length];
  };

  const value = {
    allDeals,
    dataCompleteness,
    dataCompletenessGaugeData,
    dealAgeDistribution,
    missingInformation,
    dealAttentionNeeds,
    forecastAccuracyScatterPlot,
    dataQualityScoreByRep,
    dealVelocity,
    winProbability,
    closingSoonDeals,
    loading,
    error,
    formatCurrency,
    formatPercentage,
    formatDate,
    formatNumber,
    getCommonChartProperties,
    getChartColor,
    ...chartStyles,
    theme,
    isMobile,
  };

  return (
    <HealthcheckContext.Provider value={value}>
      {children}
    </HealthcheckContext.Provider>
  );
};