import React, { useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText } from '@mui/material';
import { useRevenue } from '../../contexts/RevenueContext';

const PipelineFilter = () => {
  const { uniquePipelines, selectedPipelines, updateSelectedPipelines } = useRevenue();

  useEffect(() => {
    // Initialize with all pipelines selected
    if (uniquePipelines.length > 0 && selectedPipelines.length === 0) {
      updateSelectedPipelines(uniquePipelines.map(p => p.id));
    }
  }, [uniquePipelines, selectedPipelines, updateSelectedPipelines]);

  const handleChange = (event) => {
    const value = event.target.value;
    updateSelectedPipelines(typeof value === 'string' ? value.split(',') : value);
  };

  const allSelected = selectedPipelines.length === uniquePipelines.length;

  return (
    <FormControl sx={{ m: 1, width: 300 }}>
      <InputLabel id="pipeline-filter-label">
        {allSelected ? "Showing All Pipelines" : "Pipelines"}
      </InputLabel>
      <Select
        labelId="pipeline-filter-label"
        id="pipeline-filter"
        multiple
        value={selectedPipelines}
        onChange={handleChange}
        renderValue={(selected) => 
          allSelected ? "Showing All Pipelines" : selected.map(id => uniquePipelines.find(p => p.id === id)?.label).join(', ')
        }
      >
        {uniquePipelines.map((pipeline) => (
          <MenuItem key={pipeline.id} value={pipeline.id}>
            <Checkbox checked={selectedPipelines.includes(pipeline.id)} />
            <ListItemText primary={pipeline.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default PipelineFilter;