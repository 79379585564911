import React from 'react';
import { Box, Container, Typography, Button, useTheme } from '@mui/material';

const CallToAction = ({ onOpenSignupModal }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        py: { xs: 8, md: 12 },
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Container maxWidth="lg">
        <Box 
          sx={{ 
            textAlign: 'center',
            backgroundColor: theme.palette.mode === 'light' ? '#F0FFFC' : theme.palette.background.paper,
            borderRadius: '16px',
            padding: { xs: 4, sm: 6, md: 12 },
            px: { xs: 2, sm: 4, md: 30 },
            boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography
            component="h2"
            sx={{
              textAlign: 'center',
              mb: { xs: 1, sm: 2, md: 3 },
              pt: 0,
              fontWeight: 800,
              fontSize: { xs: '1rem', sm: '1.5rem', md: '2.5rem'},
              lineHeight: 1.2,
              background: theme.palette.mode === 'light' 
                ? 'linear-gradient(45deg, #33475B, #2E3A59)'
                : 'linear-gradient(45deg, #66E6D1, #00C0A0)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            Ready to Transform Your Sales Pipeline?
          </Typography>
          <Typography sx={{ 
            mb: { xs: 2, sm: 3, md: 4 }, 
            fontSize: { xs: '1rem', sm: '1.5rem', md: '1.75rem' }, 
            fontWeight: 400,
            lineHeight: 1.3,
            color: theme.palette.text.secondary,
            padding: { xs: '1rem 1rem', sm: '1.5rem 3rem' },
          }}>
            Join the sales leaders who are leveraging AI to drive growth and efficiency. Get started with Truepipe.ai today.
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={onOpenSignupModal}
          >
            Start Now
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default CallToAction;