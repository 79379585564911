// src/scenes/sales/SalesLayout.jsx v1.0.0 - Tyler 07-21-2024 1:36

import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Box, useMediaQuery, Container } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import Topbar from "../../components/global/Topbar";
import { KPIProvider } from '../../contexts/KPIContext';
import MobileKPIStrip from '../../components/global/mobileComponents/MobileKPIStrip';
import DashboardKPIs from '../../components/global/DashboardKPIs';

const SalesLayout = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();

  return (
    <KPIProvider>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'hidden' }}>
        <Topbar />
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
          <Container maxWidth="xl" sx={{ mt: 3, mb: 2 }}>
            {isMobile ? <MobileKPIStrip /> : <DashboardKPIs />}
          </Container>
          <Box sx={{ flexGrow: 1, p: 3, overflow: 'auto', minHeight: 0 }}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </KPIProvider>
  );
};

export default SalesLayout;