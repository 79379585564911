import React, { useState, useCallback, useEffect } from 'react';
import { Box, Typography, Button, Container, Stack} from '@mui/material';
import { trackEvent } from '../utils/analytics';
import { useABTest } from '../hooks/useABTest';
import MainHero from './assets/MainHero.png';
import HeroVideo from './assets/herovideo.mp4';
import SignupModal from '../common/SignupModal';
import { styled } from '@mui/material/styles';

// Remove the StyledImageBox component

// Add this new styled component for the video
const StyledVideo = styled('video')(({ theme }) => ({
  marginTop: theme.spacing(3),
  aspectRatio: '1920 / 1022',
  objectFit: 'cover',
  objectPosition: 'center', // Add this line
  borderRadius: '25px',
  overflow: 'hidden',
  maxHeight: '500px',
  width: '100%', // Change from 'auto' to '100%'
  maxWidth: '940px', // Add this line to set a maximum width
  display: 'block', // Add this line
  margin: '0 auto', // Add this line to center the video
  border: `1px solid ${theme.palette.divider}`,
  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(4),
  },
  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(6),
  },
}));

const Hero = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const variants = useABTest({
    hero_content: ['control', 'variant_a'],
    cta_button: ['control', 'variant_b']
  });

  useEffect(() => {
    console.log('Hero component AB test variants:', variants);
  }, [variants]);

  const content = {
    hero_content: {
      control: {
        title: 'Unlock The Power of AI',
        subtitle: 'for B2B SaaS Revenue Teams',
      },
      variant_a: {
        title: 'Supercharge Your Revenue with AI',
        subtitle: 'Empower B2B SaaS Teams to Close Deals Faster and Smarter',
      }
    },
    cta_button: {
      control: {
        cta: 'Free Trial',
        caption: 'One-click. No CC required'
      },
      variant_b: {
        cta: 'Free Trial',
        caption: 'Start now. No credit card needed.'
      }
    }
  };

  // Use default values if variants are not yet available
  const heroContent = content.hero_content[variants.hero_content || 'control'];
  const ctaContent = content.cta_button[variants.cta_button || 'control'];

  const { title, subtitle } = heroContent;
  const { cta, caption } = ctaContent;

  const handleStartNow = useCallback(() => {
    const eventParams = {
      'event_category': 'engagement',
      'event_label': 'Hero CTA',
      'ab_test_hero_content': variants.hero_content,
      'ab_test_cta_button': variants.cta_button,
    };
    trackEvent('hero_cta_click', eventParams);
    console.log('Hero CTA clicked. Event params:', eventParams);
    setIsModalOpen(true);
  }, [variants]);

  return (
    <Box 
      variant="hero" 
      sx={{ 
        pt: { xs: 10, sm: 14, md: 20 },
        pb: { xs: 10, sm: 12, md: 14 },
      }}
    >
      <Container 
        maxWidth="lg" 
        disableGutters 
        sx={{ 
          px: { xs: 2, sm: 3, md: 4 }
        }}
      >
        <Stack spacing={3} useFlexGap sx={{ width: '100%', maxWidth: '70rem', margin: '0 auto' }}>
          <Typography variant="heroTitle">
            {title}
          </Typography>
          <Typography variant="heroSubtitle">
            {subtitle}
          </Typography>
          <Stack
            direction="column"
            spacing={2}
            useFlexGap
            sx={{ width: '100%', maxWidth: '30rem', margin: '0 auto', alignItems: 'center' }}
          >
            <Button
              variant="contained"
              onClick={handleStartNow}
              sx={{ height: '40px', width: '200px' }}
            >
              {cta}
            </Button>
            <Typography variant="caption" textAlign="center" sx={{ opacity: 0.8, fontSize: { xs: '0.7rem', sm: '0.75rem' } }}>
              {caption}
            </Typography>
          </Stack>
        </Stack>
        
        <StyledVideo autoPlay loop muted playsInline poster={MainHero}>
          <source src={HeroVideo} type="video/mp4" />
          <img src={MainHero} alt="AI-enabled Sales Intelligence" style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }} />
        </StyledVideo>
      </Container>
      <SignupModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </Box>
  );
};

export default Hero;