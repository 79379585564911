import React from 'react';
import { Box, Button, Card, Chip, CardActions, CardContent, Container, Divider, Grid, Typography, useTheme, useMediaQuery, Tabs, Tab } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { Link as RouterLink } from 'react-router-dom';
import { motion } from 'framer-motion';

const pricingTiers = [
  {
    title: 'Free Trial',  // Changed from 'Basic' to 'Free Trial'
    monthlyPrice: '$0',
    annualPrice: '$0',
    features: [
      'Access to all dashboards',
      'Real-time alerts and updates',
      'Scheduled email reminders',
      'Basic AI features',
      'Fetch new data from CRM once a month',
      'Watch up to 5 deals',
      'Last 3 months of historical data',
      '2 pre-built dashboards',
      'Basic CSV exports',
      'Community support',
    ],
    ctaText: 'Start Free Trial',  // Updated CTA text
    buttonVariant: 'outlined',
  },
  {
    title: 'Pro',
    monthlyPrice: '$15',
    annualPrice: '$150',
    features: [
      'All Free features, plus:',
      'Advanced AI features',
      'Unlimited refreshes with HubSpot',
      'Custom branding and white labeling',
      'Custom deal stage mapping',
      'Advanced forecasting algorithms',
      'Train own LLM on CRM data',
      'Access to benchmark data',
      'Watch up to 20 deals',
      'Full historical data access',
      'Create and save custom dashboards',
      'Advanced export options (PDF, Excel)',
      'Priority support',
    ],
    ctaText: 'Upgrade to Pro',
    buttonVariant: 'contained',
    highlighted: true,
  },
  {
    title: 'Lifetime',
    monthlyPrice: '$100',
    annualPrice: '$100',
    features: [
      'All Pro features',
      'One-time fee',
      'Limited to a single user',
      'Watch up to 50 deals',
      'Unlimited custom dashboards',
    ],
    ctaText: 'Get Lifetime Access',
    buttonVariant: 'outlined',
  },
];

const MotionCard = motion(Card);

const Pricing = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [billingCycle, setBillingCycle] = React.useState('monthly');

  const handleBillingCycleChange = (event, newBillingCycle) => {
    if (newBillingCycle !== null) {
      setBillingCycle(newBillingCycle);
    }
  };

  return (
    <Container
      id="pricing"
      sx={{
        pt: { xs: 4, sm: 8 },
        pb: { xs: 4, sm: 8 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
        width: '100%',
        maxWidth: '100% !important',
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: '70.5rem',
          mx: 'auto',
          position: 'relative',
        }}
      >
        <Typography variant="sectionTitle" sx={{ mb: { xs: 3, md: 6 } }}>
          Choose Your Plan
        </Typography>
        <Typography
          variant={isMobile ? "body1" : "h6"}
          align="center"
          color="text.secondary"
          fontSize={{ xs: '1rem', md: '1.25rem' }}
          sx={{ mb: { xs: 3, sm: 4, md: 6 }, maxWidth: '800px', mx: 'auto' }}
          
        >
          Truepipe was built by sales professionals to optimize pipeline management, track key deals, ensure accurate forecasting, and boost efficiency by shortening sales cycles, increasing deal sizes, and accelerating revenue growth.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
        <Tabs
  value={billingCycle}
  onChange={handleBillingCycleChange}
  aria-label="billing cycle"
>
  <Tab value="monthly" label="Monthly" />
  <Tab value="annual" label="Annual" />
</Tabs>
        </Box>
      </Box>
      <Grid container spacing={1} justifyContent="center">
        {pricingTiers.map((tier, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <PricingCard tier={tier} billingCycle={billingCycle} />
          </Grid>
        ))}
      </Grid>
      <Box sx={{ mt: 8, textAlign: 'center' }}>
        <Typography variant="h6" color="text.secondary" gutterBottom>
          Need a custom solution?
        </Typography>
        <Button
          component={RouterLink}
          to="/contact"
          variant="outlined"
          color="secondary"
          size="large"
          sx={{ mt: 2 }}
        >
          Contact Sales
        </Button>
      </Box>
    </Container>
  );
};

const PricingCard = ({ tier, billingCycle }) => {
  const theme = useTheme();

  const price = billingCycle === 'monthly' ? tier.monthlyPrice : tier.annualPrice;

  return (
    <MotionCard
      id="pricing"
      whileHover={{ scale: 1.05 }}
      transition={{ duration: 0.3 }}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        border: tier.highlighted ? `1px solid ${theme.palette.primary.main}` : undefined,
        background: tier.highlighted
          ? theme.palette.mode === 'dark'
            ? 'linear-gradient(#033363, #021F3B)'
            : 'linear-gradient(#F0F8FF, #E6F3FF)'
          : theme.palette.background.paper,
        borderRadius: '16px',
        width: '100%',
        maxWidth: { xs: '100%', sm: '340px' },
        margin: '0 auto',
        overflow: 'hidden',
      }}
    >
      <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', gap: 2, p: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <Typography variant="h4" component="h3" fontWeight="bold" lineHeight="1.5">
            {tier.title}
          </Typography>
          {tier.highlighted && (
            <Chip
              icon={<AutoAwesomeIcon />}
              label="POPULAR"
              size="small"
              sx={{
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.secondary.contrastText,
              }}
            />
          )}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
          <Typography variant="h3" component="span" fontWeight="bold">
            {price}
          </Typography>
          <Typography variant="h6" component="span" color="text.secondary">
            {price !== 'Free' && tier.title !== 'Lifetime' ? (billingCycle === 'monthly' ? '/month' : '/year') : ''}
          </Typography>
        </Box>
        <Divider sx={{ my: 2 }} />
        {tier.features.map((feature, idx) => (
          <Box key={idx} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <CheckCircleRoundedIcon color="secondary" fontSize="small" />
            <Typography variant="body2" color="text.secondary">
              {feature}
            </Typography>
          </Box>
        ))}
      </CardContent>
      <CardActions sx={{ justifyContent: 'center', padding: 2 }}>
        <Button
          component={RouterLink}
          to="/pricing"
          variant={tier.buttonVariant}
          color={tier.highlighted ? "secondary" : "primary"}
          size="large"
          sx={{ marginBottom: '1rem' }}
        >
          {tier.ctaText}
        </Button>
      </CardActions>
    </MotionCard>
  );
};

export default Pricing;