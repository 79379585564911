import React from 'react';
import { Box, Container, Typography, Button, Link, styled, useTheme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';

// Import both logo SVGs
import LogoWhite from '../home/assets/tp_horizontal_whitetext_logo.svg';
import LogoBlack from '../home/assets/tp_new_logo_black.svg';

const FooterContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  padding: theme.spacing(5, 2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 10),
  },
}));

const FooterContent = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

const FooterColumn = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
}));

const FooterHeading = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: '18px',
  marginBottom: theme.spacing(1),
}));

const FooterLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textDecoration: 'none',
  fontSize: '1rem',
  fontWeight: 500,
  lineHeight: 1.5,
  transition: 'color 0.15s ease-in-out',
  '&:hover': {
    color: theme.palette.secondary.main,
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  textAlign: 'center',
  cursor: 'pointer',
  borderRadius: '10em',
  justifyContent: 'center',
  alignItems: 'center',
  height: '3rem',
  padding: '.875rem 2rem',
  fontSize: '1rem',
  fontWeight: 700,
  lineHeight: 1,
  backgroundColor: theme.palette.secondary.main,
  transition: 'transform .15s cubic-bezier(.19,1,.22,1), background-color .15s cubic-bezier(.19,1,.22,1)',
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
    transform: 'scale(1.05)',
  },
}));

const Footer = ({ onOpenSignupModal }) => {
  const theme = useTheme();

  // Determine which logo to use based on the theme mode
  const logoSrc = theme.palette.mode === 'dark' ? LogoWhite : LogoBlack;

  return (
    <FooterContainer>
      <FooterContent maxWidth="lg">
        <FooterColumn>
          <img
            src={logoSrc}
            alt="truepipe logo"
            style={{ width: '140px', height: 'auto' }}
          />
          <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
            <FooterLink href="https://github.com/truepipe" target="_blank" rel="noopener noreferrer">
              <GitHubIcon />
            </FooterLink>
            <FooterLink href="https://linkedin.com/company/truepipe" target="_blank" rel="noopener noreferrer">
              <LinkedInIcon />
            </FooterLink>
            <FooterLink href="https://twitter.com/truepipe" target="_blank" rel="noopener noreferrer">
              <TwitterIcon />
            </FooterLink>
          </Box>
        </FooterColumn>
        <FooterColumn>
          <FooterHeading variant="h6">Product</FooterHeading>
          <FooterLink component={RouterLink} to="/#features">Features</FooterLink>
          <FooterLink component={RouterLink} to="/#highlights">Highlights</FooterLink>
          <FooterLink component={RouterLink} to="/#pricing">Pricing</FooterLink>
        </FooterColumn>
        <FooterColumn>
          <FooterHeading variant="h6">Company</FooterHeading>
          <FooterLink component={RouterLink} to="/careers">Careers</FooterLink>
          <FooterLink component={RouterLink} to="/terms">Terms & Conditions</FooterLink>
          <FooterLink component={RouterLink} to="/privacy">Privacy</FooterLink>
        </FooterColumn>
        <FooterColumn>
          <FooterHeading variant="h6">Contact</FooterHeading>
          <FooterLink href="mailto:support@truepipe.ai">support@truepipe.ai</FooterLink>
          <FooterLink href="mailto:security@truepipe.ai">security@truepipe.ai</FooterLink>
          <FooterLink href="mailto:press@truepipe.ai">press@truepipe.ai</FooterLink>
          <FooterLink component={RouterLink} to="/contact-us">Contact Us</FooterLink>
        </FooterColumn>
        <FooterColumn>
          <Button variant="contained" onClick={onOpenSignupModal}>Sign Up</Button>
        </FooterColumn>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;