import { calculateYoYRevenueComparison, processAvgDealSize, processCurrentYearWinRate, processDealCountData } from '../contexts/utils/revenueProcessing';

export const filterDataByPipelines = ({ deals, selectedPipelines }) => {
  const filteredDeals = selectedPipelines.length > 0
    ? deals.filter(deal => selectedPipelines.includes(deal.pipeline))
    : deals;

  return {
    filteredDeals,
    filteredYoyRevenueComparison: calculateYoYRevenueComparison(filteredDeals),
    filteredAvgDealSizeData: processAvgDealSize(filteredDeals),
    filteredCurrentYearWinRate: processCurrentYearWinRate(filteredDeals),
    filteredDealCountData: processDealCountData(filteredDeals),
  };
};