import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Card, CardContent } from '@mui/material';
import { InsightsProvider, useInsights } from '../../contexts/InsightsContext';
import { useDashboardStyles } from '../../components/hooks/useDashboardStyles';
import QueryBuilder from '../../components/sales/InsightsDashboard/QueryBuilder';
import DynamicInsightCards from '../../components/sales/InsightsDashboard/DynamicInsightCards';
import AnomalyDetection from '../../components/sales/InsightsDashboard/AnomalyDetection';
import CrossDashboardInsights from '../../components/sales/InsightsDashboard/CrossDashboardInsights';

const InsightCard = ({ title, content }) => {
  const { components } = useDashboardStyles();
  return (
    <Card sx={components.card}>
      <CardContent>
        <Typography variant="h6" component="div" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body2">
          {content}
        </Typography>
      </CardContent>
    </Card>
  );
};

const InsightsDashboardContent = () => {
  const { layout, typography, components } = useDashboardStyles();
  const { insightsData, queryResults } = useInsights();
  const [insights, setInsights] = useState([]);

  useEffect(() => {
    if (queryResults) {
      setInsights(queryResults.insights);
    } else if (insightsData) {
      setInsights(generateInsights(insightsData));
    }
  }, [queryResults, insightsData]);

  const generateInsights = (data) => {
    // This function should be updated to work with the actual insightsData structure
    return [
      {
        title: "Pipeline Overview",
        content: `You have ${data.totalDeals || 0} deals in your pipeline with a total value of $${(data.totalRevenue || 0).toLocaleString()}.`
      },
      {
        title: "Deal Size Analysis",
        content: `Your average deal size is $${(data.averageDealSize || 0).toLocaleString()}. Consider focusing on upselling strategies to increase this figure.`
      },
      // Add more insights based on the actual data structure
    ];
  };

  return (
    <Box sx={layout.pageWrapper}>
      <Typography variant="h2" sx={typography.pageTitle}>
        AI-Driven Sales Insights
      </Typography>

      <QueryBuilder />

      <Box sx={layout.sectionWrapper}>
        <Typography variant="h6" sx={typography.sectionTitle}>
          Key Insights
        </Typography>
        <DynamicInsightCards />
      </Box>

      <Grid container spacing={3}>
        {insights.map((insight, index) => (
          <Grid item xs={12} md={4} key={index}>
            <InsightCard title={insight.title} content={insight.content} />
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box sx={layout.sectionWrapper}>
            <Typography variant="h6" sx={typography.sectionTitle}>
              Anomaly Detection
            </Typography>
            <AnomalyDetection />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={layout.sectionWrapper}>
            <Typography variant="h6" sx={typography.sectionTitle}>
              Cross-Dashboard Insights
            </Typography>
            <CrossDashboardInsights />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const InsightsDashboard = () => (
  <InsightsProvider>
    <InsightsDashboardContent />
  </InsightsProvider>
);

export default InsightsDashboard;