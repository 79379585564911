import React from 'react';
import { useRevenue } from '../../../../contexts/RevenueContext';
import { useChartStyles } from '../../../hooks/useChartStyles';
import { Box, Typography, Grid, Paper } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';

const MetricCard = ({ title, value, unit }) => (
  <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
    <Typography variant="h6" gutterBottom>{title}</Typography>
    <Typography variant="h4">{value}{unit}</Typography>
  </Paper>
);

const SalesVelocityChart = () => {
  const { salesVelocity, formatCurrency, formatNumber } = useRevenue();
  const { getCommonChartProperties, getChartColor, isMobile } = useChartStyles();

  if (!salesVelocity || salesVelocity.length === 0) {
    return <Typography>No data available for Sales Velocity</Typography>;
  }

  const avgTimeBetweenContacts = salesVelocity.reduce((sum, rep) => sum + rep.avgDealTime, 0) / salesVelocity.length;
  const avgDealClosureTime = avgTimeBetweenContacts; // Assuming this is the same as avgDealTime
  const totalDeals = salesVelocity.reduce((sum, rep) => sum + rep.dealsCount, 0);
  const avgDealSize = salesVelocity.reduce((sum, rep) => sum + (rep.totalRevenue || 0), 0) / totalDeals;
  const winRate = totalDeals / salesVelocity.reduce((sum, rep) => sum + (rep.totalDeals || 0), 0);

  const barData = salesVelocity.map(rep => ({
    rep: rep.name,
    'Avg Deal Time': rep.avgDealTime,
    'Deals Count': rep.dealsCount,
  }));

  const pieData = [
    { id: 'Won', value: totalDeals },
    { id: 'Lost', value: salesVelocity.reduce((sum, rep) => sum + ((rep.totalDeals || 0) - rep.dealsCount), 0) },
  ];

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <MetricCard 
            title="Avg Time Between Contacts" 
            value={formatNumber(avgTimeBetweenContacts, 1)} 
            unit=" days"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MetricCard 
            title="Avg Deal Closure Time" 
            value={formatNumber(avgDealClosureTime, 1)} 
            unit=" days"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MetricCard 
            title="Avg Deal Size" 
            value={formatCurrency(avgDealSize)} 
            unit=""
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MetricCard 
            title="Overall Win Rate" 
            value={formatNumber(winRate * 100, 1)} 
            unit="%"
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper elevation={3} sx={{ p: 2, height: 400 }}>
            <Typography variant="h6" gutterBottom>Rep Performance</Typography>
            <ResponsiveBar
              data={barData}
              keys={['Avg Deal Time', 'Deals Count']}
              indexBy="rep"
              margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
              padding={0.3}
              valueScale={{ type: 'linear' }}
              indexScale={{ type: 'band', round: true }}
              colors={({ id, data }) => getChartColor(id === 'Avg Deal Time' ? 0 : 1)}
              borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Rep',
                legendPosition: 'middle',
                legendOffset: 32
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Value',
                legendPosition: 'middle',
                legendOffset: -40
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              legends={[
                {
                  dataFrom: 'keys',
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: 120,
                  translateY: 0,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: 'left-to-right',
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemOpacity: 1
                      }
                    }
                  ]
                }
              ]}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ p: 2, height: 400 }}>
            <Typography variant="h6" gutterBottom>Win Rate Distribution</Typography>
            <ResponsivePie
              data={pieData}
              margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
              innerRadius={0.5}
              padAngle={0.7}
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              colors={({ id }) => getChartColor(id === 'Won' ? 0 : 1)}
              borderWidth={1}
              borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: 'color' }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
              legends={[
                {
                  anchor: 'bottom',
                  direction: 'row',
                  justify: false,
                  translateX: 0,
                  translateY: 56,
                  itemsSpacing: 0,
                  itemWidth: 100,
                  itemHeight: 18,
                  itemTextColor: '#999',
                  itemDirection: 'left-to-right',
                  itemOpacity: 1,
                  symbolSize: 18,
                  symbolShape: 'circle',
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemTextColor: '#000'
                      }
                    }
                  ]
                }
              ]}
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SalesVelocityChart;