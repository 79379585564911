// src/contexts/utils/insightsProcessing.jsx v1.2.1

export const processDealsForInsights = (deals) => {
  return {
    totalRevenue: calculateTotalRevenue(deals),
    averageDealSize: calculateAverageDealSize(deals),
    dealsByStage: groupDealsByStage(deals),
    topPerformers: getTopPerformers(deals),
    revenueByProduct: calculateRevenueByProduct(deals),
    recentlyClosedDeals: getRecentlyClosedDeals(deals),
    highValueDeals: getHighValueDeals(deals),
    dealsClosedThisQuarter: getDealsClosedThisQuarter(deals),
    averageDealVelocity: calculateAverageDealVelocity(deals),
    averageCustomerLifetimeValue: calculateAverageCustomerLifetimeValue(deals),
    dealsByPipeline: groupDealsByPipeline(deals),
    winRate: calculateWinRate(deals),
  };
};

export const generateDynamicInsights = (processedData, metric, comparison) => {
  const insights = [];

  switch (metric) {
    case 'Total Revenue':
      insights.push(generateRevenueInsight(processedData.totalRevenue, comparison));
      break;
    case 'Number of Deals':
      insights.push(generateDealCountInsight(processedData.dealsClosedThisQuarter.length, comparison));
      break;
    case 'Average Deal Size':
      insights.push(generateAverageDealSizeInsight(processedData.averageDealSize, comparison));
      break;
    case 'Win Rate':
      insights.push(generateWinRateInsight(processedData.winRate, comparison));
      break;
    case 'Deal Velocity':
      insights.push(generateDealVelocityInsight(processedData.averageDealVelocity, comparison));
      break;
    case 'Customer Lifetime Value':
      insights.push(generateCustomerLifetimeValueInsight(processedData.averageCustomerLifetimeValue, comparison));
      break;
  }

  // Add top performer insight
  if (processedData.topPerformers.length > 0) {
    insights.push(generateTopPerformerInsight(processedData.topPerformers[0]));
  }

  // Add anomaly detection
  const anomalies = detectAnomalies(processedData);
  insights.push(...anomalies);

  return insights;
};

// Helper functions

const calculateTotalRevenue = (deals) => {
  return deals.reduce((total, deal) => total + (deal.amount || 0), 0);
};

const calculateAverageDealSize = (deals) => {
  const totalRevenue = calculateTotalRevenue(deals);
  return deals.length > 0 ? totalRevenue / deals.length : 0;
};

const groupDealsByStage = (deals) => {
  return deals.reduce((acc, deal) => {
    const stage = deal.customStage || 'Unknown';
    if (!acc[stage]) {
      acc[stage] = [];
    }
    acc[stage].push(deal);
    return acc;
  }, {});
};

const getTopPerformers = (deals) => {
  const performerDeals = deals.reduce((acc, deal) => {
    const ownerId = deal.hubspot_owner_id || 'Unknown';
    if (!acc[ownerId]) {
      acc[ownerId] = { totalRevenue: 0, dealCount: 0 };
    }
    acc[ownerId].totalRevenue += deal.amount || 0;
    acc[ownerId].dealCount += 1;
    return acc;
  }, {});

  return Object.entries(performerDeals)
    .map(([ownerId, data]) => ({
      ownerId,
      totalRevenue: data.totalRevenue,
      dealCount: data.dealCount,
      averageDealSize: data.totalRevenue / data.dealCount
    }))
    .sort((a, b) => b.totalRevenue - a.totalRevenue)
    .slice(0, 5); // Top 5 performers
};

const calculateRevenueByProduct = (deals) => {
  // Assuming we have a product field in the deal object
  return deals.reduce((acc, deal) => {
    const product = deal.product || 'Unknown';
    if (!acc[product]) {
      acc[product] = 0;
    }
    acc[product] += deal.amount || 0;
    return acc;
  }, {});
};

const getRecentlyClosedDeals = (deals) => {
  const twoWeeksAgo = new Date();
  twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
  return deals.filter(deal => 
    new Date(deal.closedate) >= twoWeeksAgo && 
    deal.hs_deal_stage_probability === 1
  );
};

const getHighValueDeals = (deals) => {
  const averageDealSize = calculateAverageDealSize(deals);
  const highValueThreshold = averageDealSize * 2;
  return deals.filter(deal => deal.amount > highValueThreshold);
};

const getDealsClosedThisQuarter = (deals) => {
  const now = new Date();
  const quarterStart = new Date(now.getFullYear(), Math.floor(now.getMonth() / 3) * 3, 1);
  return deals.filter(deal => 
    new Date(deal.closedate) >= quarterStart && 
    deal.hs_deal_stage_probability === 1
  );
};

const calculateAverageDealVelocity = (deals) => {
  const totalVelocity = deals.reduce((sum, deal) => sum + (deal.dealVelocity || 0), 0);
  return deals.length > 0 ? totalVelocity / deals.length : 0;
};

const calculateAverageCustomerLifetimeValue = (deals) => {
  const totalCLV = deals.reduce((sum, deal) => sum + (deal.customerLifetimeValue || 0), 0);
  return deals.length > 0 ? totalCLV / deals.length : 0;
};

const groupDealsByPipeline = (deals) => {
  return deals.reduce((acc, deal) => {
    const pipeline = deal.pipeline || 'Unknown';
    if (!acc[pipeline]) {
      acc[pipeline] = [];
    }
    acc[pipeline].push(deal);
    return acc;
  }, {});
};

const calculateWinRate = (deals) => {
  const wonDeals = deals.filter(deal => deal.hs_deal_stage_probability === 1).length;
  return deals.length > 0 ? wonDeals / deals.length : 0;
};

// Insight generation functions

const generateRevenueInsight = (totalRevenue, comparison) => ({
  type: 'revenue',
  title: 'Total Revenue',
  value: totalRevenue,
  insight: `Total revenue is $${totalRevenue.toLocaleString()}`,
  // Add comparison logic here
});

const generateDealCountInsight = (dealCount, comparison) => ({
  type: 'dealCount',
  title: 'Number of Deals',
  value: dealCount,
  insight: `${dealCount} deals were closed`,
  // Add comparison logic here
});

const generateAverageDealSizeInsight = (averageDealSize, comparison) => ({
  type: 'averageDealSize',
  title: 'Average Deal Size',
  value: averageDealSize,
  insight: `Average deal size is $${averageDealSize.toLocaleString()}`,
  // Add comparison logic here
});

const generateWinRateInsight = (winRate, comparison) => ({
  type: 'winRate',
  title: 'Win Rate',
  value: winRate,
  insight: `Current win rate is ${(winRate * 100).toFixed(2)}%`,
  // Add comparison logic here
});

const generateDealVelocityInsight = (averageDealVelocity, comparison) => ({
  type: 'dealVelocity',
  title: 'Average Deal Velocity',
  value: averageDealVelocity,
  insight: `Average deal velocity is ${averageDealVelocity.toFixed(2)} days`,
  // Add comparison logic here
});

const generateCustomerLifetimeValueInsight = (averageCustomerLifetimeValue, comparison) => ({
  type: 'customerLifetimeValue',
  title: 'Average Customer Lifetime Value',
  value: averageCustomerLifetimeValue,
  insight: `Average customer lifetime value is $${averageCustomerLifetimeValue.toLocaleString()}`,
  // Add comparison logic here
});

const generateTopPerformerInsight = (topPerformer) => ({
  type: 'topPerformer',
  title: 'Top Performer',
  value: topPerformer.totalRevenue,
  insight: `Top performer: ${topPerformer.ownerId} with $${topPerformer.totalRevenue.toLocaleString()} in total revenue`,
  historicalData: [], // You'll need to implement this
});

const detectAnomalies = (processedData) => {
  const anomalies = [];

  // Check for unusually large deals
  if (processedData.averageDealSize && processedData.highValueDeals) {
    const largeDealsCount = processedData.highValueDeals.length;
    if (largeDealsCount > 0) {
      anomalies.push({
        type: 'anomaly',
        title: 'Unusually Large Deals Detected',
        insight: `Detected ${largeDealsCount} unusually large deals, exceeding twice the average deal size.`,
        value: largeDealsCount,
      });
    }
  }

  // Check for significant difference between top performers
  if (processedData.topPerformers && processedData.topPerformers.length > 1) {
    const [top, second] = processedData.topPerformers;
    if (top.totalRevenue > second.totalRevenue * 2) {
      anomalies.push({
        type: 'anomaly',
        title: 'Large Performance Gap',
        insight: `Top performer's revenue is more than double the second-best performer. Consider investigating the reason for this gap.`,
        value: top.totalRevenue / second.totalRevenue,
      });
    }
  }

  // Add more anomaly detection logic as needed

  return anomalies;
};

export const generateInsights = (processedData) => {
  const insights = [];

  if (processedData.totalRevenue) {
    insights.push({
      type: 'revenue',
      message: `Total revenue: $${processedData.totalRevenue.toLocaleString()}`
    });
  }

  if (processedData.averageDealSize) {
    insights.push({
      type: 'averageDealSize',
      message: `Average deal size: $${processedData.averageDealSize.toLocaleString()}`
    });
  }

  if (processedData.topPerformers && processedData.topPerformers.length > 0) {
    const topPerformer = processedData.topPerformers[0];
    insights.push({
      type: 'topPerformer',
      message: `Top performer: ${topPerformer.ownerId} with $${topPerformer.totalRevenue.toLocaleString()} in total revenue`
    });
  }

  // Add more insights based on the processed data
  return insights;
};

export const predictTrends = (processedData) => {
  // This function would require historical data to make meaningful predictions
  // For now, we'll return a placeholder message
  return [{
    type: 'trendPrediction',
    message: 'Trend prediction requires historical data analysis. Feature coming soon.'
  }];
};