// src/components/sales/RevenueDashboard/charts/YoYRevenueArea.jsx v1.0.0

import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import { Box } from '@mui/material';
import { useRevenue } from '../../../../contexts/RevenueContext';

const YoYRevenueArea = ({ data }) => {
  const {
    formatCurrency,
    getCommonChartProperties,
    getYoYRevenueComparisonTooltip,
    getCustomTooltip,
    isMobile,
    getChartColorPair,
    getCurrentAndPreviousYear
  } = useRevenue();

  const { currentYear, previousYear } = getCurrentAndPreviousYear();
  const commonProperties = getCommonChartProperties('line');

  const chartProps = {
    ...commonProperties,
    data,
    xScale: { type: 'point' },
    yScale: { type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false },
    axisBottom: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Month',
      legendOffset: 36,
      legendPosition: 'middle'
    },
    axisLeft: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Revenue',
      legendOffset: -40,
      legendPosition: 'middle',
      format: value => formatCurrency(value, { notation: 'compact' })
    },
    enableArea: true,
    areaOpacity: 0.2,
    enableSlices: 'x',
    sliceTooltip: ({ slice }) => getCustomTooltip(getYoYRevenueComparisonTooltip(slice)),
    legends: isMobile ? [] : [
      {
        anchor: 'bottom-right',
        direction: 'column',
        justify: false,
        translateX: 100,
        translateY: 0,
        itemsSpacing: 0,
        itemDirection: 'left-to-right',
        itemWidth: 80,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: 'circle',
        symbolBorderColor: 'rgba(0, 0, 0, .5)',
        effects: [
          {
            on: 'hover',
            style: {
              itemBackground: 'rgba(0, 0, 0, .03)',
              itemOpacity: 1
            }
          }
        ]
      }
    ],
    colors: ({ id }) => getChartColorPair(id.includes(currentYear) ? 0 : 1).lineColor
  };

  return (
    <Box>
      <Box sx={{ height: 400 }}>
        <ResponsiveLine {...chartProps} />
      </Box>
    </Box>
  );
};

export default YoYRevenueArea;