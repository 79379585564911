import React from 'react';
import { Box, Typography, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { useInsights } from '../../../contexts/InsightsContext';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';

const CrossDashboardInsights = () => {
  const { dynamicInsights, loading, error } = useInsights();

  if (loading) {
    return <Typography>Loading cross-dashboard insights...</Typography>;
  }

  if (error) {
    return <Typography color="error">Error loading cross-dashboard insights: {error}</Typography>;
  }

  // Filter insights to get only cross-dashboard insights
  const crossInsights = dynamicInsights.filter(insight => insight.type === 'crossDashboard');

  return (
    <Box>
      <Typography variant="h6" gutterBottom>Cross-Dashboard Insights</Typography>
      {crossInsights.length > 0 ? (
        <List>
          {crossInsights.map((insight, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                <CompareArrowsIcon color="primary" />
              </ListItemIcon>
              <ListItemText 
                primary={insight.title}
                secondary={insight.insight}
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography>No cross-dashboard insights available.</Typography>
      )}
    </Box>
  );
};

export default CrossDashboardInsights;