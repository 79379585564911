import React, { useState } from 'react';
import { Box, Typography, Grid, TextField, Autocomplete, Button, CircularProgress } from '@mui/material';
import { useInsights } from '../../../contexts/InsightsContext';

const QueryBuilder = () => {
  const { executeQuery, availableMetrics, availableTimeframes, availableComparisons } = useInsights();
  const [queryParts, setQueryParts] = useState({ metric: '', timeframe: '', comparison: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleQueryPartChange = (part, value) => {
    setQueryParts(prev => ({ ...prev, [part]: value }));
    setError('');
  };

  const handleAnalyze = async () => {
    const { metric, timeframe, comparison } = queryParts;
    if (metric && timeframe && comparison) {
      setIsLoading(true);
      setError('');
      const query = `Show me ${metric} for ${timeframe} ${comparison}`;
      try {
        await executeQuery(query);
      } catch (err) {
        setError('An error occurred while processing your query. Please try again.');
      } finally {
        setIsLoading(false);
      }
    } else {
      setError('Please select all query parts before analyzing.');
    }
  };

  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="h6" gutterBottom>
        Ask a question about your sales data
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={3}>
          <Autocomplete
            options={availableMetrics}
            renderInput={(params) => <TextField {...params} label="Metric" />}
            onChange={(event, value) => handleQueryPartChange('metric', value)}
            value={queryParts.metric}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Autocomplete
            options={availableTimeframes}
            renderInput={(params) => <TextField {...params} label="Time Frame" />}
            onChange={(event, value) => handleQueryPartChange('timeframe', value)}
            value={queryParts.timeframe}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Autocomplete
            options={availableComparisons}
            renderInput={(params) => <TextField {...params} label="Comparison" />}
            onChange={(event, value) => handleQueryPartChange('comparison', value)}
            value={queryParts.comparison}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAnalyze}
            fullWidth
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Analyze'}
          </Button>
        </Grid>
      </Grid>
      {error && (
        <Typography color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default QueryBuilder;