import React from 'react';
import { Box, Typography, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { useInsights } from '../../../contexts/InsightsContext';
import WarningIcon from '@mui/icons-material/Warning';

const AnomalyDetection = () => {
  const { dynamicInsights, loading, error } = useInsights();

  if (loading) {
    return <Typography>Loading anomalies...</Typography>;
  }

  if (error) {
    return <Typography color="error">Error loading anomalies: {error}</Typography>;
  }

  // Filter insights to get only anomalies
  const anomalies = dynamicInsights.filter(insight => insight.type === 'anomaly');

  return (
    <Box>
      <Typography variant="h6" gutterBottom>Anomaly Detection</Typography>
      {anomalies.length > 0 ? (
        <List>
          {anomalies.map((anomaly, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                <WarningIcon color="warning" />
              </ListItemIcon>
              <ListItemText 
                primary={anomaly.title}
                secondary={anomaly.insight}
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography>No anomalies detected.</Typography>
      )}
    </Box>
  );
};

export default AnomalyDetection;