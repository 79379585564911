import React, { useState } from 'react';
import Hero from '../../components/home/Hero';
import Features from '../../components/home/Features';
import HowItWorks from '../../components/home/HowItWorks';
import Highlights from '../../components/home/Highlights';
import FAQ from '../../components/home/FAQ';
import CallToAction from '../../components/home/CallToAction';
import Pricing from '../../components/home/Pricing';
import Layout from '../../components/home/Layout';
import SignupModal from '../../components/common/SignupModal';
import { Box } from '@mui/material';

const HomePage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenSignupModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseSignupModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Layout>
      <Box sx={{ '& > *:not(:last-child)': { mb: 8 } }}>
        <Hero onOpenSignupModal={handleOpenSignupModal} />
        <Features onOpenSignupModal={handleOpenSignupModal} />
        <HowItWorks />
        <Highlights />
        <Pricing onOpenSignupModal={handleOpenSignupModal} />
        <FAQ />
        <CallToAction onOpenSignupModal={handleOpenSignupModal} />
      </Box>
      <SignupModal
        open={isModalOpen}
        onClose={handleCloseSignupModal}
      />
    </Layout>
  );
};

export default HomePage;