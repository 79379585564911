import React from 'react';
import { ResponsiveScatterPlot } from '@nivo/scatterplot';
import { useTheme } from '@mui/material';
import CustomTooltip from '../../charts/CustomTooltip';
import { useHealthcheck } from '../../../contexts/HealthcheckContext';
import { turquoise, mint } from '../../../theme';

const ForecastAccuracyScatterPlot = () => {
  const theme = useTheme();
  const { 
    forecastAccuracyScatterPlot,
    loading,
    getCommonChartProperties, 
    isMobile,
    formatCurrency,
    formatPercentage
  } = useHealthcheck();

  const commonProperties = getCommonChartProperties('scatter');

  if (loading) {
    return <div>Loading forecast accuracy data...</div>;
  }

  if (!forecastAccuracyScatterPlot || forecastAccuracyScatterPlot.length === 0 || !forecastAccuracyScatterPlot[0].data || forecastAccuracyScatterPlot[0].data.length === 0) {
    return <div>No forecast accuracy data available.</div>;
  }

  // Calculate min and max values for x and y
  const allValues = forecastAccuracyScatterPlot[0].data.flatMap(d => [d.x, d.y]);
  const minValue = Math.min(...allValues);
  const maxValue = Math.max(...allValues);

  // Apply buffer
  const buffer = (maxValue - minValue) * 0.1;
  const minBuffer = minValue - buffer;
  const maxBuffer = maxValue + buffer;

  // Custom color scale function
  const getColor = (node) => {
    if (!node || !node.data) return theme.palette.mode === 'dark' ? turquoise : mint;
    
    const baseColor = theme.palette.mode === 'dark' ? turquoise : mint;
    const accuracy = node.data.accuracy;
    
    // Create a gradient from red (inaccurate) to the base color (accurate)
    const r = Math.round(255 - (255 - parseInt(baseColor.slice(1, 3), 16)) * accuracy);
    const g = Math.round(parseInt(baseColor.slice(3, 5), 16) * accuracy);
    const b = Math.round(parseInt(baseColor.slice(5, 7), 16) * accuracy);
    
    return `rgb(${r}, ${g}, ${b})`;
  };

  return (
    <ResponsiveScatterPlot
      data={forecastAccuracyScatterPlot}
      {...commonProperties}
      xScale={{ 
        type: 'linear', 
        min: minBuffer, 
        max: maxBuffer 
      }}
      yScale={{ 
        type: 'linear', 
        min: minBuffer, 
        max: maxBuffer 
      }}
      nodeSize={node => {
        if (!node || !node.data) return 4; // Minimum node size
        const amount = node.data.y; // Actual amount
        const maxAmount = Math.max(...forecastAccuracyScatterPlot[0].data.map(d => d.y));
        return 4 + (amount / maxAmount) * 16; // Scale node size between 4 and 20
      }}
      colors={getColor}
      blendMode="normal"
      tooltip={({ node }) => (
        <CustomTooltip
          title={node.data.dealName}
          items={[
            { label: 'Forecasted Amount', value: node.data.x, formatType: 'currency' },
            { label: 'Actual Amount', value: node.data.y, formatType: 'currency' },
            { label: 'Forecast Accuracy', value: node.data.accuracy, formatType: 'percentage' },
          ]}
        />
      )}
      axisBottom={{
        ...commonProperties.axisBottom,
        legend: 'Forecasted Amount',
        legendPosition: 'middle',
        legendOffset: 40,
      }}
      axisLeft={{
        ...commonProperties.axisLeft,
        legend: 'Actual Amount',
        legendPosition: 'middle',
        legendOffset: -40,
      }}
    />
  );
};

export default ForecastAccuracyScatterPlot;