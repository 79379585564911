  // Track custom events
  export const trackEvent = (eventName, eventParams = {}) => {
    if (window.dataLayer) {
      // Ensure A/B test data is always a string
      const safeParams = {
        ...eventParams,
        ab_test_cta_button: String(eventParams.ab_test_cta_button || 'not_set'),
        ab_test_hero_content: String(eventParams.ab_test_hero_content || 'not_set')
      };
      
      window.dataLayer.push({
        event: eventName,
        ...safeParams
      });
      console.log('Event pushed to dataLayer:', eventName, safeParams);
      console.log('Current dataLayer:', JSON.parse(JSON.stringify(window.dataLayer)));
    } else {
      console.warn('dataLayer not found. Event not tracked:', eventName);
    }
  };
  
  // Track A/B test impression
  export const trackABTestVariant = (testName, variant) => {
    if (window.dataLayer) {
      const eventData = {
        event: 'ab_test_variant',
        test_name: testName,
        variant: variant
      };
      window.dataLayer.push(eventData);
      console.log('AB Test Variant tracked:', eventData);
      console.log('Current dataLayer:', JSON.parse(JSON.stringify(window.dataLayer)));
    } else {
      console.warn('dataLayer not found. AB Test Variant not tracked:', testName, variant);
    }
  };

  // Remove the trackExperiment function if it's not being used elsewhere