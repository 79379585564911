import React from 'react';
import { Box, Typography, Grid, Card, CardContent, Icon, Tooltip } from '@mui/material';
import { useInsights } from '../../../contexts/InsightsContext';
import { useChartStyles } from '../../hooks/useChartStyles';
import { formatCurrency, formatPercentage, formatNumber } from '../../utils/formatters';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { Sparklines, SparklinesLine } from 'react-sparklines';

const insightTypeIcons = {
  revenue: TrendingUpIcon,
  averageDealSize: TrendingUpIcon,
  topPerformer: TrendingUpIcon,
  recentlyClosed: TrendingUpIcon,
  // Add more icon mappings as needed
};

const DynamicInsightCards = () => {
  const { dynamicInsights, loading, error } = useInsights();
  const { getCommonChartProperties, getChartColor, isMobile, getTooltipStyles } = useChartStyles();

  console.log('DynamicInsightCards - dynamicInsights:', dynamicInsights);
  console.log('DynamicInsightCards - loading:', loading);
  console.log('DynamicInsightCards - error:', error);

  if (loading) {
    return <Typography>Loading insights...</Typography>;
  }

  if (error) {
    return <Typography color="error">Error loading insights: {error}</Typography>;
  }

  // Add this check
  if (!Array.isArray(dynamicInsights) || dynamicInsights.length === 0) {
    return <Typography>No insights available at the moment.</Typography>;
  }

  const formatInsightValue = (type, value) => {
    switch (type) {
      case 'revenue':
      case 'averageDealSize':
        return formatCurrency(value);
      case 'topPerformer':
        return formatCurrency(value);
      case 'recentlyClosed':
        return formatNumber(value);
      default:
        return formatNumber(value);
    }
  };

  return (
    <Grid container spacing={isMobile ? 1 : 2}>
      {dynamicInsights.map((insight, index) => {
        console.log('Rendering insight:', insight);
        const IconComponent = insightTypeIcons[insight.type] || TrendingUpIcon;
        const color = getChartColor(index);

        // Add null checks for each property
        if (!insight || typeof insight !== 'object') {
          console.error('Invalid insight object:', insight);
          return null;
        }

        return (
          <Grid item xs={12} sm={6} md={3} key={insight.type || index}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                <Box display="flex" alignItems="center" mb={1}>
                  <Icon component={IconComponent} sx={{ color, mr: 1 }} />
                  <Typography variant="body1" color={color}>
                    {insight.title || 'Untitled Insight'}
                  </Typography>
                </Box>
                <Typography variant="h5" component="div" gutterBottom>
                  {formatInsightValue(insight.type, insight.value)}
                </Typography>
                <Typography variant="body2" sx={{ mb: 1, flexGrow: 1 }}>
                  {insight.insight || 'No insight available'}
                </Typography>
                {Array.isArray(insight.historicalData) && insight.historicalData.length > 0 && (
                  <Tooltip
                    title="Historical trend"
                    placement="top"
                    arrow
                    componentsProps={{
                      tooltip: {
                        sx: getTooltipStyles().container,
                      },
                    }}
                  >
                    <Box sx={{ mb: 0.5 }}>
                      <Sparklines data={insight.historicalData} width={100} height={20}>
                        <SparklinesLine color={color} />
                      </Sparklines>
                    </Box>
                  </Tooltip>
                )}
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default DynamicInsightCards;