import React, { useState } from 'react';
import { Box, Typography, Grid, Tabs, Tab } from '@mui/material';
import { RevenueProvider, useRevenue } from '../../contexts/RevenueContext';
// import DashboardKPIs from '../../components/global/DashboardKPIs';
import { useDashboardStyles } from '../../components/hooks/useDashboardStyles';
import SkeletonLoader from '../../utils/SkeletonLoader';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import PipelineFilter from '../../components/utils/PipelineFilter';

// Working Charts
import YoYRevenueArea from '../../components/sales/RevenueDashboard/charts/YoYRevenueArea';
import YoYRevenueBar from '../../components/sales/RevenueDashboard/charts/YoYRevenueBar';
import RepPerformanceChart from '../../components/sales/RevenueDashboard/charts/RepPerformanceChart';
import WinRateChart from '../../components/sales/RevenueDashboard/charts/WinRateChart';
import AvgDealSizeLine  from '../../components/sales/RevenueDashboard/charts/AvgDealSizeLine';
import DealCountComparison from '../../components/sales/RevenueDashboard/charts/DealCountComparison';
import RevenueInsights from '../../components/sales/RevenueDashboard/charts/RevenueInsights';

// Performance Tab Charts (to be implemented)
import SalesActivityCorrelationChart from '../../components/sales/RevenueDashboard/charts/SalesActivityCorrelationChart';
import SalesVelocityChart from '../../components/sales/RevenueDashboard/charts/SalesVelocityChart';
import PipelineHealthChart from '../../components/sales/RevenueDashboard/charts/PipelineHealthChart';
import RevenueForecastVsActual from '../../components/sales/RevenueDashboard/charts/RevenueForecastVsActual';
// import RepActivityTimeline from '../../components/sales/RevenueDashboard/charts/RepActivityTimeline';

// New Charts

const RevenueDashboard = () => (
  <RevenueProvider>
    <RevenueDashboardContent />
  </RevenueProvider>
);

const PlaceholderChart = ({ title }) => {
  const { layout } = useDashboardStyles();
  return (
    <Box sx={layout.chartContainer}>
      <Typography>{title}</Typography>
    </Box>
  );
};

const RevenueDashboardContent = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [chartType, setChartType] = useState('area');
  const { layout, typography, components } = useDashboardStyles();
  const { loading, filteredData, updateSelectedPipelines } = useRevenue();

  const handleChartTypeChange = (event, newChartType) => {
    if (newChartType !== null) {
      setChartType(newChartType);
    }
  };

  return (
    <Box sx={layout.pageWrapper}>

      <Typography variant="h2" sx={typography.pageTitle}>
        Revenue Analysis
      </Typography>
      
      <PipelineFilter onChange={updateSelectedPipelines} />
      
      <Tabs 
        value={activeTab} 
        onChange={(event, newValue) => setActiveTab(newValue)}
        sx={components.tabs.root}
      >
        <Tab label="Overview" sx={components.tabs.tab} />
        <Tab label="Performance" sx={components.tabs.tab} />
      </Tabs>

      {activeTab === 0 && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box sx={layout.sectionWrapper}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6" sx={typography.sectionTitle}>Year-over-Year Revenue Comparison</Typography>
                <ToggleButtonGroup
                  value={chartType}
                  exclusive
                  onChange={handleChartTypeChange}
                  aria-label="chart type"
                  size="small"
                >
                  <ToggleButton value="area" aria-label="area chart">
                    <ShowChartIcon />
                  </ToggleButton>
                  <ToggleButton value="bar" aria-label="bar chart">
                    <BarChartIcon />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
              {loading ? (
                <SkeletonLoader height={300} />
              ) : (
                chartType === 'area' ? <YoYRevenueArea data={filteredData.filteredYoyRevenueComparison} /> : <YoYRevenueBar data={filteredData.filteredYoyRevenueComparison} />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={layout.sectionWrapper}>
              <Typography variant="h6" sx={typography.sectionTitle}>Avg Deal Size</Typography>
              {loading ? <SkeletonLoader height={300} /> : <AvgDealSizeLine data={filteredData.filteredAvgDealSizeData} />}
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={layout.sectionWrapper}>
              <Typography variant="h6" sx={typography.sectionTitle}>Deal Count Comparison</Typography>
              {loading ? <SkeletonLoader height={300} /> : <DealCountComparison data={filteredData.filteredDealCountData} />}
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={layout.sectionWrapper}>
              <Typography variant="h6" sx={typography.sectionTitle}>Win Rate</Typography>
              {loading ? <SkeletonLoader height={300} /> : <WinRateChart data={filteredData.filteredCurrentYearWinRate} />}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={layout.sectionWrapper}>
              <Typography variant="h6" sx={typography.sectionTitle}>Revenue Insights</Typography>
              <RevenueInsights data={filteredData.filteredRevenueInsights} />
            </Box>
          </Grid>
        </Grid>
      )}

      {activeTab === 1 && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box sx={layout.sectionWrapper}>
              <Typography variant="h6" sx={typography.sectionTitle}>Rep Performance Comparison</Typography>
              {loading ? <SkeletonLoader height={400} /> : <RepPerformanceChart />}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={layout.sectionWrapper}>
              <Typography variant="h6" sx={typography.sectionTitle}>Sales Activity vs. Revenue Correlation</Typography>
              {loading ? <SkeletonLoader height={400} /> : <SalesActivityCorrelationChart />}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={layout.sectionWrapper}>
              <Typography variant="h6" sx={typography.sectionTitle}>Sales Velocity Dashboard</Typography>
              {loading ? <SkeletonLoader height={400} /> : <SalesVelocityChart />}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={layout.sectionWrapper}>
              <Typography variant="h6" sx={typography.sectionTitle}>Pipeline Health by Rep</Typography>
              {loading ? <SkeletonLoader height={400} /> : <PipelineHealthChart data={filteredData.filteredPipelineHealthData} />}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={layout.sectionWrapper}>
              <Typography variant="h6" sx={typography.sectionTitle}>Rep Activity Timeline</Typography>
              {loading ? <SkeletonLoader height={400} /> : <PlaceholderChart title="Rep Activity Timeline" />}
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default RevenueDashboard;