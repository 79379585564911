import React, { createContext, useContext, useState, useEffect } from 'react';
import { getFirestore, collection, query, orderBy, onSnapshot, where, limit, doc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const UsersDataContext = createContext();

export const useUsersData = () => useContext(UsersDataContext);

export const UsersDataProvider = ({ children }) => {
  const [syncProgress, setSyncProgress] = useState([]);
  const [finalTotals, setFinalTotals] = useState(null);
  const [overviewData, setOverviewData] = useState(null);
  const [pipelineData, setPipelineData] = useState({
    totalValue: 0,
    avgDealSize: 0,
    dealsCreated: 0,
    dealsToClose: 0,
    stages: []
  });
  const [healthcheckData, setHealthcheckData] = useState(null);
  const [insightsData, setInsightsData] = useState(null);
  const [revenueData, setRevenueData] = useState(null);
  const [salesAIData, setSalesAIData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [syncStatus, setSyncStatus] = useState('loading');
  const [isLoading, setIsLoading] = useState(true);
  const [criticalDeals, setCriticalDeals] = useState([]);
  const [closingSoonDeals, setClosingSoonDeals] = useState([]);
  const [highImpactDeals, setHighImpactDeals] = useState([]);
  const [userHubSpotInfo, setUserHubSpotInfo] = useState(null);
  const [owners, setOwners] = useState({});
  const [pastDueDeals, setPastDueDeals] = useState([]);
  const [missingDataDeals, setMissingDataDeals] = useState([]);
  const [dealsCreated, setDealsCreated] = useState([]);
  const [dealsToClose, setDealsToClose] = useState([]);
  const [allDeals, setAllDeals] = useState([]);
  const [openDeals, setOpenDeals] = useState([]);
  const [pipelineStages, setPipelineStages] = useState([]);
  const [stageMapping, setStageMapping] = useState({});

  useEffect(() => {
    console.log('UsersDataProvider: Setting up listener');
    const auth = getAuth();
    const db = getFirestore();

    const unsubscribe = auth.onAuthStateChanged(user => {
      console.log('Auth state changed. User:', user ? user.uid : 'No user');
      if (user) {
        const q = query(
          collection(db, 'users', user.uid, 'syncProgress'),
          orderBy('timestamp', 'desc')
        );

        const unsubscribeSnapshot = onSnapshot(q, 
          (snapshot) => {
            const newProgress = snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            }));
            setSyncProgress(newProgress);

            // Update totals calculation
            const totals = {
              deals: 0,
              contacts: 0,
              companies: 0,
              engagements: 0,
              salesReps: 0
            };

            newProgress.forEach(step => {
              const message = step.message || '';
              if (step.step === 'fetchDeals' && message.includes('Found')) {
                totals.deals = parseInt(message.split(' ')[1]) || 0;
              } else if (step.step === 'fetchContacts' && message.includes('Found')) {
                totals.contacts = parseInt(message.split(' ')[1]) || 0;
              } else if (step.step === 'fetchCompanies' && message.includes('Found')) {
                totals.companies = parseInt(message.split(' ')[1]) || 0;
              } else if (step.step === 'fetchEngagements' && message.includes('Fetch')) {
                totals.engagements = parseInt(message.split(' ')[1]) || 0;
              } else if (step.step === 'fetchOwners' && message.includes('Fetched')) {
                totals.salesReps = parseInt(message.split(' ')[1]) || 0;
              }
            });
            setFinalTotals(totals);

            // Update sync status
            if (syncProgress.length === 0) {
              setSyncStatus('needs_update');
            } else if (syncProgress.every(step => step.status === 'completed')) {
              setSyncStatus('synced');
            } else {
              setSyncStatus('syncing');
            }

            setLoading(false);
          },
          (err) => {
            console.error('Error in Firestore snapshot listener:', err);
            setError(err);
            setLoading(false);
          }
        );

        const aggregatesRef = collection(db, 'users', user.uid, 'aggregates');
        
        const unsubscribeAggregates = onSnapshot(aggregatesRef, (snapshot) => {
          const newAggregates = {};
          snapshot.docs.forEach(doc => {
            newAggregates[doc.id] = doc.data();
          });

          setOverviewData(newAggregates.overview || null);
          setPipelineData(newAggregates.pipeline || {
            totalValue: 0,
            avgDealSize: 0,
            dealsCreated: 0,
            dealsToClose: 0,
            stages: []
          });
          setHealthcheckData(newAggregates.healthcheck || null);
          setInsightsData(newAggregates.insights || null);
          console.log('Insights Data set:', newAggregates.insights);
          
          try {
            setRevenueData(newAggregates.revenue || null);
            console.log('Revenue Data set:', newAggregates.revenue);
          } catch (error) {
            console.error('Error setting revenue data:', error);
            setError(error);
          }
          
          setSalesAIData(newAggregates.salesAI || null);

          setLoading(false);
        }, (err) => {
          console.error('Error in Firestore snapshot listener:', err);
          setError(err);
          setLoading(false);
        });

        // Add new listeners for deal details
        const dealsRef = collection(db, 'users', user.uid, 'deals');
        
        const criticalQuery = query(dealsRef, 
          where('customMetrics.isOpen', '==', true),
          where('customMetrics.criticalBad', '==', true),
          limit(5)
        );

        const closingSoonQuery = query(dealsRef, 
          where('customMetrics.isOpen', '==', true),
          where('customMetrics.closingSoon', '==', true),
          limit(5)
        );

        const highImpactQuery = query(dealsRef, 
          where('customMetrics.isOpen', '==', true),
          where('customMetrics.criticalGood', '==', true),
          limit(5)
        );

        const unsubscribeHighImpact = onSnapshot(highImpactQuery, (snapshot) => {
          console.log('High Impact Query snapshot:', snapshot.size, 'documents');
          snapshot.forEach(doc => {
            console.log('High Impact Deal:', doc.id, doc.data());
          });
          const highImpactDeals = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          console.log('High Impact Deals fetched:', highImpactDeals);
          setHighImpactDeals(highImpactDeals);
        });

        const pastDueQuery = query(dealsRef, 
          where('customMetrics.isOpen', '==', true),
          where('customMetrics.pastDue', '>', 0),
          limit(5)
        );
        const missingDataQuery = query(dealsRef,
          where('customMetrics.isOpen', '==', true),
          where('customMetrics.missingAmount', '>', 0),
          limit(5)
        );
        const dealsCreatedQuery = query(dealsRef, where('customMetrics.createdThisQuarter', '==', true), limit(5));
        const dealsToCloseQuery = query(dealsRef, where('customMetrics.closingThisQuarter', '==', true), limit(5));

        const unsubscribeCritical = onSnapshot(criticalQuery, (snapshot) => {
          setCriticalDeals(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        });

        const unsubscribeClosingSoon = onSnapshot(closingSoonQuery, (snapshot) => {
          setClosingSoonDeals(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        });

        const unsubscribePastDue = onSnapshot(pastDueQuery, (snapshot) => {
          setPastDueDeals(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        });

        const unsubscribeMissingData = onSnapshot(missingDataQuery, (snapshot) => {
          setMissingDataDeals(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        });

        const unsubscribeDealsCreated = onSnapshot(dealsCreatedQuery, (snapshot) => {
          setDealsCreated(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        });

        const unsubscribeDealsToClose = onSnapshot(dealsToCloseQuery, (snapshot) => {
          setDealsToClose(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        });

        // Add listener for user document
        const userDocRef = doc(db, 'users', user.uid);
        const unsubscribeUserDoc = onSnapshot(userDocRef, (docSnapshot) => {
          if (docSnapshot.exists()) {
            const userData = docSnapshot.data();
            setUserHubSpotInfo({
              portalId: userData.hubspotPortalId,
              accountId: userData.hubspotAccountId,
              accountType: userData.hubspotAccountType,
              domain: userData.hubspotDomain,
              currency: userData.hubspotCurrency,
              timeZone: userData.hubspotTimeZone,
              userEmail: userData.hubspotUserEmail,
              userId: userData.hubspotUserId,
            });
          }
        });

        // Add listener for owners
        const ownersRef = collection(db, 'users', user.uid, 'owners');
        const unsubscribeOwners = onSnapshot(ownersRef, (snapshot) => {
          const newOwners = {};
          snapshot.docs.forEach(doc => {
            newOwners[doc.id] = doc.data();
          });
          setOwners(newOwners);
        });

        // Add listener for pipeline stages
        const pipelineStagesRef = doc(db, 'users', user.uid, 'pipelineStages', 'stages');
        const unsubscribePipelineStages = onSnapshot(pipelineStagesRef, (doc) => {
          const stagesData = doc.data();
          console.log('Raw stages data:', stagesData); // Debugging log

          if (stagesData && typeof stagesData === 'object') {
            const stagesArray = Object.entries(stagesData).map(([id, stageData]) => ({
              id,
              ...stageData
            }));

            // Sort stages by displayOrder
            stagesArray.sort((a, b) => a.displayOrder - b.displayOrder);

            setPipelineStages(stagesArray);
            
            // Create stage mapping
            const mapping = {};
            stagesArray.forEach(stage => {
              if (stage && stage.id && stage.label) {
                mapping[stage.id] = stage.label;
              }
            });
            setStageMapping(mapping);
          } else {
            console.error('Stages data is not an object:', stagesData);
            setPipelineStages([]);
            setStageMapping({});
          }
        });

        // Add a new listener for all relevant deals
        const allDealsQuery = query(dealsRef, 
          where('isOpen', 'in', [true, false]),
          where('dealstage', '!=', 'closedlost')
        );

        const unsubscribeAllDeals = onSnapshot(allDealsQuery, (snapshot) => {
          const deals = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          console.log('Fetched all deals:', deals); // New log
          setAllDeals(deals);
          console.log('All deals set in context:', deals);
          const filteredOpenDeals = deals.filter(deal => deal.isOpen === true);
          setOpenDeals(filteredOpenDeals);
          console.log('Open deals set in context:', filteredOpenDeals); // New log
        });

        Promise.all([
          new Promise(resolve => onSnapshot(q, snapshot => {
            resolve();
          })),
          new Promise(resolve => onSnapshot(aggregatesRef, snapshot => {
            resolve();
          }))
        ]).then(() => {
          setIsLoading(false);
        });

        return () => {
          unsubscribeSnapshot();
          unsubscribeAggregates();
          unsubscribeCritical();
          unsubscribeClosingSoon();
          unsubscribeHighImpact();
          unsubscribePastDue();
          unsubscribeMissingData();
          unsubscribeDealsCreated();
          unsubscribeDealsToClose();
          unsubscribeUserDoc();
          unsubscribeOwners();
          unsubscribePipelineStages();
          unsubscribeAllDeals();
        };
      } else {
        console.log('No user, resetting data');
        setSyncProgress([]);
        setOverviewData(null);
        setFinalTotals(null);
        setPipelineData({
          totalValue: 0,
          avgDealSize: 0,
          dealsCreated: 0,
          dealsToClose: 0,
          stages: []
        });
        setHealthcheckData(null);
        setInsightsData(null);
        setRevenueData(null);
        setSalesAIData(null);
        setLoading(false);
        setIsLoading(false);
        setUserHubSpotInfo(null);
        setOwners({});
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    console.log('UsersDataProvider: syncStatus changed', syncStatus);
  }, [syncStatus]);

  useEffect(() => {
    console.log('UsersDataProvider: revenueData changed', revenueData);
  }, [revenueData]);

  // Function to get stage name from stage ID
  const getStageName = (stageId) => {
    return stageMapping[stageId] || stageId;
  };

  // Function to map deals with stage names
  const mapDealsWithStageNames = (deals) => {
    return deals.map(deal => ({
      ...deal,
      stageName: getStageName(deal.dealstage)
    }));
  };

  const value = {
    syncProgress,
    overviewData,
    finalTotals,
    pipelineData,
    healthcheckData,
    insightsData,
    revenueData,
    salesAIData,
    loading,
    error,
    syncStatus,
    isLoading,
    criticalDeals,
    closingSoonDeals,
    highImpactDeals,
    userHubSpotInfo,
    owners,
    pastDueDeals,
    missingDataDeals,
    dealsCreated,
    dealsToClose,
    pipelineStages,
    allDeals,
    openDeals,
    stageMapping,
    getStageName,
    mapDealsWithStageNames,
  };

  return (
    <UsersDataContext.Provider value={value}>
      {children}
    </UsersDataContext.Provider>
  );
};