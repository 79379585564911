import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { useRevenue } from '../../../../contexts/RevenueContext';
import { Box, Typography } from '@mui/material';
import { useChartStyles } from '../../../hooks/useChartStyles';
import { formatCurrency } from '../../../utils/formatters';

const RepPerformanceChart = () => {
  const { repPerformance, totalRevenue } = useRevenue();
  const { 
    getCommonChartProperties, 
    getLegendConfig, 
    getCustomTooltip, 
    isMobile, 
    getStackedBarColors, 
    getCurrentAndPreviousYear 
  } = useChartStyles();

  const { currentYear, previousYear } = getCurrentAndPreviousYear();

  if (!repPerformance || repPerformance.length === 0) {
    return <Typography>No data available for Rep Performance Comparison</Typography>;
  }

  const chartData = repPerformance.map(rep => ({
    id: rep.hubspot_owner_id,
    name: rep.name,
    [currentYear]: rep.currentYTDRevenue,
    [previousYear]: rep.previousYearRevenue,
    dealsClosedCount: rep.dealsClosedCount
  }));

  const chartProps = {
    ...getCommonChartProperties('bar'),
    data: chartData,
    keys: [previousYear.toString(), currentYear.toString()],
    indexBy: 'id',
    colors: getStackedBarColors(),
    tooltip: ({ id, value, color, indexValue }) => {
      const rep = chartData.find(d => d.id === indexValue);
      return getCustomTooltip({
        title: rep.name,
        items: [
          { label: id, value, color, formatType: 'currency' },
          { label: 'Total Revenue', value: rep[currentYear] + rep[previousYear], formatType: 'currency' },
          { label: 'Deals Closed', value: rep.dealsClosedCount, formatType: 'number' }
        ]
      });
    },
    legends: getLegendConfig(),
    axisLeft: {
      ...getCommonChartProperties('bar').axisLeft,
      format: value => `$${value / 1000}k`
    },
    axisBottom: {
      ...getCommonChartProperties('bar').axisBottom,
      format: value => chartData.find(d => d.id === value)?.name || 'Unknown Rep',
      legend: undefined, // Remove the x-axis label
      legendPosition: 'middle',
      legendOffset: 32
    }
  };

  return (
    <Box sx={{ p: 2, mb: 6, height: isMobile ? 400 : 500, width: '100%' }}>
      <Typography variant="h6" gutterBottom>
        Total Revenue (YTD): {formatCurrency(totalRevenue)}
      </Typography>
      <ResponsiveBar {...chartProps} />
    </Box>
  );
};

export default RepPerformanceChart;