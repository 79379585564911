import React, { useState, useMemo } from 'react';
import { useRevenue } from '../../../../contexts/RevenueContext';
import { useChartStyles } from '../../../hooks/useChartStyles';
import { Box, Typography, Paper, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';

const PipelineHealthChart = () => {
  const { pipelineHealth, formatCurrency, formatNumber } = useRevenue();
  const { getCommonChartProperties, getChartColor } = useChartStyles();
  const [selectedRep, setSelectedRep] = useState('overall');

  const chartData = useMemo(() => {
    if (!pipelineHealth) return [];
    return selectedRep === 'overall'
      ? pipelineHealth.overall
      : pipelineHealth.reps.find(rep => rep.id === selectedRep)?.pipelineHealth || [];
  }, [pipelineHealth, selectedRep]);

  if (!pipelineHealth || chartData.length === 0) {
    return <Typography>No data available for Pipeline Health</Typography>;
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Paper elevation={3} sx={{ p: 2, height: 500 }}>
        <Typography variant="h6" gutterBottom>Pipeline Health</Typography>
        <FormControl sx={{ mb: 2, minWidth: 120 }}>
          <InputLabel id="rep-select-label">Select Rep</InputLabel>
          <Select
            labelId="rep-select-label"
            value={selectedRep}
            onChange={(e) => setSelectedRep(e.target.value)}
            label="Select Rep"
          >
            <MenuItem value="overall">Overall</MenuItem>
            {pipelineHealth.reps.map(rep => (
              <MenuItem key={rep.id} value={rep.id}>{rep.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <ResponsiveBar
          {...getCommonChartProperties('bar')}
          data={chartData}
          keys={['count', 'value']}
          indexBy="stage"
          margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
          padding={0.3}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colors={({ id }) => getChartColor(id === 'count' ? 0 : 1)}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -45,
            legend: 'Pipeline Stage',
            legendPosition: 'middle',
            legendOffset: 40
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Count',
            legendPosition: 'middle',
            legendOffset: -40
          }}
          axisRight={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Value',
            legendPosition: 'middle',
            legendOffset: 40
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1
                  }
                }
              ]
            }
          ]}
          tooltip={({ id, value, color }) => (
            <strong style={{ color }}>
              {id === 'count' ? `Count: ${formatNumber(value)}` : `Value: ${formatCurrency(value)}`}
            </strong>
          )}
        />
      </Paper>
    </Box>
  );
};

export default PipelineHealthChart;