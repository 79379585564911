import React from 'react';
import { Box, Container, Typography, Accordion, AccordionSummary, AccordionDetails, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faqs = [
  {
    question: 'How long does it take to set up Truepipe.ai?',
    answer: 'Setting up Truepipe.ai is quick and easy. Most users are up and running within 30 seconds of connecting their HubSpot account.',
  },
  {
    question: 'Is my data secure with Truepipe.ai?',
    answer: 'Absolutely. We use enterprise-grade encryption and comply with all major data protection regulations to ensure your data is always secure.',
  },
  {
    question: 'Can Truepipe.ai work with my custom HubSpot setup?',
    answer: 'Yes! Truepipe.ai is designed to work seamlessly with custom deal stages, fields, and sales rep structures in HubSpot.',
  },
  {
    question: 'How accurate are the AI-powered predictions?',
    answer: 'Our AI models are highly accurate and continuously improving. Most customers report forecast accuracy within 5-10% of actual results.',
  },
  {
    question: 'Do I need technical expertise to use Truepipe.ai?',
    answer: 'Not at all. Truepipe.ai is designed for ease of use, with intuitive dashboards and insights that require no technical expertise.',
  },
];

const FAQ = () => {
  const theme = useTheme();

  return (
    <Box sx={{ py: { xs: 8, md: 12 }, backgroundColor: theme.palette.background.light }}>
      <Container maxWidth="lg">
      <Typography variant="sectionTitle">
          Frequently Asked Questions
        </Typography>
        <Box sx={{ maxWidth: '800px', mx: 'auto' }}>
          {faqs.map((faq, index) => (
            <Accordion key={index}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">{faq.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{faq.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default FAQ;