import React from 'react';
import { useRevenue } from '../../../../contexts/RevenueContext';
import { ResponsiveRadialBar } from '@nivo/radial-bar';
import { Typography } from '@mui/material';

const WinRateChart = ({ data }) => {
  const { formatNumber } = useRevenue();

  if (!data) {
    return <Typography>No win rate data available.</Typography>;
  }

  const chartData = [
    {
      id: 'Win Rate',
      data: [
        { x: 'Closed Lost', y: data.closedLostDeals },
        { x: 'Closed Won', y: data.closedWonDeals },
        { x: 'Open Deals', y: data.openDeals },
        { x: 'Total Deals', y: data.totalDeals },
      ],
    },
  ];

  return (
    <div style={{ height: 400 }}>
      <ResponsiveRadialBar
        data={chartData}
        valueFormat={formatNumber}
        padding={0.4}
        cornerRadius={2}
        margin={{ top: 40, right: 120, bottom: 40, left: 40 }}
        radialAxisStart={{ tickSize: 5, tickPadding: 5, tickRotation: 0 }}
        circularAxisOuter={{ tickSize: 5, tickPadding: 12, tickRotation: 0 }}
        legends={[
          {
            anchor: 'right',
            direction: 'column',
            justify: false,
            translateX: 80,
            translateY: 0,
            itemsSpacing: 6,
            itemDirection: 'left-to-right',
            itemWidth: 100,
            itemHeight: 18,
            itemTextColor: '#999',
            symbolSize: 18,
            symbolShape: 'square',
            effects: [
              {
                on: 'hover',
                style: {
                  itemTextColor: '#000',
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
};

export default WinRateChart;