import { parseISO, format, startOfYear, endOfYear, eachMonthOfInterval, differenceInDays } from 'date-fns';
import memoize from 'lodash/memoize';

const isClosedWon = (deal) => {
  return deal.customStage === 'closedwon';
};

const isClosedLost = (deal) => {
  return deal.customStage === 'closedlost';
};

export const processCurrentYearWinRate = (deals) => {
  const currentYear = new Date().getFullYear();
  const currentYearDeals = deals.filter(deal => new Date(deal.closedate).getFullYear() === currentYear);

  const closedLostDeals = currentYearDeals.filter(isClosedLost).length;
  const closedWonDeals = currentYearDeals.filter(isClosedWon).length;
  const openDeals = currentYearDeals.filter(deal => !isClosedWon(deal) && !isClosedLost(deal)).length;
  const totalDeals = currentYearDeals.length;

  return {
    closedLostDeals,
    closedWonDeals,
    openDeals,
    totalDeals
  };
};

export const processDealCountData = (deals) => {
  const currentYear = new Date().getFullYear();
  const previousYear = currentYear - 1;

  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const counts = monthNames.map(month => ({
    month,
    [previousYear]: 0,
    [currentYear]: 0
  }));

  deals.forEach(deal => {
    const dealDate = new Date(deal.createdate);
    const dealYear = dealDate.getFullYear();
    const dealMonth = monthNames[dealDate.getMonth()];

    if (dealYear === currentYear || dealYear === previousYear) {
      const monthData = counts.find(item => item.month === dealMonth);
      if (monthData) {
        monthData[dealYear]++;
      }
    }
  });

  return counts;
};

export const processAvgDealSize = (deals) => {
  console.log('processAvgDealSize - Input deals:', deals);
  const currentYear = new Date().getFullYear();
  const previousYear = currentYear - 1;

  const monthlyData = {
    [currentYear]: {},
    [previousYear]: {}
  };

  // Initialize all months with zero values
  eachMonthOfInterval({
    start: startOfYear(new Date(previousYear, 0, 1)),
    end: endOfYear(new Date(currentYear, 0, 1))
  }).forEach(date => {
    const year = date.getFullYear();
    const month = format(date, 'MMM');
    if (year === currentYear || year === previousYear) {
      monthlyData[year][month] = { totalAmount: 0, count: 0 };
    }
  });

  deals.forEach(deal => {
    if (!isClosedWon(deal)) return;

    const closeDate = parseISO(deal.closedate);
    const year = closeDate.getFullYear();
    if (year !== currentYear && year !== previousYear) return;

    const month = format(closeDate, 'MMM');
    const amount = parseFloat(deal.amount) || 0;

    monthlyData[year][month].totalAmount += amount;
    monthlyData[year][month].count++;
  });

  console.log('processAvgDealSize - Monthly data:', monthlyData);

  const result = Object.entries(monthlyData).flatMap(([year, months]) =>
    Object.entries(months).map(([month, data]) => ({
      year: parseInt(year),
      month,
      avgDealSize: data.count > 0 ? data.totalAmount / data.count : 0,
    }))
  );

  console.log('processAvgDealSize - Result:', result);
  return result;
};

const calculateTotalRevenue = (closedWonDeals) => {
  return closedWonDeals.reduce((total, deal) => {
    const amount = parseFloat(deal.amount) || 0;
    return total + amount;
  }, 0);
};

const calculateAvgClosedRevenue = (closedWonDeals) => {
  if (closedWonDeals.length === 0) return 0;    
  return calculateTotalRevenue(closedWonDeals) / closedWonDeals.length;
};

const calculateAvgSalesCycle = (closedWonDeals) => {
  if (closedWonDeals.length === 0) return 0;
  const totalDays = closedWonDeals.reduce((total, deal) => {
    const createDate = parseISO(deal.createdate);
    const closeDate = parseISO(deal.closedate);
    return total + differenceInDays(closeDate, createDate);
  }, 0);
  return totalDays / closedWonDeals.length;
};

// Memoize expensive calculations
const memoizedCalculateTotalRevenue = memoize(calculateTotalRevenue);
const memoizedCalculateAvgClosedRevenue = memoize(calculateAvgClosedRevenue);
const memoizedCalculateAvgSalesCycle = memoize(calculateAvgSalesCycle);

export const processRevenueData = (deals = [], owners = []) => {
  const closedWonDeals = deals.filter(isClosedWon);
  const totalRevenue = memoizedCalculateTotalRevenue(closedWonDeals);
  const yoyRevenueComparison = processYoYRevenueComparison(closedWonDeals);
  const mrr = calculateMRR(closedWonDeals);
  const arr = mrr * 12;
  const avgClosedRevenue = memoizedCalculateAvgClosedRevenue(closedWonDeals);
  const avgSalesCycle = memoizedCalculateAvgSalesCycle(closedWonDeals);

  return {
    totalRevenue,
    mrr,
    arr,
    avgClosedRevenue,
    avgSalesCycle,
    yoyRevenueComparison,
    forecastAccuracy: processForecastAccuracy(deals),
    forecastedRevenueChanges: processForecastedRevenueChanges(deals),
    salesTargetProgress: processSalesTargetProgress(deals),
    repPerformance: processRepPerformance(deals, owners),
    dealQualityIndex: processDealQualityIndex(deals),
    revenueProjection: processRevenueProjection(deals),
    repFunnelData: processRepFunnelData(deals, owners),
  };
};

const calculateMRR = (closedWonDeals) => {
  const currentDate = new Date();
  const currentMonthDeals = closedWonDeals.filter(deal => {
    const closeDate = parseISO(deal.closedate);
    return closeDate.getMonth() === currentDate.getMonth() && closeDate.getFullYear() === currentDate.getFullYear();
  });
  return calculateTotalRevenue(currentMonthDeals);
};

export const processYoYRevenueComparison = (closedWonDeals) => {
  const currentYear = new Date().getFullYear();
  const previousYear = currentYear - 1;

  const monthlyData = { [currentYear]: {}, [previousYear]: {} };

  eachMonthOfInterval({
    start: startOfYear(new Date(previousYear, 0, 1)),
    end: endOfYear(new Date(currentYear, 0, 1))
  }).forEach(month => {
    const year = month.getFullYear();
    const monthKey = format(month, 'MMM');
    if (year === currentYear || year === previousYear) {
      monthlyData[year][monthKey] = { total: 0, deals: [] };
    }
  });

  closedWonDeals.forEach(deal => {
    const closeDate = parseISO(deal.closedate);
    const year = closeDate.getFullYear();
    const month = format(closeDate, 'MMM');
    const amount = parseFloat(deal.amount) || 0;

    if (year === currentYear || year === previousYear) {
      if (!monthlyData[year][month]) monthlyData[year][month] = { total: 0, deals: [] };
      monthlyData[year][month].total += amount;
      monthlyData[year][month].deals.push(deal);
    }
  });

  return [
    {
      id: `${currentYear} Revenue`,
      data: Object.entries(monthlyData[currentYear]).map(([month, { total }]) => ({ x: month, y: total })),
    },
    {
      id: `${previousYear} Revenue`,
      data: Object.entries(monthlyData[previousYear]).map(([month, { total }]) => ({ x: month, y: total })),
    }
  ];
};

export const processForecastAccuracy = (deals) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  
  const monthlyForecast = {};
  const monthlyActual = {};

  deals.forEach(deal => {
    const closeDate = new Date(deal.closedate);
    const dealMonth = closeDate.getMonth();
    const dealYear = closeDate.getFullYear();

    if (dealYear === currentYear && dealMonth <= currentMonth) {
      const monthKey = closeDate.toLocaleString('default', { month: 'short' });
      const amount = parseFloat(deal.amount) || 0;
      const probability = parseFloat(deal.hs_deal_stage_probability) || 0;

      // For actual revenue, only count closed-won deals
      if (probability === 1) {
        monthlyActual[monthKey] = (monthlyActual[monthKey] || 0) + amount;
      }

      // For forecast, use all deals, weighted by probability
      // This acts as a proxy for what the forecast might have been
      monthlyForecast[monthKey] = (monthlyForecast[monthKey] || 0) + amount * probability;
    }
  });

  // Shift forecast one month earlier to compare with next month's actual
  const shiftedForecast = Object.entries(monthlyForecast).reduce((acc, [month, value], index, arr) => {
    if (index < arr.length - 1) {
      acc[arr[index + 1][0]] = value;
    }
    return acc;
  }, {});

  return Object.keys(monthlyActual).reduce((acc, month) => {
    acc[month] = {
      forecast: shiftedForecast[month] || 0,
      actual: monthlyActual[month] || 0,
    };
    return acc;
  }, {});
};

const processForecastedRevenueChanges = (deals) => {
  // Implementation here
};

const processSalesTargetProgress = (deals) => {
  // Implementation here
};

export const processRepNames = (owners) => {
  return owners.reduce((acc, owner) => {
    acc[owner.id] = `${owner.firstName} ${owner.lastName}`;
    return acc;
  }, {});
};

export const processRepPerformance = (repPerformanceData, owners) => {
  return Object.entries(repPerformanceData)
    .filter(([key]) => key !== 'lastUpdated')
    .map(([hubspot_owner_id, data]) => {
      const owner = owners.find(o => o.id === hubspot_owner_id);
      return {
        hubspot_owner_id,
        name: owner ? `${owner.firstName} ${owner.lastName}` : `Unknown (${hubspot_owner_id})`,
        currentYTDRevenue: data.currentYTDRevenue,
        previousYearRevenue: data.previousYearRevenue,
        dealsClosedCount: data.dealsClosedCount,
        totalRevenue: data.totalRevenue,
        avgDealSize: data.totalRevenue / data.dealsClosedCount
      };
    });
};

export const processSalesActivity = (deals, owners) => {
  return owners.map(owner => {
    const ownerDeals = deals.filter(deal => deal.hubspot_owner_id === owner.id);
    return {
      id: owner.id,
      name: `${owner.firstName} ${owner.lastName}`,
      totalContacts: ownerDeals.reduce((sum, deal) => sum + (parseInt(deal.num_contacted_notes) || 0), 0),
      avgContactsPerDeal: ownerDeals.length > 0 ? 
        ownerDeals.reduce((sum, deal) => sum + (parseInt(deal.num_contacted_notes) || 0), 0) / ownerDeals.length : 0,
    };
  });
};

export const processSalesVelocity = (deals, owners) => {
  return owners.map(owner => {
    const ownerDeals = deals.filter(deal => deal.hubspot_owner_id === owner.id && deal.closedate);
    const avgDealTime = ownerDeals.length > 0 ?
      ownerDeals.reduce((sum, deal) => sum + differenceInDays(parseISO(deal.closedate), parseISO(deal.createdate)), 0) / ownerDeals.length : 0;
    return {
      id: owner.id,
      name: `${owner.firstName} ${owner.lastName}`,
      avgDealTime,
      dealsCount: ownerDeals.length,
    };
  });
};

export const processPipelineHealth = (deals, pipelineStages, owners) => {
  const stageMap = new Map(pipelineStages.map(stage => [stage.id, stage.label]));

  const processDeals = (dealsToProcess) => {
    return Object.values(stageMap).map(stageLabel => ({
      stage: stageLabel,
      count: dealsToProcess.filter(deal => stageMap.get(deal.dealstage) === stageLabel).length,
      value: dealsToProcess
        .filter(deal => stageMap.get(deal.dealstage) === stageLabel)
        .reduce((sum, deal) => sum + (parseFloat(deal.amount) || 0), 0),
    }));
  };

  const overall = processDeals(deals);

  const reps = owners.map(owner => {
    const ownerDeals = deals.filter(deal => deal.hubspot_owner_id === owner.id);
    return {
      id: owner.id,
      name: `${owner.firstName} ${owner.lastName}`,
      pipelineHealth: processDeals(ownerDeals),
    };
  });

  return { overall, reps };
};

export const processRepActivityTimeline = (deals, owners) => {
  return owners.map(owner => {
    const ownerDeals = deals.filter(deal => deal.hubspot_owner_id === owner.id);
    const activities = ownerDeals.map(deal => ([
      { type: 'lastContacted', date: deal.notes_last_contacted },
      { type: 'lastUpdated', date: deal.notes_last_updated },
      { type: 'nextActivity', date: deal.notes_next_activity_date },
    ])).flat().filter(activity => activity.date).sort((a, b) => new Date(b.date) - new Date(a.date));
    return {
      id: owner.id,
      name: `${owner.firstName} ${owner.lastName}`,
      activities: activities.slice(0, 10), // Get the 10 most recent activities
    };
  });
};

const processDealQualityIndex = (deals) => {
  // Implementation here
};

const processRevenueProjection = (deals) => {
  // Implementation here
};



export const processRepFunnelData = (deals = [], owners = []) => {
  if (owners.length === 0) {
    console.warn('Owners array is empty. Cannot process rep funnel data.');
    return [];
  }

  const repData = deals.reduce((acc, deal) => {
    const ownerId = deal.hubspot_owner_id;
    const owner = owners.find(o => o.id === ownerId);
    if (owner) {
      const repName = `${owner.firstName} ${owner.lastName}`;
      if (!acc[repName]) {
        acc[repName] = {
          totalValue: 0,
          dealCount: 0,
          deals: []
        };
      }
      const dealAmount = parseFloat(deal.amount) || 0;
      acc[repName].totalValue += dealAmount;
      acc[repName].dealCount += 1;
      acc[repName].deals.push({
        id: deal.id,
        amount: dealAmount,
        stage: deal.dealstage,
        closeDate: deal.closedate
      });
    }
    return acc;
  }, {});

  return Object.entries(repData).map(([repName, data]) => ({
    repName,
    totalValue: data.totalValue,
    dealCount: data.dealCount,
    avgDealSize: data.totalValue / data.dealCount,
    deals: data.deals.sort((a, b) => b.amount - a.amount).slice(0, 5) // Top 5 deals by amount
  }));
};

export const calculateYoYRevenueComparison = (deals, selectedPipelines = []) => {
  const closedWonDeals = deals.filter(isClosedWon);
  const filteredDeals = selectedPipelines.length > 0
    ? closedWonDeals.filter(deal => selectedPipelines.includes(deal.pipeline))
    : closedWonDeals;
  
  return processYoYRevenueComparison(filteredDeals);
};

export const getRepPerformanceData = (repId, deals, owners) => {
  const repPerformance = processRepPerformance(deals, owners);
  return repPerformance.find(rep => rep.id === repId) || null;
};

export const calculateSalesMetrics = (deals, owners) => {
  const closedWonDeals = deals.filter(isClosedWon);
  return {
    totalRevenue: calculateTotalRevenue(closedWonDeals),
    totalDeals: closedWonDeals.length,
    avgDealSize: calculateAvgClosedRevenue(closedWonDeals),
    topPerformer: processRepPerformance(deals, owners).sort((a, b) => b.totalRevenue - a.totalRevenue)[0],
  };
};

export const processSalesActivityCorrelation = (deals, salesActivity) => {
  const activityMap = new Map(salesActivity.map(rep => [rep.id, rep.name]));

  const chartData = deals
    .filter(deal => deal.closedate && deal.createdate && deal.amount && deal.num_contacted_notes)
    .map((deal) => ({
      x: parseInt(deal.num_contacted_notes),
      y: parseFloat(deal.amount),
      size: differenceInDays(parseISO(deal.closedate), parseISO(deal.createdate)),
      repName: activityMap.get(deal.hubspot_owner_id) || 'Unknown',
      dealId: deal.id,
    }));

  const repNames = [...new Set(chartData.map(item => item.repName))];

  return { chartData, repNames };
};