import React, { useState, useEffect, useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { List, ListItem, ListItemText } from '@mui/material';
import { styled, keyframes, alpha } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ColorModeContext, tokens } from '../../theme';
import { useAuth } from '../../contexts/AuthContext';
import Menu from '@mui/material/Menu';
import Popover from '@mui/material/Popover';
import { AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';
import NotificationDropdown from "../profile/NotificationDropdown";
import RightSidebar from "../global/RightSidebar";
import SignupModal from '../common/SignupModal';

// Icons
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import DashboardIcon from "@mui/icons-material/Dashboard";

// Import the logo
import LogoBlack from "../home/assets/tp_new_logo_black.svg";
import LogoWhite from "../home/assets/tp_horizontal_whitetext_logo.svg";
import { ReactComponent as LogoNoText } from "../home/assets/LogoNoText.svg";

const StyledButton = styled(Button)(({ theme }) => ({
  color: 'white',
  textAlign: 'center',
  cursor: 'pointer',
  border: '0 solid #000',
  borderRadius: '10em',
  justifyContent: 'center',
  alignItems: 'center',
  height: '2.5rem',
  padding: '0.75rem 1.5rem',
  fontSize: '0.875rem',
  fontWeight: 700,
  lineHeight: 1,
  backgroundColor: theme.palette.secondary.main,
  transition: 'transform .15s cubic-bezier(.19,1,.22,1), background-color .15s cubic-bezier(.19,1,.22,1)',
  '&:hover': {
    backgroundColor: theme.palette.secondary.light,
    transform: 'scale(1.05)',
  },
}));

const AnimatedLogo = styled('img')(({ theme }) => ({
  height: '40px',
  marginTop: '10px',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'scale(1.1)',
    filter: 'brightness(1.2)',
    cursor: 'pointer',
  },
}));

const LogoWrapper = ({ children }) => {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(true);
    setTimeout(() => setIsClicked(false), 300);
  };

  return (
    <div
      onClick={handleClick}
      style={{
        animation: isClicked ? `${pulse} 0.3s ease-in-out` : 'none',
        display: 'inline-block',
      }}
    >
      {children}
    </div>
  );
};

const MenuLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  paddingTop: '.125rem',
  paddingBottom: '.125rem',
  fontSize: '1rem',
  marginRight: '1rem',
  fontWeight: 700,
  textDecoration: 'none',
  transition: 'color .15s cubic-bezier(1,0,0,1)',
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: 0,
    height: '2px',
    backgroundColor: theme.palette.secondary.main,
    transition: 'width 0.2s cubic-bezier(1,0,0,1)',
  },
  '&:hover': {
    color: theme.palette.secondary.main,
    '&::after': {
      width: '100%',
    },
  },
}));

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;

function AppAppBar({ isHomePage }) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const { currentUser, logOut } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null);
  const [signupModalOpen, setSignupModalOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const getBackgroundColor = () => {
    if (!isScrolled) return 'transparent';
    return theme.palette.mode === 'light'
      ? alpha(theme.palette.background.default, 0.9)
      : alpha(theme.palette.background.default, 0.9);
  };

  const getTextColor = () => {
    if (!isScrolled) {
      return theme.palette.mode === 'light' ? theme.palette.text.primary : theme.palette.text.primary;
    }
    return theme.palette.text.primary;
  };

  const handleNotificationClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleProfileClose = () => {
    setProfileAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await logOut();
      navigate('/');
    } catch (error) {
      console.error("Failed to log out", error);
    }
    handleProfileClose();
  };

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
    setSidebarOpen(true);
    handleNotificationClose();
  };

  const handleSignInClick = () => {
    setSignupModalOpen(true);
  };

  const handleCloseSignupModal = () => {
    setSignupModalOpen(false);
  };

  const notificationOpen = Boolean(anchorEl);
  const profileOpen = Boolean(profileAnchorEl);

  const isAuthenticatedRoute = location.pathname.startsWith('/sales');

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleNavigation = (path) => {
    navigate(path);
    setDrawerOpen(false);
  };

  const mobileMenuItems = isAuthenticatedRoute || currentUser
    ? [
      { label: 'Home', path: '/' },
      { label: 'Blog', path: '/blog' },
      { label: 'Contact', path: '/contact-us' },  
      { label: 'Dashboard', path: '/sales' },
      { label: 'Settings', path: '/profile/settings' },
        { label: 'Sign Out', action: handleLogout },
      ]
    : [
        { label: 'Home', path: '/' },
        { label: 'Blog', path: '/blog' },
        { label: 'Contact', path: '/contact-us' },
        { label: 'Sign In', action: handleSignInClick },
      ];

  const renderMobileMenu = () => (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={handleDrawerToggle}
      PaperProps={{
        sx: { width: '100%', backgroundColor: theme.palette.background.default }
      }}
    >
      <Box sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <LogoNoText width="40" height="40" />
          <IconButton onClick={handleDrawerToggle}>
            <CloseIcon />
          </IconButton>
        </Box>
        <List>
          {mobileMenuItems.map((item, index) => (
            <ListItem 
              key={index} 
              onClick={() => {
                if (item.action) {
                  item.action();
                } else {
                  handleNavigation(item.path);
                }
                handleDrawerToggle();
              }}
              sx={{ 
                py: 2, 
                borderBottom: index < mobileMenuItems.length - 1 ? `1px solid ${theme.palette.divider}` : 'none'
              }}
            >
              <ListItemText 
                primary={item.label} 
                primaryTypographyProps={{ 
                  variant: 'h6', 
                  sx: { fontWeight: 'bold' } 
                }} 
              />
            </ListItem>
          ))}
        </List>
        <Box sx={{ mt: 'auto', mb: 2, display: 'flex', justifyContent: 'center' }}>
          <IconButton onClick={colorMode.toggleColorMode} size="large">
            {theme.palette.mode === "dark" ? <DarkModeOutlinedIcon /> : <LightModeOutlinedIcon />}
          </IconButton>
        </Box>
      </Box>
    </Drawer>
  );

  return (
    <>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          transition: 'all 0.15s cubic-bezier(1, 0, 0, 1)',
          backdropFilter: isScrolled ? 'blur(5px)' : 'none',
          backgroundColor: getBackgroundColor(),
          boxShadow: isScrolled ? theme.shadows[1] : 'none',
          borderRadius: isScrolled ? '0.5rem' : '0',
          zIndex: 5,
          top: isScrolled ? '0.5rem' : '0',
          left: isScrolled ? '2.5%' : '0',
          right: isScrolled ? '2.5%' : '0',
          width: isScrolled ? '95%' : '100%',
        }}
      >
        <Container maxWidth={false} disableGutters={isScrolled}>
          <Toolbar
            sx={{
              justifyContent: 'space-between',
              height: 64,
              transition: 'all 0.15s cubic-bezier(1, 0, 0, 1)',
              px: { xs: 2, sm: 3 },
            }}
          >
            <Link to="/" onClick={() => { navigate('/'); window.scrollTo(0, 0); }} style={{ display: 'inline-block', textDecoration: 'none' }}>
              <LogoWrapper>
                {isMobile ? (
                  <LogoNoText
                    width="40"
                    height="40"
                    style={{
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        transform: 'scale(1.1)',
                        filter: 'brightness(1.2)',
                        cursor: 'pointer',
                      },
                    }}
                  />
                ) : (
                  <AnimatedLogo
                    src={theme.palette.mode === 'dark' ? LogoWhite : LogoBlack}
                    alt="Company Logo"
                    sx={{
                      height: 40,
                    }}
                  />
                )}
              </LogoWrapper>
            </Link>

            {isMobile ? (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                <IconButton
                  color="inherit"
                  onClick={handleDrawerToggle}
                  sx={{ 
                    color: theme.palette.text.primary
                  }}
                >
                  <MenuIcon />
                </IconButton>
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                {!isAuthenticatedRoute && (
                  <>
                    <MenuLink
                      to="/blog"
                      sx={{
                        color: getTextColor(),
                        fontWeight: location.pathname === '/blog' ? 'bold' : 'normal',
                      }}
                    >
                      <Typography variant="body2" sx={{ fontSize: '1rem', fontWeight: '500' }}>
                        Blog
                      </Typography>
                    </MenuLink>
                    <MenuLink
                      to="/contact-us"
                      sx={{
                        color: getTextColor(),
                        fontWeight: location.pathname === '/contact-us' ? 'bold' : 'normal',
                      }}
                    >
                      <Typography variant="body2" sx={{ fontSize: '1rem', fontWeight: '500' }}>
                        Contact
                      </Typography>
                    </MenuLink>
                  </>
                )}

                <IconButton onClick={colorMode.toggleColorMode}>
                  {theme.palette.mode === "dark" ? (
                    <DarkModeOutlinedIcon />
                  ) : (
                    <LightModeOutlinedIcon />
                  )}
                </IconButton>

                {currentUser ? (
                  <>
                    <IconButton onClick={handleNotificationClick}>
                      <NotificationsOutlinedIcon />
                    </IconButton>
                    <IconButton onClick={handleProfileClick}>
                      <PersonOutlinedIcon />
                    </IconButton>
                  </>
                ) : (
                  <Button
                    variant="outlined"
                    onClick={handleSignInClick}
                    sx={{
                      color: theme.palette.text.primary,
                      borderColor: theme.palette.text.primary,
                      '&:hover': {
                        borderColor: theme.palette.text.primary,
                        backgroundColor: 'rgba(255, 255, 255, 0.08)',
                      },
                    }}
                  >
                    Sign in
                  </Button>
                )}
              </Box>
            )}
          </Toolbar>
        </Container>

        {renderMobileMenu()}

        <Popover
          open={notificationOpen}
          anchorEl={anchorEl}
          onClose={handleNotificationClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <NotificationDropdown onCategoryClick={handleCategoryClick} />
        </Popover>

        <Menu
          anchorEl={profileAnchorEl}
          open={profileOpen}
          onClose={handleProfileClose}
          PaperProps={{
            sx: {
              backgroundColor: theme => theme.palette.background.paper,
              borderRadius: '8px',
              border: theme => `1px solid ${theme.palette.divider}`,
              boxShadow: theme => theme.shadows[3],
            }
          }}
          MenuListProps={{
            sx: {
              padding: '8px 0',
            }
          }}
        >
          <MenuItem onClick={() => { navigate('/sales'); handleProfileClose(); }} sx={{ padding: '12px 24px' }}>
            <DashboardIcon sx={{ marginRight: '12px', color: 'text.secondary' }} />
            <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>Dashboard</Typography>
          </MenuItem>
          <MenuItem onClick={() => { navigate('/profile/settings'); handleProfileClose(); }} sx={{ padding: '12px 24px' }}>
            <SettingsOutlinedIcon sx={{ marginRight: '12px', color: 'text.secondary' }} />
            <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>Settings</Typography>
          </MenuItem>
          <MenuItem onClick={handleLogout} sx={{ padding: '12px 24px' }}>
            <LogoutIcon sx={{ marginRight: '12px', color: 'text.secondary' }} />
            <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>Logout</Typography>
          </MenuItem>
        </Menu>

        <RightSidebar
          open={sidebarOpen}
          onClose={() => setSidebarOpen(false)}
          activeCategory={activeCategory}
        />
      </AppBar>

      {/* Add SignupModal */}
      <SignupModal
        open={signupModalOpen}
        onClose={handleCloseSignupModal}
      />
    </>
  );
}

AppAppBar.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
  onOpenSignupModal: PropTypes.func.isRequired,
};

export default AppAppBar;