// src/contexts/KPIContext.jsx v1.1.0
import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { getFirestore, collection, query, onSnapshot, where, limit, doc, orderBy } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useFirebase } from './FirebaseContext';
import { debounce } from 'lodash';

const KPIContext = createContext();

export const useKPI = () => useContext(KPIContext);

export const KPIProvider = ({ children }) => {
  const [kpiData, setKPIData] = useState({
    aggregates: {
      insights: null,
      pipeline: null,
      healthcheck: {
        pastDue: null,
        missingData: null,
        dealQualityScore: null,
        forecastAccuracy: null,
      },
      revenue: null,
    },
    deals: [],
    userHubSpotInfo: null,
    owners: {},
    loading: true,
    error: null,
  });

  const [syncStatus, setSyncStatus] = useState(localStorage.getItem('syncStatus') || 'loading');
  const [syncProgress, setSyncProgress] = useState(JSON.parse(localStorage.getItem('syncProgress') || '{}'));

  const { db } = useFirebase();
  const auth = getAuth();

  const debouncedSetSyncProgress = useMemo(
    () => debounce((newProgress) => setSyncProgress(newProgress), 500),
    []
  );

  const checkDataCompleteness = (data) => {
    const requiredFields = [
      'aggregates',
      'deals',
      'userHubSpotInfo',
      'owners',
      'pipelineStages',
    ];

    const missingFields = requiredFields.filter(field => {
      if (field === 'userHubSpotInfo') {
        return !data[field] || Object.values(data[field]).every(value => value === null);
      }
      return !data[field] || 
        (Array.isArray(data[field]) && data[field].length === 0) ||
        (typeof data[field] === 'object' && Object.keys(data[field]).length === 0);
    });

    if (missingFields.length > 0) {
      console.log('Missing data fields:', missingFields);
    }

    return missingFields.length === 0;
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (user) {
        const aggregatesRef = collection(db, 'users', user.uid, 'aggregates');
        const dealsRef = collection(db, 'users', user.uid, 'deals');
        const ownersRef = collection(db, 'users', user.uid, 'owners');

        let newOwners = {};

        // Listener for aggregates
        const unsubscribeAggregates = onSnapshot(aggregatesRef, (snapshot) => {
          const newAggregates = {};
          snapshot.docs.forEach(doc => {
            newAggregates[doc.id] = doc.data();
          });

          console.log('Received aggregates:', newAggregates);
          console.log('Revenue data:', newAggregates.revenue);

          // Calculate MRR and ARR if they're not present
          if (newAggregates.revenue && (newAggregates.revenue.mrr == null || newAggregates.revenue.arr == null)) {
            const currentYear = new Date().getFullYear();
            const { mrr, arr } = calculateMRRAndARR(newAggregates.revenue.totalClosedWonRevenue, currentYear);
            newAggregates.revenue.mrr = mrr;
            newAggregates.revenue.arr = arr;
          }

          // Update healthcheck with new metrics
          if (newAggregates.healthcheck) {
            newAggregates.healthcheck.dealQualityScore = newAggregates.healthcheck.dealQualityScore || null;
            newAggregates.healthcheck.forecastAccuracy = newAggregates.healthcheck.forecastAccuracy || null;
          }

          setKPIData(prevData => {
            const updatedData = {
              ...prevData,
              aggregates: newAggregates,
              loading: false, // Set loading to false when we receive data
            };
            return updatedData;
          });
        });

        // Listener for all deals
        const unsubscribeDeals = onSnapshot(dealsRef, (snapshot) => {
          const deals = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          console.log('Fetched all deals:', deals); // New log
          setKPIData(prevData => {
            const updatedData = {
              ...prevData,
              deals,
            };
            const isComplete = checkDataCompleteness(updatedData);
            return { ...updatedData, loading: !isComplete };
          });
        });

        // Listener for owners
        const unsubscribeOwners = onSnapshot(ownersRef, (snapshot) => {
          newOwners = {};
          snapshot.docs.forEach(doc => {
            newOwners[doc.id] = doc.data();
          });
          setKPIData(prevData => {
            const updatedData = { ...prevData, owners: newOwners };
            const isComplete = checkDataCompleteness(updatedData);
            return { ...updatedData, loading: !isComplete };
          });
        });

        // Listener for user document
        const userDocRef = doc(db, 'users', user.uid);
        const unsubscribeUserDoc = onSnapshot(userDocRef, (docSnapshot) => {
          if (docSnapshot.exists()) {
            const userData = docSnapshot.data();
            setKPIData(prevData => {
              const updatedData = {
                ...prevData,
                userHubSpotInfo: {
                  portalId: userData.hubspotPortalId || null,
                  accountId: userData.hubspotAccountId || null,
                  accountType: userData.hubspotAccountType || null,
                  domain: userData.hubspotDomain || null,
                  currency: userData.hubspotCurrency || null,
                  timeZone: userData.hubspotTimeZone || null,
                  userEmail: userData.hubspotUserEmail || null,
                  userId: userData.hubspotUserId || null,
                }
              };
              const isComplete = checkDataCompleteness(updatedData);
              return { ...updatedData, loading: !isComplete };
            });
          }
        });

        // Listener for pipeline stages
        const pipelineStagesRef = doc(db, 'users', user.uid, 'pipelineStages', 'stages');
        const unsubscribePipelineStages = onSnapshot(pipelineStagesRef, (doc) => {
          const stagesData = doc.data();
          console.log('Raw stages data:', stagesData); // Debugging log

          if (stagesData && typeof stagesData === 'object') {
            const stagesArray = Object.entries(stagesData).map(([id, stageData]) => ({
              id,
              ...stageData
            }));

            // Sort stages by displayOrder
            stagesArray.sort((a, b) => a.displayOrder - b.displayOrder);

            setKPIData(prevData => {
              const updatedData = {
                ...prevData,
                pipelineStages: stagesArray,
                stageMapping: stagesArray.reduce((acc, stage) => {
                  acc[stage.id] = stage.label;
                  return acc;
                }, {})
              };
              const isComplete = checkDataCompleteness(updatedData);
              return { ...updatedData, loading: !isComplete };
            });
          } else {
            console.error('Stages data is not an object:', stagesData);
            setKPIData(prevData => {
              const updatedData = {
                ...prevData,
                pipelineStages: [],
                stageMapping: {}
              };
              const isComplete = checkDataCompleteness(updatedData);
              return { ...updatedData, loading: !isComplete };
            });
          }
        });

        // Listener for syncProgress
        const syncDocRef = doc(db, 'users', user.uid, 'syncProgress', 'currentSync');

        const unsubscribeSyncProgress = onSnapshot(syncDocRef, (docSnapshot) => {
          if (docSnapshot.exists()) {
            const syncData = docSnapshot.data();
            debouncedSetSyncProgress(syncData);

            const newSyncStatus = syncData.overall?.status === 'completed' ? 'synced' : 
                                  (syncData.overallStatus === 'in_progress' ? 'syncing' : 'needs_update');

            setSyncStatus(newSyncStatus);
            localStorage.setItem('syncStatus', newSyncStatus);
            localStorage.setItem('syncProgress', JSON.stringify(syncData));

            setKPIData(prevData => ({
              ...prevData,
              loading: false,
              syncStatus: newSyncStatus,
              syncProgress: syncData
            }));
          } else {
            debouncedSetSyncProgress({});
            setSyncStatus('needs_update');
            localStorage.setItem('syncStatus', 'needs_update');
            localStorage.removeItem('syncProgress');

            setKPIData(prevData => ({
              ...prevData,
              loading: false,
              syncStatus: 'needs_update',
              syncProgress: {}
            }));
          }
        }, (err) => {
          console.error('Error in Firestore snapshot listener:', err);
          setKPIData(prevData => ({
            ...prevData,
            error: err.message
          }));
        });

        // Cleanup function
        return () => {
          unsubscribeAggregates();
          unsubscribeDeals();
          unsubscribeOwners();
          unsubscribeUserDoc();
          unsubscribePipelineStages();
          unsubscribeSyncProgress();
        };
      } else {
        // Reset data when user is not authenticated
        setSyncStatus('loading');
        setSyncProgress({});
        localStorage.removeItem('syncStatus');
        localStorage.removeItem('syncProgress');
        setKPIData({
          aggregates: {
            insights: null,
            pipeline: null,
            healthcheck: {
              pastDue: null,
              missingData: null,
              dealQualityScore: null,
              forecastAccuracy: null,
            },
            revenue: null,
          },
          deals: [],
          userHubSpotInfo: null,
          owners: {},
          loading: true,
          error: null,
        });
      }
    });

    return () => unsubscribe();
  }, [db, auth]);

  // Function to get stage name from stage ID
  const getStageName = (stageId) => {
    return kpiData.stageMapping[stageId] || stageId;
  };

  // Function to map deals with stage names
  const mapDealsWithStageNames = (deals) => {
    return deals.map(deal => ({
      ...deal,
      stageName: getStageName(deal.dealstage)
    }));
  };

  // Utility function to filter deals based on criteria
  const filterDeals = (criteria) => {
    const deals = kpiData.deals || []; // Ensure deals is an array
    return deals.filter(deal => {
      for (const [key, condition] of Object.entries(criteria)) {
        const value = deal[key];
        if (typeof condition === 'object') {
          for (const [operator, criterion] of Object.entries(condition)) {
            switch (operator) {
              case '$lt':
                if (!(value < criterion)) return false;
                break;
              case '$lte':
                if (!(value <= criterion)) return false;
                break;
              case '$gt':
                if (!(value > criterion)) return false;
                break;
              case '$gte':
                if (!(value >= criterion)) return false;
                break;
              case '$eq':
                if (!(value === criterion)) return false;
                break;
              case '$ne':
                if (!(value !== criterion)) return false;
                break;
              default:
                return false;
            }
          }
        } else {
          if (value !== condition) return false;
        }
      }
      return true;
    });
  };

  return (
    <KPIContext.Provider value={{ ...kpiData, syncStatus, syncProgress, getStageName, mapDealsWithStageNames, filterDeals }}>
      {children}
    </KPIContext.Provider>
  );
};

const calculateMRRAndARR = (totalRevenue, currentYear) => {
  const now = new Date();
  const startOfYear = new Date(currentYear, 0, 1);
  const monthsPassed = (now.getMonth() - startOfYear.getMonth()) + 
    (12 * (now.getFullYear() - startOfYear.getFullYear())) + 1;

  const mrr = totalRevenue / monthsPassed;
  const arr = mrr * 12;

  return { mrr, arr };
};