// src/contexts/hooks/useFetchRevenue.js v1.1.0

import { useState, useEffect } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { useFirebase } from '../../contexts/FirebaseContext';
import { useAuth } from '../../contexts/AuthContext';

const useFetchRevenue = () => {
  const [revenueData, setRevenueData] = useState({
    totalRevenue: 0,
    mrr: 0,
    arr: 0,
    avgClosedRevenue: 0,
    avgSalesCycle: 0,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { db } = useFirebase();
  const { currentUser } = useAuth();

  useEffect(() => {
    if (!currentUser) {
      setRevenueData({
        totalRevenue: 0,
        mrr: 0,
        arr: 0,
        avgClosedRevenue: 0,
        avgSalesCycle: 0,
      });
      setLoading(false);
      return;
    }

    setLoading(true);

    const repPerformanceRef = doc(db, 'users', currentUser.uid, 'aggregates', 'repPerformance');

    const unsubscribe = onSnapshot(repPerformanceRef, 
      (snapshot) => {
        if (snapshot.exists()) {
          const repPerformanceData = snapshot.data();
          const totalRevenue = Object.values(repPerformanceData)
            .filter(rep => typeof rep === 'object' && rep.currentYTDRevenue)
            .reduce((sum, rep) => sum + rep.currentYTDRevenue, 0);

          const totalDeals = Object.values(repPerformanceData)
            .filter(rep => typeof rep === 'object' && rep.dealsClosedCount)
            .reduce((sum, rep) => sum + rep.dealsClosedCount, 0);

          const avgDealSize = totalDeals > 0 ? totalRevenue / totalDeals : 0;

          // Simplified MRR and ARR calculations
          const currentMonth = new Date().getMonth() + 1; // Get current month (1-12)
          const mrr = totalRevenue / currentMonth;
          const arr = mrr * 12;

          // Note: Avg Sales Cycle might need to come from a different data source
          const avgSalesCycle = 128; // This is a placeholder value

          setRevenueData({
            totalRevenue,
            mrr,
            arr,
            avgClosedRevenue: avgDealSize,
            avgSalesCycle,
          });
          setLoading(false);
        }
      },
      (err) => {
        console.error('Error fetching rep performance:', err);
        setError(err);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [db, currentUser]);

  return { revenueData, loading, error };
};

export default useFetchRevenue;