import React from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import { ResponsiveRadialBar } from '@nivo/radial-bar';
import { useHealthcheck } from '../../../contexts/HealthcheckContext';
import CustomTooltip from '../../charts/CustomTooltip';
import { bittersweet } from '../../../theme';
import { formatWholePercentage } from '../../utils/formatters';

const DataCompletenessGauge = () => {   
  const { 
    dataCompletenessGaugeData, 
    loading, 
    getCommonChartProperties, 
    isMobile, 
    getChartColor 
  } = useHealthcheck() || {};

  const chartProperties = getCommonChartProperties ? getCommonChartProperties('radialBar') : {};

  const getColorForPercentage = (percentage) => {
    if (!getChartColor) return bittersweet;
    if (percentage >= 0.95) return getChartColor(0);
    if (percentage >= 0.89) return getChartColor(1);
    if (percentage >= 0.75) return getChartColor(2);
    if (percentage >= 0.50) return getChartColor(3);
    return bittersweet;
  };

  if (loading) {
    return (
      <Box height="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Typography variant="h6">Data Completeness</Typography>
        <CircularProgress />
      </Box>
    );
  }

  if (!dataCompletenessGaugeData) {
    return (
      <Box height="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Typography variant="h6">Data Completeness</Typography>
        <Typography>No data available</Typography>
      </Box>
    );
  }

  const { completeness, data } = dataCompletenessGaugeData;

  return (
    <Box height="100%" position="relative">
      <Typography variant="h6" align="center" gutterBottom>Data Completeness</Typography>
      <ResponsiveRadialBar
        data={data}
        {...chartProperties}
        valueFormat=">-.2%"
        padding={0.4}
        cornerRadius={2}
        margin={isMobile ? { top: 20, right: 20, bottom: 20, left: 20 } : { top: 40, right: 40, bottom: 40, left: 40 }}
        radialAxisStart={ null }
        circularAxisOuter={{ tickSize: 5, tickPadding: 12, tickRotation: 0 }}
        colors={({ data }) => getColorForPercentage(data.y)}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
        enableCircularGrid={false}
        enableRadialGrid={false}
        radialAxisMax={1}
        tooltip={({ data }) => {
          if (!data) return null;
          return (
            <CustomTooltip
              title="Data Completeness"
              items={[{ label: 'Completeness', value: data.y * 100, formatType: 'percentage' }]}
            />
          );
        }}
        motionConfig="gentle"
        transitionMode="startAngle"
      />
      <Box
        position="absolute"
        top="50%"
        left="50%"
        sx={{
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
        }}
      >
        <Typography variant="h4" component="div" fontWeight="bold" sx={{ mt: 10 }}>
          {formatWholePercentage(completeness)}
        </Typography>
      </Box>
    </Box>
  );
};

export default DataCompletenessGauge;