import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { useDashboardStyles } from '../../components/hooks/useDashboardStyles';
import { HealthcheckProvider } from '../../contexts/HealthcheckContext';

// Import all chart components here
import DataCompletenessGauge from '../../components/sales/HealthcheckDashboard/DataCompletenessGauge';
import DealAgeDistribution from '../../components/sales/HealthcheckDashboard/DealAgeDistribution';
import MissingInformationChart from '../../components/sales/HealthcheckDashboard/MissingInformationChart';
import DealAttentionNeedsTreemap from '../../components/sales/HealthcheckDashboard/DealAttentionNeedsTreemap';
import DealStageTransitionTimes from '../../components/sales/HealthcheckDashboard/DealStageTransitionTimes';
import ForecastAccuracyScatterPlot from '../../components/sales/HealthcheckDashboard/ForecastAccuracyScatterPlot';
import DataQualityScoreByRep from '../../components/sales/HealthcheckDashboard/DataQualityScoreByRep';

const HealthcheckDashboard = () => (
  <HealthcheckProvider>
    <HealthcheckDashboardContent />
  </HealthcheckProvider>
);

const HealthcheckDashboardContent = () => {
  const { layout, typography } = useDashboardStyles();

  return (
    <Box sx={layout.pageWrapper}>
      <Typography variant="h2" sx={typography.pageTitle}>
        CRM Healthcheck Dashboard
      </Typography>

      {/* Top Section - Small Components */}
      <Box sx={{
        ...layout.sectionWrapper,
        height: '33vh', // Set the height to 1/3 of the viewport height
        minHeight: '300px', // Ensure a minimum height for smaller screens
      }}>
        <Typography variant="h6" sx={typography.sectionTitle}>Overview</Typography>
        <Grid container spacing={2} sx={{ height: 'calc(100% - 40px)' }}> {/* Subtract the approximate height of the title */}
          <Grid item xs={12} sm={6} md={3} sx={{ height: '100%' }}>
            <Box sx={{ ...layout.chartContainer, height: '100%' }}>
              <DataCompletenessGauge />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3} sx={{ height: '100%' }}>
            <Box sx={{ ...layout.chartContainer, height: '100%' }}>
              <DealAgeDistribution />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3} sx={{ height: '100%' }}>
            <Box sx={{ ...layout.chartContainer, height: '100%' }}>
              <MissingInformationChart />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3} sx={{ height: '100%' }}>
            <Box sx={{ ...layout.chartContainer, height: '100%' }}>
              <DealAttentionNeedsTreemap />
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Middle Section - Medium Components */}
      <Box sx={layout.sectionWrapper}>
        <Typography variant="h6" sx={typography.sectionTitle}>Detailed Metrics</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Box sx={{ ...layout.chartContainer, height: '300px' }}>
              <ForecastAccuracyScatterPlot />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ ...layout.chartContainer, height: '300px' }}>
              <DataQualityScoreByRep />
            </Box>
          </Grid>
          {/* Removed placeholder for Recent Activity Timeline */}
        </Grid>
      </Box>

      {/* Bottom Section - Large Components */}
      {/* Removed placeholders for Pipeline Velocity Chart and Probability vs Days Open Bubble Chart */}
    </Box>
  );
};

export default HealthcheckDashboard;

// TODO: Charts to be implemented later
// 1. Recent Activity Timeline
// 2. Pipeline Velocity Chart
// 3. Probability vs Days Open Bubble Chart
// 4. Deal Stage Transition Times (hs entered, exited, etc.)