// src/components/sales/RevenueDashboard/charts/YoYRevenueBar.jsx v1.0.0

import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { useRevenue } from '../../../../contexts/RevenueContext';
import { Box, Typography } from '@mui/material';

const YoYRevenueBar = ({ data }) => {
    const {
      formatCurrency,
      getCommonChartProperties,
      getYoYRevenueComparisonTooltip,
      getCustomTooltip,
      isMobile,
      getChartColorPair,
      getCurrentAndPreviousYear
    } = useRevenue();

    console.log('YoYRevenueBar data:', data);

  if (!data || data.length === 0) {
    return <Typography>No revenue data available for the selected time period.</Typography>;
  }

  const { currentYear, previousYear } = getCurrentAndPreviousYear();
  const commonProperties = getCommonChartProperties('bar');

  const chartProps = {
    ...commonProperties,
    data: data[0].data.map((item, index) => ({
      month: item.x,
      [currentYear]: item.y,
      [previousYear]: data[1].data[index].y,
    })),
    keys: [currentYear, previousYear],
    indexBy: 'month',
    padding: 0.2,
    groupMode: 'grouped',
    axisBottom: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Month',
      legendOffset: 36,
      legendPosition: 'middle'
    },
    axisLeft: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Revenue',
      legendOffset: -40,
      legendPosition: 'middle',
      format: value => formatCurrency(value, { notation: 'compact' })
    },
    enableLabel: false,
    tooltip: ({ id, value, indexValue, data }) => getCustomTooltip(getYoYRevenueComparisonTooltip({
        points: [
          { serieId: `${currentYear} Revenue`, data: { x: indexValue, y: data[currentYear] } },
          { serieId: `${previousYear} Revenue`, data: { x: indexValue, y: data[previousYear] } }
        ]
      })),
    legends: isMobile ? [] : [
      {
        dataFrom: 'keys',
        anchor: 'bottom-right',
        direction: 'column',
        justify: false,
        translateX: 120,
        translateY: 0,
        itemsSpacing: 2,
        itemWidth: 100,
        itemHeight: 20,
        itemDirection: 'left-to-right',
        itemOpacity: 0.85,
        symbolSize: 20,
        effects: [
          {
            on: 'hover',
            style: {
              itemOpacity: 1
            }
          }
        ]
      }
    ],
    colors: ({ id }) => getChartColorPair(id === currentYear ? 0 : 1).lineColor
  };

  return (
    <Box sx={{ height: 400 }}>
      <ResponsiveBar {...chartProps} />
    </Box>
  );
};

export default YoYRevenueBar;