import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import { 
  Container, 
  Typography, 
  Box, 
  TextField, 
  Button, 
  MenuItem,
  Snackbar,
  useTheme,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import AppAppBar from './AppAppBar';
import Footer from '../global/Footer';

const reasons = [
  'General Inquiry',
  'Sales Question',
  'Technical Support',
  'Partnership Opportunity',
  'Other'
];

const ContactUs = ({ toggleColorMode }) => {
  const [state, handleSubmit] = useForm("mgvwvqvw");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const theme = useTheme();

  React.useEffect(() => {
    if (state.succeeded) {
      setOpenSnackbar(true);
    }
  }, [state.succeeded]);

  return (
    <>
      <AppAppBar 
        onOpenSignupModal={() => {}} 
        toggleColorMode={toggleColorMode}
      />
      <Container 
        maxWidth="md" 
        sx={{ 
          minHeight: 'calc(100vh - 64px)', 
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent: 'center',
          backgroundColor: theme.palette.background.default,
          pt: 8, // Add top padding
          pb: 8, // Add bottom padding
        }}
      >
        <Box sx={{ my: 4, width: '100%', maxWidth: 600, mx: 'auto' }}>
          <Box sx={{ mb: 6, textAlign: 'center' }}>
            <Typography variant="overline" sx={{ color: theme.palette.secondary.main, fontSize: '1.5rem', fontWeight: '500' }}>
              Contact
            </Typography>
          </Box>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              margin="normal"
              id="name"
              name="name"
              label="Name"
              required
              variant="outlined"
            />
            <TextField
              fullWidth
              margin="normal"
              id="email"
              name="email"
              label="Email"
              type="email"
              required
              variant="outlined"
            />
            <ValidationError prefix="Email" field="email" errors={state.errors} />
            <TextField
              fullWidth
              margin="normal"
              id="company"
              name="company"
              label="Company"
              required
              variant="outlined"
            />
            <TextField
              select
              fullWidth
              margin="normal"
              id="reason"
              name="reason"
              label="Reason for Contact"
              required
            >
              {reasons.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              fullWidth
              margin="normal"
              id="message"
              name="message"
              label="Message"
              multiline
              rows={8}
              required
              variant="outlined"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'primary.main',
                  },
                  // Add padding to the input
                  '& textarea': {
                    padding: '.5rem',
                  },
                },
                '& .MuiInputBase-input': {
                  background: 'transparent',
                },
                // Remove this line as it's causing the padding issue
                // '& .MuiInputBase-root': {
                //   padding: 0,
                // },
              }}
            />
            <ValidationError prefix="Message" field="message" errors={state.errors} />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={state.submitting}
              sx={{ mt: 2, width: '100%' }}
            >
              Submit
            </Button>
          </form>
        </Box>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackbar(false)}
        >
          <MuiAlert 
            elevation={6} 
            variant="filled" 
            severity="success"
            onClose={() => setOpenSnackbar(false)}
          >
            Your message has been sent successfully!
          </MuiAlert>
        </Snackbar>
      </Container>
      <Footer onOpenSignupModal={() => {}} />
    </>
  );
};

export default ContactUs;