  import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
  import { Box, Paper, Typography, Chip, IconButton, SwipeableDrawer, useTheme } from '@mui/material';
  import { ExpandMore, ExpandLess, CheckCircle, Warning, Scale, TrendingUp, TrendingDown, ErrorOutline, Info, Sync } from '@mui/icons-material';
  import { useLocation, useNavigate } from 'react-router-dom';
  import { useKPI } from '../../../contexts/KPIContext';
  import { formatCurrency, formatNumber, formatPercentage } from '../../utils/formatters';
  import { useChartStyles } from '../../hooks/useChartStyles';
  import MobileKPITooltip from './MobileKPITooltip';

  const MobileKPIStrip = () => {
    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();
    const { getColor } = useChartStyles();
    const [expandedKPI, setExpandedKPI] = useState(null);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [tooltipContent, setTooltipContent] = useState(null);
    const kpiRefs = useRef({});
    const scrollRef = useRef(null);
    const [activeKPIIndex, setActiveKPIIndex] = useState(0);

    const {
      aggregates,
      deals,
      userHubSpotInfo,
      owners,
      loading,
      syncStatus,
      syncProgress,
      filterDeals,
    } = useKPI();

    const { progress, isComplete } = useMemo(() => {
      if (!syncProgress || Object.keys(syncProgress).length === 0) {
        return { progress: 0, isComplete: false };
      }

      const steps = [
        'fetchPipelineStages',
        'fetchDeals',
        'fetchOwners',
        'fetchAssociations',
        'fetchContacts',
        'fetchCompanies',
        'fetchEngagements',
        'calculateCustomMetrics',
        'structureDashboardData'
      ];

      const totalSteps = steps.length;
      const completedSteps = steps.filter(step => syncProgress[step]?.status === 'completed').length;
      const progress = (completedSteps / totalSteps) * 100;
      const isComplete = syncProgress.overall?.status === 'completed';

      return { progress, isComplete };
    }, [syncProgress]);

    const getStatusLabel = () => {
      if (syncStatus === 'synced') return 'Synced';
      if (syncStatus === 'syncing') return `Syncing... ${Math.round(progress)}%`;
      if (syncStatus === 'needs_update') return 'Needs update';
      return 'Loading...';
    };

    const getStatusIcon = () => {
      if (syncStatus === 'synced') return <CheckCircle />;
      if (syncStatus === 'syncing') return <Sync />;
      if (syncStatus === 'needs_update') return <Warning />;
      return <Sync />;
    };

    const getStatusColor = () => {
      const isDarkMode = theme.palette.mode === 'dark';
      if (syncStatus === 'synced') return isDarkMode ? getColor({ data: { probability: 100 } }) : getColor({ data: { probability: 100 } });
      if (syncStatus === 'syncing') return getColor({ data: { probability: progress } });
      if (syncStatus === 'needs_update') return theme.palette.error.main;
      return theme.palette.grey[500];
    };

    const kpiData = useMemo(() => [
      { label: 'Overview', value: getStatusLabel(), route: '/sales', isOverview: true },
      { 
        label: 'Pipeline', 
        value: aggregates.pipeline?.totalOpenPipelineValue !== undefined 
          ? formatCurrency(aggregates.pipeline?.totalOpenPipelineValue) 
          : '0', 
        route: '/sales/pipeline', 
        loading: !aggregates.pipeline || aggregates.pipeline?.totalOpenPipelineValue === undefined 
      },
      { label: 'Healthcheck', value: aggregates.healthcheck?.totalIssues || '0', route: '/sales/healthcheck', loading: !aggregates.healthcheck },
      { label: 'Insights', value: aggregates.insights?.totalInsights || '0', route: '/sales/insights', loading: !aggregates.insights },
      { 
        label: 'Revenue', 
        value: aggregates.revenue?.ytdRevenue !== undefined 
          ? formatCurrency(aggregates.revenue.ytdRevenue) 
          : '0', 
        route: '/sales/revenue', 
        loading: !aggregates.revenue || aggregates.revenue?.ytdRevenue === undefined 
      },
      { label: 'SalesAI', value: 'coming soon', route: '/sales/ai', loading: false },
    ], [aggregates, getStatusLabel]);

    const microKPIData = useMemo(() => ({
      Pipeline: [
        { 
          label: 'Open Pipeline', 
          value: aggregates?.pipeline?.totalOpenPipelineValue ?? 0, 
          format: 'currency',
          dealProperty: 'isOpen',
          deals: filterDeals({ isOpen: 1 })
        },
        { 
          label: 'Deals Created', 
          value: aggregates?.pipeline?.dealsCreated ?? 0, 
          format: 'number',
          dealProperty: 'createdThisQuarter',
          deals: filterDeals({ createdThisQuarter: 1 })
        },
        { 
          label: 'Closing this Quarter', 
          value: aggregates?.pipeline?.dealsToClose ?? 0, 
          format: 'number',
          dealProperty: 'closingThisQuarter',
          deals: filterDeals({ closingThisQuarter: 1 })
        },
      ],
      Healthcheck: [
        { 
          label: 'Past Due', 
          value: aggregates?.healthcheck?.pastDue ?? 0, 
          threshold: 0, 
          format: 'number',
          dealProperty: 'pastDue',
          deals: filterDeals({ pastDue: 1 })
        },
        { 
          label: 'Missing Data', 
          value: aggregates?.healthcheck?.missingData ?? 0, 
          threshold: 0, 
          format: 'number',
          dealProperty: 'missingAmount',
          deals: filterDeals({ missingAmount: 1 })
        },
        { 
          label: 'Deal Quality', 
          value: aggregates?.healthcheck?.dealQualityScore ?? 0, 
          threshold: 0.7,
          format: 'percentage',
          dealProperty: 'qualityScore',
          deals: filterDeals({ qualityScore: { $lt: 0.7 } })
        },
        { 
          label: 'Forecast Accuracy', 
          value: aggregates?.healthcheck?.forecastAccuracy ?? 0, 
          threshold: 0.8,
          format: 'percentage',
          dealProperty: 'forecastAccuracy',
          deals: filterDeals({ forecastAccuracy: { $lt: 0.8 } })
        },
      ],
      Insights: [
        { 
          label: 'Critical Deals', 
          value: aggregates?.insights?.criticalDeals ?? 0, 
          threshold: 0, 
          format: 'number', 
          dealProperty: 'criticalBad', 
          deals: filterDeals({ criticalBad: 1 })
        },
        { 
          label: 'Closing Soon', 
          value: aggregates?.insights?.closingSoon ?? 0, 
          format: 'number', 
          dealProperty: 'closingSoon', 
          deals: filterDeals({ closingSoon: 1 })
        },
        { 
          label: 'High Impact Deals', 
          value: aggregates?.insights?.highImpactDeals ?? 0, 
          threshold: 0, 
          format: 'number', 
          dealProperty: 'criticalGood',
          deals: filterDeals({ criticalGood: 1 })
        },
      ],
      Revenue: [
        { 
          label: 'MRR', 
          value: calculateMRR(aggregates?.revenue?.ytdRevenue ?? 0), 
          format: 'currency' 
        },
        { 
          label: 'ARR', 
          value: calculateARR(aggregates?.revenue?.ytdRevenue ?? 0), 
          format: 'currency' 
        },
        { 
          label: 'Avg Deal Size', 
          value: aggregates?.revenue?.avgClosedWonDealSize ?? 0, 
          format: 'currency' 
        },
        { 
          label: 'Avg Sales Cycle', 
          value: aggregates?.revenue?.avgSalesCycle ?? 0, 
          format: 'number', 
          suffix: ' days' 
        },
      ],
    }), [aggregates, filterDeals]);

    const handleExpand = (kpiLabel, event) => {
      event.preventDefault();
      event.stopPropagation();
      setExpandedKPI(expandedKPI === kpiLabel ? null : kpiLabel);
    };

    const handleMicroKPIClick = (metric, event) => {
      event.preventDefault();
      event.stopPropagation();
      if (userHubSpotInfo && metric.dealProperty && metric.deals && metric.deals.length > 0) {
        setTooltipContent({
          deals: metric.deals,
          portalId: userHubSpotInfo.portalId,
          title: metric.label
        });
        setTooltipOpen(true);
      }
    };

    const handleTooltipClose = () => {
      setTooltipOpen(false);
      setTooltipContent(null);
    };

    const isKPIActive = (path) => location.pathname === path;

    const scrollToKPI = useCallback((index) => {
      if (scrollRef.current) {
        const kpiElement = kpiRefs.current[kpiData[index].label];
        if (kpiElement) {
          const containerWidth = scrollRef.current.offsetWidth;
          const scrollLeft = kpiElement.offsetLeft - (containerWidth - kpiElement.offsetWidth) / 2;
          scrollRef.current.scrollTo({
            left: scrollLeft,
            behavior: 'smooth'
          });
        }
      }
    }, [kpiData]);

    useEffect(() => {
      const activeIndex = kpiData.findIndex(kpi => kpi.route === location.pathname);
      if (activeIndex !== -1 && activeIndex !== activeKPIIndex) {
        setActiveKPIIndex(activeIndex);
        scrollToKPI(activeIndex);
        setExpandedKPI(null); // Close any open microKPI when scrolling
      }
    }, [location.pathname, kpiData, activeKPIIndex, scrollToKPI]);

    const handleKPIClick = (route, index) => {
      setActiveKPIIndex(index);
      scrollToKPI(index);
      navigate(route);
    };

    const getIcon = (metric) => {
      if (metric.label === 'High Impact Deals') {
        return <Scale color="success" />;
      }
      if (metric.label === 'Critical Good Deals') {
        return <CheckCircle color="success" />;
      }
      if (metric.threshold !== undefined) {
        return metric.value > metric.threshold ? <ErrorOutline color="error" /> : <CheckCircle color="secondary" />;
      }
      if (metric.previousValue !== undefined) {
        return metric.value > metric.previousValue ? <TrendingUp color="success" /> : <TrendingDown color="error" />;
      }
      return <Info sx={{ color: getColor({ data: { probability: 50 } }) }} />;
    };

    const formatValue = (value, format) => {
      switch (format) {
        case 'currency':
          return formatCurrency(value, { maximumFractionDigits: 0 });
        case 'percentage':
          return formatPercentage(value, { maximumFractionDigits: 0 });
        default:
          return formatNumber(value, { maximumFractionDigits: 0 });
      }
    };

    const renderMicroKPIs = (kpiLabel) => {
      if (!microKPIData[kpiLabel] || microKPIData[kpiLabel].length === 0) {
        console.log(`No microKPIData for ${kpiLabel}`);
        return null;
      }

      return microKPIData[kpiLabel].map((metric, idx) => (
        <Box 
          key={idx} 
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 1,
            cursor: 'pointer',
          }}
          onClick={(e) => handleMicroKPIClick(metric, e)}
        >
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              p: 1,
              borderRadius: '50%',
            }}>
              {getIcon(metric)}
            </Box>
            <Typography sx={{ fontSize: '.75rem' }}>
              {metric.label}:
            </Typography>
          </Box>
          <Typography sx={{ fontSize: '.875rem', fontWeight: 'bold' }}>
            {formatValue(metric.value, metric.format)}
            {metric.suffix || ''}
          </Typography>
        </Box>
      ));
    };

    return (
      <>
        <Box
          ref={scrollRef}
          sx={{
            display: 'flex',
            overflowX: 'auto',
            scrollSnapType: 'x mandatory',
            '&::-webkit-scrollbar': { display: 'none' },
            scrollbarWidth: 'none',
            mb: 2,
          }}
        >
          {kpiData.map((kpi, index) => (
            <Paper
              key={index}
              ref={(el) => { kpiRefs.current[kpi.label] = el; }}
              onClick={() => handleKPIClick(kpi.route, index)}
              sx={{
                flex: '0 0 100%',
                scrollSnapAlign: 'center',
                p: 2,
                height: '80px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                color: 'inherit',
                position: 'relative',
                border: isKPIActive(kpi.route) ? `1px solid ${theme.palette.secondary.main}` : 'none',
                backgroundColor: isKPIActive(kpi.route) ? theme.palette.action.selected : theme.palette.background.paper,
                cursor: 'pointer',
              }}
            >
              <Typography variant="h6" component="div">
                {kpi.label}
              </Typography>
              {kpi.isOverview ? (
                <Chip
                  icon={getStatusIcon()}
                  label={kpi.value}
                  size="small"
                  sx={{
                    mt: 1,
                    backgroundColor: getStatusColor(),
                    color: theme.palette.getContrastText(getStatusColor()),
                    '& .MuiChip-icon': {
                      color: theme.palette.getContrastText(getStatusColor()),
                    },
                  }}
                />
              ) : (
                <Typography variant="h4" component="div">
                  {kpi.value}
                </Typography>
              )}
              {microKPIData[kpi.label] && (
                <IconButton
                  size="small"
                  sx={{
                    position: 'absolute',
                    bottom: 6,
                    right: 5,
                    backgroundColor: expandedKPI === kpi.label ? theme.palette.secondary.main : 'transparent',
                    color: expandedKPI === kpi.label ? theme.palette.common.white : theme.palette.secondary.main,
                  }}
                  onClick={(e) => handleExpand(kpi.label, e)}
                >
                  {expandedKPI === kpi.label ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              )}
            </Paper>
          ))}
        </Box>
        <SwipeableDrawer
          anchor="bottom"
          open={expandedKPI !== null}
          onClose={() => setExpandedKPI(null)}
          onOpen={() => {}}
          disableSwipeToOpen={false}
          swipeAreaWidth={56}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <Box sx={{ p: 2, backgroundColor: theme.palette.background.paper }}>
            {expandedKPI && (
              <>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  {expandedKPI} Details
                </Typography>
                {renderMicroKPIs(expandedKPI)}
              </>
            )}
          </Box>
        </SwipeableDrawer>
        <MobileKPITooltip
          open={tooltipOpen}
          onClose={handleTooltipClose}
          deals={tooltipContent?.deals}
          portalId={tooltipContent?.portalId}
          owners={owners}
          title={tooltipContent?.title}
        />
      </>
    );
  };

  const calculateMRR = (ytdRevenue) => {
    const currentDate = new Date();
    const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
    const monthsPassed = currentDate.getMonth() - startOfYear.getMonth() + 
      (12 * (currentDate.getFullYear() - startOfYear.getFullYear())) + 1;
    
    return ytdRevenue / monthsPassed;
  };

  const calculateARR = (ytdRevenue) => {
    return calculateMRR(ytdRevenue) * 12;
  };

  export default MobileKPIStrip;