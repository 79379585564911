import React, { useMemo, useEffect } from 'react';
import { Box, Paper, Typography, Grid, Chip, IconButton, Popper, Fade, Skeleton, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { ExpandMore, ExpandLess, CheckCircle, Warning, Scale, TrendingUp, TrendingDown, ErrorOutline, Info, Sync } from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MobileKPIStrip from './mobileComponents/MobileKPIStrip';
import { useLocation } from 'react-router-dom';
import { formatNumber, formatCurrency, formatPercentage } from '../utils/formatters';
import { useKPI } from '../../contexts/KPIContext';
import { getChartColor } from '../../components/charts/chartColors';
import SkeletonLoader from '../../utils/SkeletonLoader';
import { useChartConfig } from '../../components/hooks/useChartConfig';
import KPITooltip from './KPITooltip';
import { useChartStyles } from '../hooks/useChartStyles';

const KPICardSkeleton = () => (
  <Skeleton
    variant="rectangular"
    width="100%"
    height={120}
    sx={{ borderRadius: 1 }}
  />
);

const DashboardKPIs = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const { getColor } = useChartStyles();
  const [expandedKPI, setExpandedKPI] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const kpiRefs = React.useRef({});
  const tooltipTimeoutRef = React.useRef(null);
  const [popperWidth, setPopperWidth] = React.useState(0);
  const [popperHeight, setPopperHeight] = React.useState(0);
  const {
    aggregates,
    deals,
    userHubSpotInfo,
    owners,
    loading,
    syncStatus,
    syncProgress,
    filterDeals,
  } = useKPI();

  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const [tooltipContent, setTooltipContent] = React.useState(null);
  const [tooltipAnchorEl, setTooltipAnchorEl] = React.useState(null);

  // Add this useEffect for logging KPI context data
  useEffect(() => {
    console.log('KPI Context Data:', {
      aggregates,
      deals,
      userHubSpotInfo,
      owners,
      loading,
      syncStatus,
      syncProgress,
    });
  }, [aggregates, deals, userHubSpotInfo, owners, loading, syncStatus, syncProgress]);

  React.useEffect(() => {
    if (expandedKPI && kpiRefs.current[expandedKPI]) {
      const width = kpiRefs.current[expandedKPI].offsetWidth;
      setPopperWidth(width);
      const height = kpiRefs.current[expandedKPI].offsetHeight;
      setPopperHeight(height);
    }
  }, [expandedKPI]);

  const handleExpand = (kpiLabel, event) => {
    event.preventDefault();
    event.stopPropagation();
    setExpandedKPI(expandedKPI === kpiLabel ? null : kpiLabel);
    setAnchorEl(kpiRefs.current[kpiLabel]);
  };

  const isKPIActive = (path) => location.pathname === path;

  const { progress, isComplete, totalSteps, completedSteps } = useMemo(() => {
    if (!syncProgress || Object.keys(syncProgress).length === 0) {
      return { progress: 0, isComplete: false, totalSteps: 0, completedSteps: 0 };
    }

    const steps = [
      'fetchPipelineStages',
      'fetchDeals',
      'fetchOwners',
      'fetchAssociations',
      'fetchContacts',
      'fetchCompanies',
      'fetchEngagements',
      'calculateCustomMetrics',
      'structureDashboardData'
    ];

    const totalSteps = steps.length;
    const completedSteps = steps.filter(step => syncProgress[step]?.status === 'completed').length;
    const progress = (completedSteps / totalSteps) * 100;
    const isComplete = syncProgress.overall?.status === 'completed';

    return { progress, isComplete, totalSteps, completedSteps };
  }, [syncProgress]);

  const getStatusLabel = () => {
    if (syncStatus === 'synced') return 'Synced';
    if (syncStatus === 'syncing') return `Syncing... ${Math.round(progress)}%`;
    if (syncStatus === 'needs_update') return 'Needs update';
    return 'Loading...';
  };

  const getStatusIcon = () => {
    if (syncStatus === 'synced') return <CheckCircle />;
    if (syncStatus === 'syncing') return <Sync />;
    if (syncStatus === 'needs_update') return <Warning />;
    return <Sync />;
  };

  const getStatusColor = () => {
    const isDarkMode = theme.palette.mode === 'dark';
    if (syncStatus === 'synced') return isDarkMode ? getColor({ data: { probability: 100 } }) : getColor({ data: { probability: 100 } });
    if (syncStatus === 'syncing') return getColor({ data: { probability: progress } });
    if (syncStatus === 'needs_update') return theme.palette.error.main;
    return theme.palette.grey[500];
  };

  const getStatusTextColor = () => {
    return theme.palette.getContrastText(getStatusColor());
  };

  const getHoverStyles = (isActive) => {
    if (isActive) return {};

    return theme.palette.mode === 'dark'
      ? {
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            border: `2px solid ${theme.palette.primary.main}`,
            borderRadius: 'inherit',
            opacity: 0,
            transition: 'opacity 0.3s ease',
          },
          '&:hover::before': {
            opacity: 1,
          },
        }
      : {
          '&:hover': {
            backgroundColor: theme.palette.secondary.light,
          },
        };
  };

  const formatValue = (value, format) => {
    switch (format) {
      case 'currency':
        return formatCurrency(value, { maximumFractionDigits: 0 });
      case 'percentage':
        return formatPercentage(value, { maximumFractionDigits: 0 });
      default:
        return formatNumber(value, { maximumFractionDigits: 0 });
    }
  };

  const getIcon = (metric) => {
    if (metric.label === 'High Impact Deals') {
      return <Scale color="success" />;
    }
    if (metric.label === 'Critical Good Deals') {
      return <CheckCircle color="success" />;
    }
    if (metric.threshold !== undefined) {
      return metric.value > metric.threshold ? <ErrorOutline color="error" /> : <CheckCircle color="secondary" />;
    }
    if (metric.previousValue !== undefined) {
      return metric.value > metric.previousValue ? <TrendingUp color="success" /> : <TrendingDown color="error" />;
    }
    return <Info sx={{ color: getChartColor(2, theme.palette.mode, 'areas') }} />;
  };

  const renderKPIContent = (kpi) => {
    if (kpi.isOverview) {
      return (
        <Chip
          icon={getStatusIcon()}
          label={getStatusLabel()}
          size="small"
          sx={{ 
            mt: 1,
            backgroundColor: getStatusColor(),
            color: getStatusTextColor(),
            '& .MuiChip-icon': {
              color: getStatusTextColor(),
            },
          }}
        />
      );
    } else if (kpi.loading) {
      return <KPICardSkeleton />;
    } else {
      return (
        <Typography variant="h4" component="div">
          {kpi.value}
        </Typography>
      );
    }
  };

  const microKPIData = useMemo(() => ({
    Pipeline: [
      {
        label: 'Open Pipeline',
        value: aggregates?.pipeline?.totalOpenPipelineValue ?? 0,
        format: 'currency',
        dealProperty: 'isOpen',
        deals: filterDeals({ isOpen: 1 }) ?? [],
      },
      {
        label: 'Deals Created',
        value: aggregates?.pipeline?.dealsCreated ?? 0,
        format: 'number',
        dealProperty: 'createdThisQuarter',
        deals: filterDeals({ createdThisQuarter: 1 }) ?? [],
      },
      {
        label: 'Closing this Quarter',
        value: aggregates?.pipeline?.dealsToClose ?? 0,
        format: 'number',
        dealProperty: 'closingThisQuarter',
        deals: filterDeals({ closingThisQuarter: 1 }) ?? [],
      },
    ],
    Healthcheck: [
      {
        label: 'Past Due',
        value: aggregates?.healthcheck?.pastDue ?? 0,
        threshold: 0,
        format: 'number',
        dealProperty: 'pastDue',
        deals: filterDeals({ pastDue: 1 }) ?? [],
      },
      {
        label: 'Missing Data',
        value: aggregates?.healthcheck?.missingData ?? 0,
        threshold: 0,
        format: 'number',
        dealProperty: 'missingAmount',
        deals: filterDeals({ missingAmount: 1 }) ?? [],
      },
      {
        label: 'Deal Quality',
        value: aggregates?.healthcheck?.dealQualityScore ?? 0,
        threshold: 0.7,
        format: 'percentage',
        dealProperty: 'qualityScore',
        deals: filterDeals({ qualityScore: { $lt: 0.7 } }) ?? [],
      },
      {
        label: 'Forecast Accuracy',
        value: aggregates?.healthcheck?.forecastAccuracy ?? 0,
        threshold: 0.8,
        format: 'percentage',
        dealProperty: 'forecastAccuracy',
        deals: filterDeals({ forecastAccuracy: { $lt: 0.8 } }) ?? [],
      },
    ],
    Insights: [
      {
        label: 'Critical Deals',
        value: aggregates?.insights?.criticalDeals ?? 0,
        threshold: 0,
        format: 'number',
        dealProperty: 'criticalBad',
        deals: filterDeals({ criticalBad: 1 }) ?? [],
      },
      {
        label: 'Closing Soon',
        value: aggregates?.insights?.closingSoon ?? 0,
        format: 'number',
        dealProperty: 'closingSoon',
        deals: filterDeals({ closingSoon: 1 }) ?? [],
      },
      {
        label: 'High Impact Deals',
        value: aggregates?.insights?.highImpactDeals ?? 0,
        threshold: 0,
        format: 'number',
        dealProperty: 'criticalGood',
        deals: filterDeals({ criticalGood: 1 }) ?? [],
      },
    ],
    Revenue: [
      {
        label: 'MRR',
        value: calculateMRR(aggregates?.revenue?.ytdRevenue ?? 0),
        format: 'currency',
      },
      {
        label: 'ARR',
        value: calculateARR(aggregates?.revenue?.ytdRevenue ?? 0),
        format: 'currency',
      },
      {
        label: 'Avg Deal Size',
        value: aggregates?.revenue?.avgClosedWonDealSize ?? 0,
        format: 'currency',
      },
      {
        label: 'Avg Sales Cycle',
        value: aggregates?.revenue?.avgSalesCycle ?? 0,
        format: 'number',
        suffix: ' days',
      },
    ],
  }), [aggregates, filterDeals]);

  // Add this useEffect for debugging microKPIData
  useEffect(() => {
    Object.entries(microKPIData).forEach(([category, metrics]) => {
      metrics.forEach(metric => {
        if (metric.deals) {
          console.log(`${category} - ${metric.label} deals:`, metric.deals);
        }
      });
    });
  }, [microKPIData]);

  const handleMicroKPIHover = (metric, event) => {
    if (userHubSpotInfo && metric.dealProperty && metric.deals && metric.deals.length > 0) {
      clearTimeout(tooltipTimeoutRef.current);
      setTooltipContent({
        deals: metric.deals,
        portalId: userHubSpotInfo.portalId,
        title: metric.label
      });
      setTooltipAnchorEl(event.currentTarget.closest('.MuiPaper-root'));  // Change this line
      setTooltipOpen(true);
    }
  };

  const handleTooltipClose = () => {
    tooltipTimeoutRef.current = setTimeout(() => {
      setTooltipOpen(false);
      setTooltipContent(null);
      setTooltipAnchorEl(null);
    }, 1500); // Delay closing to allow moving to tooltip
  };

  const renderMicroKPIs = (kpiLabel) => {
    const metrics = microKPIData[kpiLabel];

    if (!metrics || metrics.length === 0) {
      console.log(`No microKPIData for ${kpiLabel}`);
      return null;
    }

    return metrics.map((metric, idx) => {
      const hasDeals = Array.isArray(metric.deals) && metric.deals.length > 0;

      return (
        <Box key={idx} sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
        }}>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              p: 2,
              borderRadius: '50%',
            }}>
              {getIcon(metric)}
            </Box>
            <Typography 
              sx={{ fontSize: '.875rem', cursor: hasDeals ? 'pointer' : 'default' }}
              onMouseEnter={(e) => hasDeals && handleMicroKPIHover(metric, e)}
              onMouseLeave={handleTooltipClose}
            >
              {metric.label}:
            </Typography>
          </Box>
          <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
            {formatValue(metric.value, metric.format)}
            {metric.suffix || ''}
          </Typography>
        </Box>
      );
    });
  };

  const kpiData = useMemo(() => [
    { label: 'Overview', value: getStatusLabel(), route: '/sales', isOverview: true },
    { 
      label: 'Pipeline', 
      value: aggregates.pipeline?.totalOpenPipelineValue !== undefined 
        ? formatCurrency(aggregates.pipeline?.totalOpenPipelineValue) 
        : '0', 
      route: '/sales/pipeline', 
      loading: !aggregates.pipeline || aggregates.pipeline?.totalOpenPipelineValue === undefined 
    },
    { label: 'Healthcheck', value: aggregates.healthcheck?.totalIssues || '0', route: '/sales/healthcheck', loading: !aggregates.healthcheck },
    { label: 'Insights', value: aggregates.insights?.totalInsights || '0', route: '/sales/insights', loading: !aggregates.insights },
    { 
      label: 'Revenue', 
      value: aggregates.revenue?.ytdRevenue !== undefined 
        ? formatCurrency(aggregates.revenue.ytdRevenue) 
        : '0', 
      route: '/sales/revenue', 
      loading: !aggregates.revenue || aggregates.revenue?.ytdRevenue === undefined 
    },    { label: 'SalesAI', value: 'coming soon', route: '/sales/ai', loading: false },
  ], [aggregates, getStatusLabel]);

  if (isMobile) {
    return (
      <MobileKPIStrip
        kpiData={kpiData}
        microKPIData={microKPIData}
        formatValue={formatValue}
        getIcon={getIcon}
      />
    );
  }

  return (
    <Box sx={{ flexGrow: 1, mb: 2 }}>
      <Grid container spacing={2}>
        {kpiData.map((kpi, index) => {
          console.log(`Rendering KPI:`, kpi);
          return (
            <Grid item xs={6} sm={4} md={2} key={index}>
              <Paper
                ref={(el) => {
                  kpiRefs.current[kpi.label] = el;
                }}
                component={Link}
                to={kpi.route}
                sx={{
                  p: 2,
                  height: '120px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  textDecoration: 'none',
                  color: 'inherit',
                  position: 'relative',
                  border: isKPIActive(kpi.route) ? `1px solid ${theme.palette.secondary.main}` : 'none',
                  backgroundColor: isKPIActive(kpi.route) ? theme.palette.action.selected : theme.palette.background.paper,
                  transition: 'all 0.3s ease',
                  overflow: 'hidden',
                  ...getHoverStyles(isKPIActive(kpi.route)),
                }}
              >
                <Typography variant="h6" component="div">
                  {kpi.label}
                </Typography>
                {renderKPIContent(kpi)}
                {microKPIData[kpi.label] && (
                  <IconButton
                    size="small"
                    aria-label={`Expand ${kpi.label} details`}
                    aria-expanded={expandedKPI === kpi.label}
                    sx={{
                      position: 'absolute',
                      bottom: 5,
                      right: 5,
                      backgroundColor: expandedKPI === kpi.label ? theme.palette.secondary.main : 'transparent',
                      color: expandedKPI === kpi.label ? theme.palette.common.white : theme.palette.secondary.main,
                      '&:hover': {
                        backgroundColor: theme.palette.secondary.main,
                        color: theme.palette.common.white,
                      },
                    }}
                    onClick={(e) => handleExpand(kpi.label, e)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        handleExpand(kpi.label, e);
                      }
                    }}
                  >
                    {expandedKPI === kpi.label ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                )}
              </Paper>
              <Popper
                open={expandedKPI === kpi.label}
                anchorEl={kpiRefs.current[kpi.label]}
                placement="bottom-start"
                modifiers={[
                  {
                    name: 'offset',
                    options: {
                      offset: [0, 5],
                    },
                  },
                ]}
                transition
                style={{ zIndex: 1300, width: popperWidth, height: popperHeight }}
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper
                      sx={{
                        backgroundColor: theme.palette.background.paper,
                        color: theme.palette.text.primary,
                        boxShadow: theme.shadows[3],
                        overflow: 'hidden',
                        width: '100%',
                        minWidth: '100px',
                      }}
                    >
                      <Box sx={{ padding: theme.spacing(2, 2) }}>
                        {renderMicroKPIs(kpi.label)}
                      </Box>
                    </Paper>
                  </Fade>
                )}
              </Popper>
            </Grid>
          );
        })}
      </Grid>
      <KPITooltip
        open={tooltipOpen}
        onClose={handleTooltipClose}
        anchorEl={tooltipAnchorEl}
        deals={tooltipContent?.deals}
        portalId={tooltipContent?.portalId}
        owners={owners}
        title={tooltipContent?.title}
        popperWidth={popperWidth}
        popperHeight={popperHeight}
      />
    </Box>
  );
};

// Add these helper functions at the top of your component or in a separate utils file
const calculateMRR = (ytdRevenue) => {
  const currentDate = new Date();
  const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
  const monthsPassed = currentDate.getMonth() - startOfYear.getMonth() + 
    (12 * (currentDate.getFullYear() - startOfYear.getFullYear())) + 1;
  
  return ytdRevenue / monthsPassed;
};

const calculateARR = (ytdRevenue) => {
  return calculateMRR(ytdRevenue) * 12;
};

export default DashboardKPIs;