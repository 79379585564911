// src/contexts/utils/healthcheckProcessing.js

export const calculateDataCompleteness = (deals) => {
  const openDeals = deals.filter(deal => deal.customStage === 'open');
  
  if (openDeals.length === 0) return 0;
  
  const totalFields = openDeals.length * 10; // Assuming 10 fields per deal
  let filledFields = 0;

  openDeals.forEach(deal => {
    const requiredFields = ['amount', 'closedate', 'dealname', 'dealstage', 'hubspot_owner_id', 'pipeline'];
    requiredFields.forEach(field => {
      if (deal[field] !== undefined && deal[field] !== null && deal[field] !== '') {
        filledFields++;
      }
    });

    // Check the new properties
    if (!deal.pastDue) filledFields++;
    if (deal.missingAmount === 0) filledFields++;
    if (deal.missingCloseDate === 0) filledFields++;
    if (deal.missingOwner === 0) filledFields++;
  });

  return filledFields / totalFields;
};

export const prepareDataCompletenessGaugeData = (deals) => {
  const completenessValue = calculateDataCompleteness(deals);
  return {
    completeness: completenessValue,
    data: [
      {
        id: 'Completeness',
        data: [
          {
            x: '',
            y: completenessValue
          }
        ]
      }
    ]
  };
};

export const processDealAgeDistribution = (deals) => {
  if (!Array.isArray(deals) || deals.length === 0) return [];

  const ageRanges = [
    { label: '0-30 days', min: 0, max: 30, count: 0 },
    { label: '31-60 days', min: 31, max: 60, count: 0 },
    { label: '61-90 days', min: 61, max: 90, count: 0 },
    { label: '90+ days', min: 91, max: Infinity, count: 0 }
  ];

  deals.filter(deal => deal.customStage === 'open').forEach(deal => {
    const age = deal.daysOpen || 0;
    const range = ageRanges.find(r => age >= r.min && age <= r.max);
    if (range) range.count++;
  });

  return ageRanges;
};

export const processMissingInformation = (deals) => {
  if (!Array.isArray(deals) || deals.length === 0) return [];

  const requiredFields = ['amount', 'closedate', 'dealname', 'dealstage', 'hubspot_owner_id', 'pipeline'];
  const missingInfo = requiredFields.map(field => ({ field, missingCount: 0 }));

  deals.filter(deal => deal.customStage === 'open').forEach(deal => {
    requiredFields.forEach((field, index) => {
      if (deal[field] === undefined || deal[field] === null || deal[field] === '') {
        missingInfo[index].missingCount++;
      }
    });
  });

  return missingInfo;
};

export const processDealAttentionNeeds = (deals) => {
  const openDeals = deals.filter(deal => deal.customStage === 'open');

  const categories = {
    pastDue: { name: 'Past Due', value: 0 },
    missingInformation: { name: 'Missing Information', value: 0 },
    missingOwner: { name: 'Missing Owner', value: 0 },
    missingAmount: { name: 'Missing Amount', value: 0 },
    noRecentActivity: { name: 'No Recent Activity', value: 0 },
    stagnant: { name: 'Stagnant', value: 0 },
    highValue: { name: 'High Value at Risk', value: 0 },
    closingSoon: { name: 'Closing Soon', value: 0 }
  };

  openDeals.forEach(deal => {
    if (deal.pastDue === 1) categories.pastDue.value++;
    if (deal.missingAmount === 1 || deal.missingCloseDate === 1 || deal.missingOwner === 1) categories.missingInformation.value++;
    if (deal.missingOwner === 1) categories.missingOwner.value++;
    if (deal.missingAmount === 1) categories.missingAmount.value++;
    if (noRecentActivity(deal)) categories.noRecentActivity.value++;
    if (isStagnant(deal)) categories.stagnant.value++;
    if (isHighValueAtRisk(deal)) categories.highValue.value++;
    if (deal.closingSoon === 1) categories.closingSoon.value++;
  });

  return Object.values(categories).filter(category => category.value > 0);
};

// Helper functions (implement these based on your business logic)
const noRecentActivity = (deal) => {
  const lastActivityDate = new Date(deal.notes_last_updated || deal.notes_last_contacted || deal.updatedAt);
  const daysSinceLastActivity = (new Date() - lastActivityDate) / (1000 * 60 * 60 * 24);
  return daysSinceLastActivity > 30; // No activity in the last 30 days
};

const isStagnant = (deal) => {
  return deal.daysOpen > 90; // Deal has been open for more than 90 days
};

const isHighValueAtRisk = (deal) => {
  const amount = parseFloat(deal.amount);
  const probability = parseFloat(deal.hs_deal_stage_probability);
  return amount > 100000 && probability < 50; // High value deals with low probability
};

const isClosingSoon = (deal) => {
  const closeDate = new Date(deal.closedate);
  const daysUntilClose = (closeDate - new Date()) / (1000 * 60 * 60 * 24);
  return daysUntilClose <= 30 && daysUntilClose > 0; // Closing within the next 30 days
};

export const processForecastAccuracyScatterPlot = (deals) => {
  const processedData = deals.filter(deal => deal.customStage !== 'open').map(deal => {
    const forecastedAmount = parseFloat(deal.hs_forecast_amount) || 0;
    const actualAmount = parseFloat(deal.amount) || 0;
    const accuracy = forecastedAmount > 0 ? Math.min(actualAmount / forecastedAmount, 2) : 0;

    return {
      x: forecastedAmount,
      y: actualAmount,
      dealName: deal.dealname || 'Unnamed Deal',
      accuracy: accuracy > 1 ? 2 - accuracy : accuracy, // Normalize accuracy to 0-1 range
    };
  });

  return [{ id: 'Forecast Accuracy', data: processedData }];
};

export const processDataQualityScoreByRep = (deals, owners) => {
  if (!Array.isArray(deals) || !Array.isArray(owners)) {
    console.error('Invalid input: deals or owners is not an array');
    return [];
  }

  const openDeals = deals.filter(deal => deal.customStage === 'open');

  const repScores = {};

  // Initialize scores for all owners
  owners.forEach(owner => {
    if (owner && owner.id) {
      repScores[owner.id] = { totalScore: 0, dealCount: 0 };
    }
  });

  // Calculate scores for open deals
  openDeals.forEach(deal => {
    const repId = deal.hubspot_owner_id;
    if (repId && repScores[repId]) {
      const dealScore = calculateDealQualityScore(deal);
      repScores[repId].totalScore += dealScore;
      repScores[repId].dealCount += 1;
    }
  });

  // Calculate average scores and prepare data for chart
  return Object.entries(repScores)
    .map(([repId, { totalScore, dealCount }]) => {
      const owner = owners.find(o => o.id === repId);
      const averageScore = dealCount > 0 ? totalScore / dealCount : 0;
      return {
        rep: owner ? `${owner.firstName} ${owner.lastName}` : 'Unknown',
        score: averageScore
      };
    })
    .filter(item => item.score > 0) // Only include reps with scores
    .sort((a, b) => b.score - a.score); // Sort by score descending
};

// Helper function to calculate the quality score for a single deal
const calculateDealQualityScore = (deal) => {
  const requiredFields = [
    'amount', 'closedate', 'dealname', 'dealstage', 
    'hubspot_owner_id', 'pipeline'
  ];
  const optionalFields = [
    'hs_next_step', 'notes_last_contacted', 'notes_last_updated', 
    'notes_next_activity_date'
  ];

  let score = 0;
  let totalFields = requiredFields.length + optionalFields.length + 4; // Adding 4 for the new fields

  requiredFields.forEach(field => {
    if (deal[field] !== undefined && deal[field] !== null && deal[field] !== '') {
      score += 1;
    }
  });

  optionalFields.forEach(field => {
    if (deal[field] !== undefined && deal[field] !== null && deal[field] !== '') {
      score += 0.5;
    }
  });

  // Add checks for the new properties
  if (deal.pastDue === 0) score += 1;
  if (deal.missingAmount === 0) score += 1;
  if (deal.missingCloseDate === 0) score += 1;
  if (deal.missingOwner === 0) score += 1;

  return score / totalFields;
};

// New functions to calculate additional metrics
export const calculateAverageDealVelocity = (deals) => {
  const openDeals = deals.filter(deal => deal.customStage === 'open');
  if (openDeals.length === 0) return 0;
  
  const totalVelocity = openDeals.reduce((sum, deal) => sum + (deal.dealVelocity || 0), 0);
  return totalVelocity / openDeals.length;
};

export const calculateAverageWinProbability = (deals) => {
  const openDeals = deals.filter(deal => deal.customStage === 'open');
  if (openDeals.length === 0) return 0;
  
  const totalProbability = openDeals.reduce((sum, deal) => sum + (deal.dealWinProbability || 0), 0);
  return totalProbability / openDeals.length;
};

export const getClosingSoonDeals = (deals) => {
  return deals.filter(deal => deal.closingSoon === 1);
};