import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import { Box, Typography } from '@mui/material';
import { useRevenue } from '../../../../contexts/RevenueContext';
import { useChartStyles } from '../../../hooks/useChartStyles';

const AvgDealSizeLine = ({ data }) => {
  const {
    formatCurrency,
    getCommonChartProperties,
    getCustomTooltip,
    isMobile,
    getChartColorPair,
    getCurrentAndPreviousYear
  } = useRevenue();

  const { currentYear, previousYear } = getCurrentAndPreviousYear();

  if (!data || data.length === 0) {
    return <Typography>No average deal size data available.</Typography>;
  }

  const chartData = [
    {
      id: `${previousYear} Average Deal Size`,
      data: data.filter(item => item.year === previousYear).map(item => ({ x: item.month, y: item.avgDealSize })),
    },
    {
      id: `${currentYear} Average Deal Size`,
      data: data.filter(item => item.year === currentYear).map(item => ({ x: item.month, y: item.avgDealSize })),
    },
  ];

  console.log('AvgDealSizeLine - Processed data:', chartData);

  const commonProperties = getCommonChartProperties('line');

  const chartProps = {
    ...commonProperties,
    data: chartData,
    xScale: { type: 'point' },
    yScale: { type: 'linear', min: 0, max: 'auto', stacked: false, reverse: false },
    axisBottom: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: -45,
      legend: 'Month',
      legendOffset: 36,
      legendPosition: 'middle'
    },
    axisLeft: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Avg Deal Size',
      legendOffset: -40,
      legendPosition: 'middle',
      format: value => formatCurrency(value, { notation: 'compact' })
    },
    enableArea: false,
    areaOpacity: 0.2,
    enableSlices: 'x',
    sliceTooltip: ({ slice }) => getCustomTooltip({
      title: slice.points[0].data.x,
      items: slice.points.map(point => ({
        label: point.serieId,
        value: point.data.y,
        formatType: 'currency'
      })),
    }),
    legends: isMobile ? [] : [
      {
        anchor: 'bottom-right',
        direction: 'column',
        justify: false,
        translateX: 100,
        translateY: 0,
        itemsSpacing: 0,
        itemDirection: 'left-to-right',
        itemWidth: 80,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: 'circle',
        symbolBorderColor: 'rgba(0, 0, 0, .5)',
        effects: [
          {
            on: 'hover',
            style: {
              itemBackground: 'rgba(0, 0, 0, .03)',
              itemOpacity: 1
            }
          }
        ]
      }
    ],
    colors: ({ id }) => getChartColorPair(id.includes(currentYear) ? 0 : 1).lineColor
  };

  return (
    <Box sx={{ height: '400px' }}>
      <ResponsiveLine {...chartProps} />
    </Box>
  );
};

export default AvgDealSizeLine;