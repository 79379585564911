import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { Box, Typography } from '@mui/material';
import { useRevenue } from '../../../../contexts/RevenueContext';
import { useChartStyles } from '../../../hooks/useChartStyles';

const DealCountComparison = () => {
  const {
    filteredData,
    formatNumber,
    getCommonChartProperties,
    getCustomTooltip,
    isMobile,
    getChartColorPair,
    getCurrentAndPreviousYear
  } = useRevenue();

  const { currentYear, previousYear } = getCurrentAndPreviousYear();
  const dealCountData = filteredData.filteredDealCountData;

  const commonProperties = getCommonChartProperties('bar');
  const colors = [getChartColorPair(1).lineColor, getChartColorPair(0).lineColor];

  const chartProps = {
    ...commonProperties,
    data: dealCountData,
    keys: [previousYear.toString(), currentYear.toString()],
    indexBy: "month",
    padding: 0.3,
    groupMode: "grouped",
    colors: colors,
    borderColor: { from: 'color', modifiers: [['darker', 1.6]] },
    axisBottom: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: -45,
      legend: 'Month',
      legendPosition: 'middle',
      legendOffset: 32
    },
    axisLeft: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Deal Count',
      legendPosition: 'middle',
      legendOffset: -40,
      format: value => formatNumber(value)
    },
    labelSkipWidth: 12,
    labelSkipHeight: 12,
    labelTextColor: { from: 'color', modifiers: [['darker', 1.6]] },
    legends: isMobile ? [] : [
      {
        dataFrom: 'keys',
        anchor: 'bottom-right',
        direction: 'column',
        justify: false,
        translateX: 120,
        translateY: 0,
        itemsSpacing: 2,
        itemWidth: 100,
        itemHeight: 20,
        itemDirection: 'left-to-right',
        itemOpacity: 0.85,
        symbolSize: 20,
        effects: [
          {
            on: 'hover',
            style: {
              itemOpacity: 1
            }
          }
        ]
      }
    ],
    tooltip: ({ id, value, color }) => getCustomTooltip({
      title: id,
      items: [{ label: 'Deal Count', value, color }],
    })
  };

  if (!dealCountData || dealCountData.length === 0) {
    return <Typography>No deal count data available.</Typography>;
  }

  return (
    <Box sx={{ height: '400px' }}>
      <ResponsiveBar
        {...chartProps}
      />
    </Box>
  );
};

export default DealCountComparison;