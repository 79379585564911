// src/contexts/AuthContext.jsx v2.0.0

import React, { createContext, useContext, useState, useEffect } from 'react';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, signOut, updatePassword, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import { doc, onSnapshot, updateDoc, getDoc } from 'firebase/firestore';
import { useFirebase } from './FirebaseContext';
import logger from '../utils/frontendLogger'; // Add this line at the top of the file

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const { auth, db } = useFirebase();
  const [currentUser, setCurrentUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  const waitForUserDocument = async (uid, maxAttempts = 10, interval = 1000) => {
    for (let attempt = 0; attempt < maxAttempts; attempt++) {
      const userRef = doc(db, 'users', uid);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        return userDoc.data();
      }
      await new Promise(resolve => setTimeout(resolve, interval));
    }
    throw new Error('User document creation timeout');
  };

  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged(user => {
      logger.info('Auth state changed', { user: user?.uid });
      setCurrentUser(user);
      setLoading(false);
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        const unsubscribeFirestore = onSnapshot(userRef, 
          (doc) => {
            if (doc.exists()) {
              const data = doc.data();
              setUserData({
                ...data,
                hubspotConnected: data.hubspotConnected || false,
              });
            }
          },
          (error) => {
            console.error("Error fetching user data:", error);
            // Handle the error appropriately, e.g., show a notification to the user
          }
        );
        return () => unsubscribeFirestore();
      } else {
        setUserData(null);
      }
    });

    return () => unsubscribeAuth();
  }, [auth, db]);

  const signUp = async (email, password) => {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const userData = await waitForUserDocument(userCredential.user.uid);
    return { userCredential, userData };
  };

  const logIn = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider);
  };

  const logOut = () => {
    return signOut(auth);
  };

  const updateUserPassword = async (currentPassword, newPassword) => {
    if (!currentUser) {
      throw new Error('No user is currently logged in');
    }

    console.log('Attempting to update password for user:', currentUser.email);

    const credential = EmailAuthProvider.credential(
      currentUser.email,
      currentPassword
    );

    try {
      console.log('Reauthenticating user');
      await reauthenticateWithCredential(currentUser, credential);
      console.log('User reauthenticated successfully');
      
      console.log('Updating password');
      await updatePassword(currentUser, newPassword);
      console.log('Password updated successfully');
    } catch (error) {
      console.error('Error in updateUserPassword:', error);
      throw error;
    }
  };

  const updateUserProfile = async (profileData) => {
    if (!currentUser) {
      throw new Error('No user is currently logged in');
    }

    try {
      const userRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userRef, {
        firstName: profileData.firstName,
        lastName: profileData.lastName,
        jobTitle: profileData.jobTitle,
        phone: profileData.phone,
        companyName: profileData.companyName,
        industry: profileData.industry,
        companySize: profileData.companySize,
        companyWebsite: profileData.companyWebsite,
        location: profileData.location,
        timezone: profileData.timezone,
      });
      console.log('Profile updated successfully');
    } catch (error) {
      console.error('Error updating profile:', error);
      throw error;
    }
  };

  const value = {
    currentUser,
    userData,
    loading,
    signUp,
    logIn,
    signInWithGoogle,
    logOut,
    updateUserPassword,
    updateUserProfile
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};