import React from 'react';
import { Box, Container, Typography, Grid, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SyncIcon from '@mui/icons-material/Sync';
import DashboardIcon from '@mui/icons-material/Dashboard';
import connectHubspotVideo from './assets/connecthubspot.mp4';
import instantSyncVideo from './assets/instantsync.mp4';
import insightsVideo from './assets/insights.mp4';

// Update the StepVideo component to handle the new video
const StepVideo = styled('video')(({ videoType }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  objectPosition: videoType === 'instantSync' ? 'left center' : 'center',
}));

const StepBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  maxWidth: '300px', // Match the max-width of the image
  margin: '0 auto',
  padding: theme.spacing(2),
}));

const StepMedia = styled('div')({
  width: '100%',
  maxWidth: '300px',
  aspectRatio: '3 / 4', // This creates a 16:9 aspect ratio
  marginBottom: '1rem',
  borderRadius: '8px',
  overflow: 'hidden',
  position: 'relative', // This is needed for absolute positioning of children
});

const StepImage = styled('img')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

const StepTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  marginBottom: theme.spacing(1),
  fontSize: '1.25rem',
}));

const StepDescription = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  lineHeight: 1.4,
}));

const steps = [
  {
    icon: <AccountCircleIcon fontSize="inherit" />,
    title: 'Connect HubSpot',
    description: 'Securely link your HubSpot account with just a few clicks. No complex setup required.',
    media: connectHubspotVideo,
    type: 'video',
    videoType: 'connectHubspot',
  },
  {
    icon: <SyncIcon fontSize="inherit" />,
    title: 'Instant Sync',
    description: 'We automatically fetch and analyze your sales data, including custom deal stages and rep information.',
    media: instantSyncVideo,
    type: 'video',
    videoType: 'instantSync',
  },
  {
    icon: <DashboardIcon fontSize="inherit" />,
    title: 'Explore Insights',
    description: 'Dive into AI-powered dashboards tailored to your sales process, all within 30 seconds.',
    media: insightsVideo,
    type: 'video',
    videoType: 'insights',
  },
];

const HowItWorks = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        py: { xs: 6, md: 12 },
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Container maxWidth="lg" sx={{ textAlign: 'center' }}>
        <Typography variant="sectionTitle">
          How Truepipe Works
        </Typography>
        <Typography
          variant="h5"
          color="text.secondary"
          sx={{ 
            mb: { xs: 4, md: 8 },
            maxWidth: '800px', 
            mx: 'auto',
            textAlign: 'center',
            fontSize: { xs: '1rem', md: '1.25rem' },
          }}
        >
          Transform your sales pipeline in seconds. No complex setup, no data entry – just connect and gain instant insights.
        </Typography>
        <Grid container spacing={{ xs: 4, md: 8 }} justifyContent="center">
          {steps.map((step, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <StepBox>
                <StepTitle>{step.title}</StepTitle>
                <StepMedia>
                  {step.type === 'video' ? (
                    <StepVideo
                      autoPlay
                      loop
                      muted
                      playsInline
                      videoType={step.videoType}
                    >
                      <source src={step.media} type="video/mp4" />
                      Your browser does not support the video tag.
                    </StepVideo>
                  ) : (
                    <StepImage src={step.media} alt={step.title} />
                  )}
                </StepMedia>
                <StepDescription color="text.secondary">
                  {step.description}
                </StepDescription>
              </StepBox>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default HowItWorks;