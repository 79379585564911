import React from 'react';
import { ResponsiveScatterPlot } from '@nivo/scatterplot';
import { useRevenue } from '../../../../contexts/RevenueContext';
import { useChartStyles } from '../../../hooks/useChartStyles';
import { Box, Typography } from '@mui/material';

const SalesActivityCorrelationChart = () => {
  const { salesActivityCorrelationData, formatCurrency } = useRevenue();
  const { getCommonChartProperties, getCustomTooltip, getChartColor, isMobile } = useChartStyles();

  console.log('SalesActivityCorrelationChart - salesActivityCorrelationData:', salesActivityCorrelationData);

  if (!salesActivityCorrelationData || !salesActivityCorrelationData.chartData || salesActivityCorrelationData.chartData.length === 0) {
    return <Typography>No data available for Sales Activity Correlation</Typography>;
  }

  const { chartData, repNames } = salesActivityCorrelationData;
  
  // Add a function to normalize node sizes
  const normalizeNodeSize = (size) => {
    const minSize = 2;
    const maxSize = 20;
    const minDataSize = Math.min(...chartData.map(item => item.size));
    const maxDataSize = Math.max(...chartData.map(item => item.size));
    return minSize + ((size - minDataSize) / (maxDataSize - minDataSize)) * (maxSize - minSize);
  };

  // Transform data to match Nivo ScatterPlot requirements
  const transformedData = repNames
    .filter(repName => repName !== undefined)
    .map(repName => ({
      id: repName,
      data: chartData
        .filter(item => item.repName === repName)
        .map(item => ({
          x: item.x,
          y: item.y,
          size: normalizeNodeSize(item.size),
          rawSize: item.size,
          repName: item.repName,
          dealId: item.dealId
        }))
    }))
    .filter(series => series.data.length > 0);

  console.log('SalesActivityCorrelationChart - transformedData:', transformedData);

  if (transformedData.length === 0) {
    return <Typography>No valid data available for Sales Activity Correlation</Typography>;
  }

  const commonProperties = getCommonChartProperties('scatter');

  // Calculate max values for scales
  const maxX = Math.max(...chartData.map(item => item.x));
  const maxY = Math.max(...chartData.map(item => item.y));

  return (
    <Box sx={{ height: isMobile ? 400 : 500, width: '100%' }}>
      <ResponsiveScatterPlot
        data={transformedData}
        {...commonProperties}
        xScale={{ type: 'linear', min: 0, max: maxX * 1.1 }}
        yScale={{ type: 'linear', min: 0, max: maxY * 1.1 }}
        xFormat=">-.0f"
        yFormat={formatCurrency}
        axisBottom={{
          ...commonProperties.axisBottom,
          legend: 'Number of Contacts',
          legendPosition: 'middle',
          legendOffset: 46
        }}
        axisLeft={{
          ...commonProperties.axisLeft,
          legend: 'Deal Amount',
          legendPosition: 'middle',
          legendOffset: -60
        }}
        colors={({ serieId }) => getChartColor(repNames.indexOf(serieId))}
        nodeSize={node => node.data.size}
        useMesh={true}
        legends={[
          {
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 130,
            translateY: 0,
            itemWidth: 100,
            itemHeight: 12,
            itemsSpacing: 5,
            itemDirection: 'left-to-right',
            symbolSize: 12,
            symbolShape: 'circle',
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1
                }
              }
            ],
            data: transformedData.map((series, index) => ({
              id: series.id,
              label: series.id,
              color: getChartColor(index)
            }))
          },
          {
            anchor: 'bottom-left',
            direction: 'column',
            justify: false,
            translateX: 0,
            translateY: 0,
            itemWidth: 100,
            itemHeight: 12,
            itemsSpacing: 5,
            itemDirection: 'left-to-right',
            symbolSize: 12,
            symbolShape: 'circle',
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1
                }
              }
            ],
            data: [
              { id: 'xs', label: '< 30 days', color: '#000', size: normalizeNodeSize(30) },
              { id: 'sm', label: '30-60 days', color: '#000', size: normalizeNodeSize(60) },
              { id: 'md', label: '60-90 days', color: '#000', size: normalizeNodeSize(90) },
              { id: 'lg', label: '> 90 days', color: '#000', size: normalizeNodeSize(120) },
            ]
          }
        ]}
        tooltip={({ node }) => getCustomTooltip({
          title: node.data.repName,
          items: [
            { label: 'Contacts', value: node.data.x },
            { label: 'Amount', value: node.data.y, formatType: 'currency' },
            { label: 'Time to Close', value: `${node.data.rawSize} days` },
            { label: 'Deal ID', value: node.data.dealId }
          ]
        })}
      />
    </Box>
  );
};

export default SalesActivityCorrelationChart;