import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { ResponsiveTreeMap } from '@nivo/treemap';
import { useHealthcheck } from '../../../contexts/HealthcheckContext';
import CustomTooltip from '../../charts/CustomTooltip';

const DealAttentionNeedsTreemap = () => {
  const { 
    dealAttentionNeeds, 
    loading, 
    getCommonChartProperties, 
    isMobile, 
    getChartColor,
    formatNumber,
    formatPercentage
  } = useHealthcheck();
  const [selectedCategory, setSelectedCategory] = useState(null);

  const chartProperties = getCommonChartProperties ? getCommonChartProperties('treemap') : {};

  if (loading) {
    return (
      <Box height={isMobile ? 150 : 200} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Typography variant="h6">Deal Attention Needs</Typography>
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  if (!dealAttentionNeeds || dealAttentionNeeds.length === 0) {
    return (
      <Box height={isMobile ? 150 : 200} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Typography variant="h6">Deal Attention Needs</Typography>
        <Typography>No data available</Typography>
      </Box>
    );
  }

  const totalDeals = dealAttentionNeeds.reduce((sum, cat) => sum + cat.value, 0);

  return (
    <Box height={isMobile ? 150 : "100%"}>
      <Typography variant="h6" align="center" gutterBottom>
        Deal Attention Needs {selectedCategory ? `- ${selectedCategory}` : ''}
      </Typography>
      <ResponsiveTreeMap
        data={{
          name: 'Attention Needs',
          children: selectedCategory
            ? dealAttentionNeeds.filter(need => need.name === selectedCategory)
            : dealAttentionNeeds
        }}
        identity="name"
        value="value"
        {...chartProperties}
        colors={({ id }) => getChartColor(dealAttentionNeeds.findIndex(need => need.name === id))}
        label={d => `${d.data.name}: ${d.value}`}
        labelSkipSize={12}
        labelTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
        tooltip={({ node }) => (
          <CustomTooltip
            title={node.data.name}
            items={[
              { label: 'Deals', value: node.value, formatType: 'number' },
              { label: 'Percentage', value: node.value / totalDeals, formatType: 'percentage' }
            ]}
          />
        )}
        onClick={(node) => {
          if (node.data.name === selectedCategory) {
            setSelectedCategory(null);
          } else {
            setSelectedCategory(node.data.name);
          }
        }}
      />
    </Box>
  );
};

export default DealAttentionNeedsTreemap;