// src/contexts/RevenueContext.jsx v1.0.4

import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { collection, where, query, onSnapshot, doc } from 'firebase/firestore';
import { useFirebase } from './FirebaseContext';
import { useAuth } from './AuthContext';
import { useTheme } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { formatCurrency, formatPercentage, formatDate, formatNumber } from '../components/utils/formatters';
import { useChartStyles } from '../components/hooks/useChartStyles';
import useFetchRevenue from './hooks/useFetchRevenue';
import {
  processRevenueData,
  processRepFunnelData,
  processYoYRevenueComparison,
  calculateYoYRevenueComparison,
  processSalesActivity,
  processSalesVelocity,
 processPipelineHealth,
  processRepActivityTimeline,
  getRepPerformanceData,
  calculateSalesMetrics,
  processSalesActivityCorrelation,
  processRepNames,
  processCurrentYearWinRate,
  processAvgDealSize,
} from '../contexts/utils/revenueProcessing';
import { processRepPerformance as processRepPerformanceFunc } from '../contexts/utils/revenueProcessing';
import { filterDataByPipelines } from '../utils/dataFilters'; // Create this utility function

const RevenueContext = createContext();

export const useRevenue = () => useContext(RevenueContext);

export const RevenueProvider = ({ children }) => {
    const { revenueData, loading, error } = useFetchRevenue();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const chartStyles = useChartStyles();
    const { db } = useFirebase();
    const { currentUser } = useAuth();
    const [owners, setOwners] = useState([]);
    const [deals, setDeals] = useState([]);
    const [pipelineStages, setPipelineStages] = useState({});
    const [stageMapping, setStageMapping] = useState({});
    const [selectedPipelines, setSelectedPipelines] = useState([]);
    const [uniquePipelines, setUniquePipelines] = useState([]);
    const [salesActivityCorrelationData, setSalesActivityCorrelationData] = useState(null);
    const [pipelineHealth, setPipelineHealth] = useState(null);
    const [repPerformance, setRepPerformance] = useState({});
    const [repNames, setRepNames] = useState([]);
    const currentYearWinRate = useMemo(() => processCurrentYearWinRate(deals), [deals]);
    const avgDealSizeData = useMemo(() => {
      console.log('RevenueContext - Processing avgDealSizeData');
      console.log('RevenueContext - Deals:', deals);
      const result = processAvgDealSize(deals);
      console.log('RevenueContext - Processed avgDealSizeData:', result);
      return result;
    }, [deals]);

    const yoyRevenueComparison = useMemo(() => calculateYoYRevenueComparison(deals), [deals]);

    const filteredYoyRevenueComparison = useMemo(() => 
      calculateYoYRevenueComparison(deals, selectedPipelines), [deals, selectedPipelines]);

    // Now you can use filteredYoyRevenueComparison in useEffect hooks
    useEffect(() => {
        console.log('Selected pipelines:', selectedPipelines);
        console.log('Filtered YoY Revenue Comparison:', filteredYoyRevenueComparison);
    }, [selectedPipelines, filteredYoyRevenueComparison]);

    useEffect(() => {
      console.log('Selected pipelines updated:', selectedPipelines);
    }, [selectedPipelines]);

    useEffect(() => {
      if (deals.length > 0 && Object.keys(pipelineStages).length > 0) {
        const pipelineMap = new Map();
        Object.values(pipelineStages).forEach(stage => {
          if (!pipelineMap.has(stage.pipelineId)) {
            pipelineMap.set(stage.pipelineId, stage.pipelineLabel);
          }
        });

        const uniquePipelinesSet = new Set(deals.map(deal => deal.pipeline));
        const pipelineArray = Array.from(uniquePipelinesSet).map(pipelineId => ({
          id: pipelineId,
          label: pipelineMap.get(pipelineId) || `Unknown Pipeline (${pipelineId})`
        }));

        console.log('Updated uniquePipelines:', pipelineArray); // Add this line for debugging
        setUniquePipelines(pipelineArray);
      }
    }, [deals, pipelineStages]);

    useEffect(() => {
      console.log('Deals updated:', deals);
      console.log('Unique pipelines:', uniquePipelines);
    }, [deals, uniquePipelines]);

    useEffect(() => {
      console.log('YoY Revenue Comparison:', yoyRevenueComparison);
      console.log('Selected Pipelines:', selectedPipelines);
      console.log('Filtered YoY Revenue Comparison:', filteredYoyRevenueComparison);
    }, [yoyRevenueComparison, selectedPipelines, filteredYoyRevenueComparison]);

    useEffect(() => {
      if (deals.length > 0 && owners.length > 0) {
        console.log('Processing sales activity correlation data');
        console.log('Deals:', deals);
        console.log('Owners:', owners);
        const processedData = processSalesActivityCorrelation(deals, owners);
        console.log('Processed Sales Activity Correlation Data:', processedData);
        setSalesActivityCorrelationData(processedData);
      } else {
        console.log('Not processing sales activity correlation data');
        console.log('Deals length:', deals.length);
        console.log('Owners length:', owners.length);
      }
    }, [deals, owners]);

    useEffect(() => {
        if (!currentUser) return;
      
        const ownersRef = collection(db, 'users', currentUser.uid, 'owners');
        const unsubscribe = onSnapshot(ownersRef, (snapshot) => {
          const ownersData = snapshot.docs.map(doc => {
            const data = doc.data();
            return {
              id: doc.id,
              ...data,
              // We'll calculate these values in processRepPerformance instead
              // totalDeals: parseInt(data.totalDeals || '0', 10),
              // totalRevenue: parseFloat(data.totalRevenue || '0'),
              // avgDealSize: parseFloat(data.avgDealSize || '0'),
              // winRate: parseFloat(data.winRate || '0'),
            };
          });
          console.log('Fetched owners:', ownersData);
          setOwners(ownersData);
      
          // Detailed logging for each owner
          snapshot.docs.forEach(doc => {
            console.log(`Owner ${doc.id} data:`, doc.data());
          });
        }, (error) => {
          console.error('Error fetching owners:', error);
          // You might want to set an error state here
          // setError('Failed to fetch owners data');
        });
      
        return () => unsubscribe();
      }, [currentUser, db]);

// useEffect for serving only closed deals, not supposed to work with our winRate and avgDealSizeComparison charts
    useEffect(() => {
        if (!currentUser) return;
      
        const dealsRef = collection(db, 'users', currentUser.uid, 'deals');
        const allDealsQuery = query(dealsRef, 
          where('customStage', 'in', ['closedwon', 'closedlost'])
        );
        const unsubscribe = onSnapshot(allDealsQuery, (snapshot) => {
          const dealsData = snapshot.docs.map(doc => ({ 
            id: doc.id, 
            ...doc.data(),
            num_contacted_notes: parseInt(doc.data().num_contacted_notes || '0', 10),
            notes_last_contacted: doc.data().notes_last_contacted,
            notes_last_updated: doc.data().notes_last_updated,
            notes_next_activity_date: doc.data().notes_next_activity_date,
            num_associated_contacts: parseInt(doc.data().num_associated_contacts || '0', 10),
            num_notes: parseInt(doc.data().num_notes || '0', 10)
          }));
          console.log('RevenueContext - Fetched deals:', dealsData);
          setDeals(dealsData);
        }, (error) => {
          console.error('Error fetching deals:', error);
        });
        return () => unsubscribe();
      }, [currentUser, db]);

    useEffect(() => {
      console.log('Fetched revenueData:', revenueData); // Debugging line
    }, [revenueData]);

    // Fetch pipelineStages
    useEffect(() => {
      if (!currentUser) return;

      const pipelineStagesRef = doc(db, 'users', currentUser.uid, 'pipelineStages', 'stages');
      const unsubscribe = onSnapshot(pipelineStagesRef, (snapshot) => {
        if (snapshot.exists()) {
          const stagesData = snapshot.data().stages; // Access the 'stages' field
          setPipelineStages(stagesData);
          console.log('Fetched pipelineStages:', stagesData);
        }
      }, (error) => {
        console.error('Error fetching pipeline stages:', error);
      });

      return () => unsubscribe();
    }, [currentUser, db]);

    useEffect(() => {
      if (deals.length > 0 && pipelineStages.length > 0 && owners.length > 0) {
        const processedPipelineHealth = processPipelineHealth(deals, pipelineStages, owners);
        setPipelineHealth(processedPipelineHealth);
      }
    }, [deals, pipelineStages, owners]);

    // Fetch repPerformance data from Firestore
    useEffect(() => {
      if (!currentUser) return;
    
      const repPerformanceRef = doc(db, 'users', currentUser.uid, 'aggregates', 'repPerformance');
      const unsubscribe = onSnapshot(repPerformanceRef, (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.data();
          // Remove the lastUpdated field before setting the state
          const { lastUpdated, ...repPerformanceData } = data;
          setRepPerformance(repPerformanceData);
        }
      }, (error) => {
        console.error('Error fetching rep performance:', error);
      });
    
      return () => unsubscribe();
    }, [currentUser, db]);

  // Keep this useEffect hook to update repNames when owners change
  useEffect(() => {
    setRepNames(processRepNames(owners));
  }, [owners]);

  // Update the processedRepPerformance calculation
  const processedRepPerformance = useMemo(() => {
    return Object.entries(repPerformance)
      .map(([id, data]) => {
        const owner = owners.find(o => o.id === id);
        return {
          ...data,
          id,
          hubspot_owner_id: id,
          name: owner ? `${owner.firstName} ${owner.lastName}` : `Unknown User (${id})`,
          displayName: owner ? `${owner.firstName} ${owner.lastName}` : `Unknown User (${id})`
        };
      });
  }, [repPerformance, owners]);

  // Update the totalRevenue calculation
  const totalRevenue = useMemo(() => {
    return processedRepPerformance.reduce((sum, rep) => sum + (rep.currentYTDRevenue || 0), 0);
  }, [processedRepPerformance]);
  
  const getYoYRevenueComparisonTooltip = (slice) => {
    const currentYear = new Date().getFullYear();
    const previousYear = currentYear - 1;
    const currentYearPoint = slice.points.find(p => p.serieId === `${currentYear} Revenue`);
    const previousYearPoint = slice.points.find(p => p.serieId === `${previousYear} Revenue`);

    if (!currentYearPoint || !previousYearPoint) return null;

    const yearOverYearChange = currentYearPoint.data.y - previousYearPoint.data.y;
    const percentageChange = (yearOverYearChange / previousYearPoint.data.y) * 100;

    return {
      title: slice.points[0].data.x,
      items: [
        { label: `${currentYear}`, value: currentYearPoint.data.y, formatType: 'currency' },
        { label: `${previousYear}`, value: previousYearPoint.data.y, formatType: 'currency' },
      ],
      comparisonItem: {
        label: 'YoY Change',
        value: yearOverYearChange,
        percentage: percentageChange,
        formatType: 'currency',
      },
    };
  };
  
  const processedRevenueData = revenueData && revenueData.deals && owners.length > 0
  ? processRevenueData(deals, owners) // Pass deals to processRevenueData
  : {};

const repFunnelData = revenueData && revenueData.deals && owners.length > 0
  ? processRepFunnelData(deals, owners, pipelineStages) // Pass deals and pipelineStages to processRepFunnelData
  : [];

  const updateSelectedPipelines = (pipelines) => {
    setSelectedPipelines(pipelines);
  };

  const salesActivity = useMemo(() => processSalesActivity(deals, owners), [deals, owners]);

  useEffect(() => {
    if (salesActivityCorrelationData) {
      console.log('Processed Sales Activity Correlation Data:', salesActivityCorrelationData);
    }
  }, [salesActivityCorrelationData]);

  // Update the value object
  const value = {
    ...revenueData,
    ...processedRevenueData,
    loading,
    error,
    formatCurrency,
    formatPercentage,
    formatDate,
    formatNumber,
    ...chartStyles,
    getYoYRevenueComparisonTooltip,
    theme,
    isMobile,
    repFunnelData,
    owners,
    deals,
    pipelineStages,
    stageMapping,
    uniquePipelines,
    selectedPipelines,
    setSelectedPipelines,
    filteredYoyRevenueComparison,
    yoyRevenueComparison,
    updateSelectedPipelines,
    repNames,
    currentYearWinRate,
    avgDealSizeData,
    pipelineHealth,
    
    // New properties for performance metrics
    repPerformance: processedRepPerformance,
    totalRevenue,
    salesActivity,
    salesVelocity: processSalesVelocity(deals, owners),
    pipelineHealth,
    repActivityTimeline: processRepActivityTimeline(deals, owners),
    salesActivityCorrelationData,
    
    // Additional helper functions if needed
    getRepPerformanceData: (repId) => getRepPerformanceData(repId, deals, owners),
    calculateSalesMetrics: () => calculateSalesMetrics(deals, owners),
    
    // Any other relevant data or functions for the performance dashboard
  };

  const filteredData = useMemo(() => {
    return filterDataByPipelines({
      deals,
      yoyRevenueComparison,
      avgDealSizeData,
      currentYearWinRate,
      selectedPipelines,
    });
  }, [deals, yoyRevenueComparison, avgDealSizeData, currentYearWinRate, selectedPipelines]);

  useEffect(() => {
    console.log('RevenueContext - Current state:', {
      revenueData,
      loading,
      error,
      owners,
      deals,
      pipelineStages,
      selectedPipelines,
      uniquePipelines,
      repPerformance,
      currentYearWinRate,
      avgDealSizeData,
      yoyRevenueComparison,
      filteredYoyRevenueComparison,
      salesActivityCorrelationData,
      pipelineHealth,
    });
  }, [revenueData, loading, error, owners, deals, pipelineStages, selectedPipelines, uniquePipelines, repPerformance, currentYearWinRate, avgDealSizeData, yoyRevenueComparison, filteredYoyRevenueComparison, salesActivityCorrelationData, pipelineHealth]);

  const processedRevenueDataMemo = useMemo(() => {
    const result = revenueData && revenueData.deals && owners.length > 0
      ? processRevenueData(deals, owners)
      : {};
    console.log('RevenueContext - Processed Revenue Data:', result);
    return result;
  }, [revenueData, deals, owners]);

  const repFunnelDataMemo = useMemo(() => {
    const result = revenueData && revenueData.deals && owners.length > 0
      ? processRepFunnelData(deals, owners, pipelineStages)
      : [];
    console.log('RevenueContext - Rep Funnel Data:', result);
    return result;
  }, [revenueData, deals, owners, pipelineStages]);

  const salesActivityMemo = useMemo(() => {
    const result = processSalesActivity(deals, owners);
    console.log('RevenueContext - Sales Activity:', result);
    return result;
  }, [deals, owners]);

  const salesVelocityMemo = useMemo(() => {
    const result = processSalesVelocity(deals, owners);
    console.log('RevenueContext - Sales Velocity:', result);
    return result;
  }, [deals, owners]);

  const repActivityTimelineMemo = useMemo(() => {
    const result = processRepActivityTimeline(deals, owners);
    console.log('RevenueContext - Rep Activity Timeline:', result);
    return result;
  }, [deals, owners]);

  const filteredDataMemo = useMemo(() => {
    const result = filterDataByPipelines({
      deals,
      yoyRevenueComparison,
      avgDealSizeData,
      currentYearWinRate,
      selectedPipelines,
    });
    console.log('RevenueContext - Filtered Data:', result);
    return result;
  }, [deals, yoyRevenueComparison, avgDealSizeData, currentYearWinRate, selectedPipelines]);

  // Update the value object
  const valueMemo = {
    ...revenueData,
    ...processedRevenueDataMemo,
    loading,
    error,
    formatCurrency,
    formatPercentage,
    formatDate,
    formatNumber,
    ...chartStyles,
    getYoYRevenueComparisonTooltip,
    theme,
    isMobile,
    repFunnelData: repFunnelDataMemo,
    owners,
    deals,
    pipelineStages,
    stageMapping,
    uniquePipelines,
    selectedPipelines,
    setSelectedPipelines,
    filteredYoyRevenueComparison,
    yoyRevenueComparison,
    updateSelectedPipelines,
    repNames,
    currentYearWinRate,
    avgDealSizeData,
    pipelineHealth,
    
    // New properties for performance metrics
    repPerformance: processedRepPerformance,
    totalRevenue,
    salesActivity: salesActivityMemo,
    salesVelocity: salesVelocityMemo,
    pipelineHealth,
    repActivityTimeline: repActivityTimelineMemo,
    salesActivityCorrelationData,
    
    // Additional helper functions if needed
    getRepPerformanceData: (repId) => getRepPerformanceData(repId, deals, owners),
    calculateSalesMetrics: () => calculateSalesMetrics(deals, owners),
    
    // Any other relevant data or functions for the performance dashboard
  };

  console.log('RevenueContext - Final value object:', valueMemo);

  return (
    <RevenueContext.Provider value={{ ...valueMemo, filteredData: filteredDataMemo, updateSelectedPipelines }}>
      {children}
    </RevenueContext.Provider>
  );
};

// Keep the isClosedWon function here for consistency
const isClosedWon = (deal) => {
  const probability = parseFloat(deal.hs_deal_stage_probability);
  return probability === 1 || probability === 100;
};